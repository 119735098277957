<template>
    <input type="button" :value="text" class="cursor-pointer rounded-md bg-orange-50 px-3 py-2 text-sm font-semibold text-orange-600 hover:bg-orange-100">
</template>

<script>
export default {
    name: 'PetButton',
    props: {
        text: String,
        inline: {
            type: Boolean,
            default: true
        }
    }
}
</script>