<template>
	<div class="mt-16 sm:mt-24 lg:col-span-6 lg:mt-0">
		<div class="bg-white w-[calc(100%-40px)] mx-auto rounded-lg sm:mx-auto sm:w-full sm:max-w-md sm:overflow-hidden sm:rounded-xl shadow-lg">
			<div class="px-6 py-6">
				<h2 class="text-2xl font-bold" v-if="typeof customHeading === 'undefined'" >Registrujte ztrátu ZDARMA</h2>
				<h2 class="text-2xl font-bold" v-else>{{ customHeading }}</h2>


				<div class="font-xl mb-6 mt-3 font-light text-stone-500" v-if="typeof customSubheading === 'undefined'">Zadejte informace o svém ztraceném zvířeti a začněte s pátráním. Hotovo máte za 60 vteřin.</div>
				<div class="font-xl mb-6 mt-3 font-light text-stone-500" v-else>{{ customSubheading }}</div>

				<div class="mt-6">

					<form @submit.prevent="formSubmit" class="">
						<div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
							<div>
								<label class="block font-medium leading-6 text-stone-900 mb-2" v-if="typeof customNameHeading === 'undefined'">Jméno mazlíčka</label>
								<label class="block font-medium leading-6 text-stone-900 mb-2" v-else>{{ customNameHeading }}</label>
								<input class="block w-full rounded-md border-0 py-1.5 text-stone-900 shadow-sm ring-1 ring-inset ring-stone-300 placeholder:text-stone-400 focus:ring-2 focus:ring-inset focus:ring-orange-300 sm:text-sm sm:leading-6" type="text" v-model="form.petName" />
								<div v-if="v$.form.petName.$errors.length != 0" class="text-red-600 text-sm mt-3">Vyplňte prosím pole</div>
							</div>

							<div>
								<label class="block font-medium leading-6 text-stone-900 mb-2">Kdy se ztratil?</label>
								<input class="block w-full rounded-md border-0 py-1.5 text-stone-900 shadow-sm ring-1 ring-inset ring-stone-300 placeholder:text-stone-400 focus:ring-2 focus:ring-inset focus:ring-orange-300 sm:text-sm sm:leading-6" type="text" v-model="form.petTime" />
								<div v-if="v$.form.petTime.$errors.length != 0" class="text-red-600 text-sm mt-3">Vyplňte prosím pole</div>
							</div>

							<div>
								<label class="block font-medium leading-6 text-stone-900 mb-2">Váš e-mail</label>
								<input class="block w-full rounded-md border-0 py-1.5 text-stone-900 shadow-sm ring-1 ring-inset ring-stone-300 placeholder:text-stone-400 focus:ring-2 focus:ring-inset focus:ring-orange-300 sm:text-sm sm:leading-6" type="text" v-model="form.userEmail" >
								<div v-if="v$.form.userEmail.$errors.length != 0" class="text-red-600 text-sm mt-3">Vyplňte prosím platný email</div>
							</div>

							<div>
								<label class="block font-medium leading-6 text-stone-900 mb-2">Vytvořte si heslo</label>
								<input class="block w-full rounded-md border-0 py-1.5 text-stone-900 shadow-sm ring-1 ring-inset ring-stone-300 placeholder:text-stone-400 focus:ring-2 focus:ring-inset focus:ring-orange-300 sm:text-sm sm:leading-6" type="password" v-model="form.password" />
								<div v-if="v$.form.password.$errors.length != 0" class="text-red-600 text-sm mt-3">Vyplňte prosím pole</div>
							</div>
						</div>


						<BasicButton class="mb-6 mt-7 w-full block" name="submit" text="Začít pátrat" type="submit" />


					</form>

					<div>
						<div class="flex flex-col sm:flex-row sm:items-center mb-5">
							<!-- <p class="bg-blue-100 text-blue-800 text-sm font-semibold inline-flex items-center p-1.5 rounded">8.7</p> -->
							<div class="flex items-center mb-2 sm:mb-0">
								<p class="font-medium text-gray-900">Ověřená společnost</p>
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#84cc16" class="ml-2 w-6 h-6">
									<path fill-rule="evenodd" d="M8.603 3.799A4.49 4.49 0 0112 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 013.498 1.307 4.491 4.491 0 011.307 3.497A4.49 4.49 0 0121.75 12a4.49 4.49 0 01-1.549 3.397 4.491 4.491 0 01-1.307 3.497 4.491 4.491 0 01-3.497 1.307A4.49 4.49 0 0112 21.75a4.49 4.49 0 01-3.397-1.549 4.49 4.49 0 01-3.498-1.306 4.491 4.491 0 01-1.307-3.498A4.49 4.49 0 012.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 011.307-3.497 4.49 4.49 0 013.497-1.307zm7.007 6.387a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clip-rule="evenodd" />
								</svg>
							</div>

							<span class="hidden sm:block w-1 h-1 mx-2 bg-gray-900 rounded-full"></span>
							<p class="text-sm font-medium text-gray-500">500+ spokojených zákazníků</p>
						</div>

						<div class="flex items-center space-x-3">
							<img class="block w-[calc((100%-0.75rem)/2-8px)]" src="../assets/svg/googleMaps.svg" alt="" />
							<img class="block w-[calc((100%-0.75rem)/2+8px)]" src="../assets/svg/seznamMaps.svg" alt="" />
						</div>
					</div>
				</div>
			</div>
			
		</div>
	</div>
















</template>

<script>

import BasicButton from './BasicButton.vue'
import useVuelidate from '@vuelidate/core'
import { email, required } from '@vuelidate/validators'

export default {
    name: "FormSmall",
    setup() {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            form: {}
        }
    },
    validations() {
        return {
            form: {
                petName: { required },
                petTime: { required },
                userEmail: { required, email },
                password: { required },
            }
        }
    },
    props: {
        submitRoute: String,
				customHeading: String,
				customSubheading: String,
				customNameHeading: String,
    },
    methods: {
        formSubmit() {
            this.v$.$validate()
            if(!this.v$.$invalid) {
                this.$store.commit('updateForm', this.form)
                this.$router.push('/registrovat');
            }
        },
        getForm() {
            return this.$store.state.form
        }
    },
    components: { 
        BasicButton, 
    },
    created() {
        this.form = this.getForm()
    },
}
</script>