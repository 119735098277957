<template>
  <div class="bg-white">
    <div class="safarifix mx-auto max-w-7xl sm:px-6 lg:px-8">
      <div class="safarifix relative isolate overflow-hidden bg-stone-900 px-6 pt-16 shadow-2xl sm:rounded-3xl sm:px-16 md:pt-24 lg:flex justify-between items-center lg:px-24 lg:pt-0">
        
        <!-- <svg viewBox="0 0 1024 1024" class="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0" aria-hidden="true">
          <circle cx="512" cy="512" r="512" fill="url(#759c1415-0410-454c-8f7c-9a820de03641)" fill-opacity="0.7" />
          <defs>
            <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
              <stop stop-color="#FF9A45" />
              <stop offset="1" stop-color="#FF405B" />
            </radialGradient>
          </defs>
        </svg> -->






        <div class="absolute inset-0 block z-0" aria-hidden="true">
          <img class="blur-sm block w-auto h-auto opacity-30 min-w-full min-h-full absolute top-0 left-0 object-cover" src="https://images.unsplash.com/photo-1558898268-945a005f79fa?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80" alt="" />
        </div>






        <div class="mx-auto max-w-lg text-left sm:text-center lg:mx-0 lg:flex-auto lg:py-32 lg:text-left z-10 relative">
          <h2 v-if="cats" class="text-3xl font-bold tracking-tight text-white sm:text-4xl">Dostaňte vaší kočku zpět domů do bezpečí</h2>
          <h2 v-else class="text-3xl font-bold tracking-tight text-white sm:text-4xl">Dostaňte vašeho mazlíčka zpět domů do bezpečí</h2>
          <p v-if="cats" class="mt-6 text-lg sm:leading-8 text-stone-300">Čím dříve ji začnete hledat, tím větší šance, že se najde. Ozvěte se nám a vše pro vás zařídíme. 7 dní v týdnu, celá ČR.</p>
          <p v-else class="mt-6 text-lg sm:leading-8 text-stone-300">Čím dříve ho začnete hledat, tím větší šance, že se najde. Ozvěte se nám a vše pro vás zařídíme. 7 dní v týdnu, celá ČR.</p>
          <div class="mt-10 block sm:flex items-center sm:justify-center gap-x-6 lg:justify-start">
            <router-link to="/registrovat" class="block rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white">Oznámit ztrátu zvířete</router-link>
            <router-link to="/jak-to-funguje" class="block mt-4 sm:mt-0 text-sm font-semibold leading-6 text-white">Jak to funguje? <span aria-hidden="true">→</span></router-link>
          </div>
        </div>

        <img class="safarifix block my-12 w-9/12 mx-auto max-w-[250px] lg:w-auto lg:mx-0 lg:my-0 h-[calc(100%-100px)] z-10 relative" src="../assets/svg/walkingLady.svg" alt="walkingLady" />

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CtaMain',
  props: {
    cats: Boolean,
  }
}
</script>