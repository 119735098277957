<template>
	<MainHeader></MainHeader>

	<div class="bg-stone-100 py-10 pb-16 sm:py-24 isolate">

    <div class="relative isolate -z-10">
      <svg class="absolute inset-x-0 top-0 -z-10 h-[64rem] w-full stroke-stone-200 [mask-image:radial-gradient(32rem_32rem_at_center,white,transparent)]" aria-hidden="true">
        <defs>
          <pattern id="1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84" width="200" height="200" x="50%" y="-1" patternUnits="userSpaceOnUse">
            <path d="M.5 200V.5H200" fill="none" />
          </pattern>
        </defs>
        <svg x="50%" y="-1" class="overflow-visible fill-stone-50">
          <path d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z" stroke-width="0" />
        </svg>
        <rect width="100%" height="100%" stroke-width="0" fill="url(#1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84)" />
      </svg>
      <div class="absolute left-1/2 right-0 top-0 -z-10 -ml-24 transform-gpu overflow-hidden blur-3xl lg:ml-24 xl:ml-48" aria-hidden="true">
        <div class="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#FF9A45] to-[#FF405B] opacity-20" style="clip-path: polygon(63.1% 29.5%, 100% 17.1%, 76.6% 3%, 48.4% 0%, 44.6% 4.7%, 54.5% 25.3%, 59.8% 49%, 55.2% 57.8%, 44.4% 57.2%, 27.8% 47.9%, 35.1% 81.5%, 0% 97.7%, 39.2% 100%, 35.2% 81.4%, 97.2% 52.8%, 63.1% 29.5%)" />
      </div>
    </div>

    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-12 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2">
        <div class="lg:pr-4">
          <div class="relative overflow-hidden h-fit rounded-3xl bg-stone-900 shadow-2xl lg:max-w-lg">
            <iframe class="w-full aspect-[16/9]" src="https://www.youtube.com/embed/nABwpXFwhA0"></iframe>
          </div>
        </div>
        <div>
          <div class="text-base leading-7 text-stone-700 lg:max-w-xl">
            <p class="text-base font-semibold leading-7 text-orange-600">Fungování pátracích kampaní</p>
            <h1 class="mt-2 text-3xl font-bold tracking-tight text-stone-900 sm:text-4xl">Jak přesně pátrání probíhá a funguje?</h1>
            <div class="max-w-xl">
              <p class="mt-6">Oproti <router-link class="underline" to="/psi-detektiv">ostatním portálům</router-link> neposkytujeme jen databázi ztracených zvířat. Vytvořili jsme komplexní pátrací systém s placenými reklamami na sociálních sítích.</p>
              <p class="mt-8">Když se ztratí domácí <router-link class="underline" to="/ztracena-zvirata/kocky">kočka</router-link> nebo <router-link class="underline" to="/ztracena-zvirata/psi">pes</router-link>, chceme udělat maximum pro to, aby se zpráva o jeho ztrátě dostala ke každému člověku v okolí. Toho bohužel nedocílíme jen vylepováním plakátů a příspěvky ve veřejných skupinách na internetu.</p>
              <p class="mt-8">V rámci našeho agenturního přístupu jsme vám schopni vytvořit úplně stejné "marketigové" (v našem případě pátrací) kampaně, jako si běžně objednávají velké společnosti na sítích Facebook a Instagram. Tyto reklamy s popisem a fotkou vašeho zvířátka jsme potom schopni velice přesně zacílit na jakoukoliv lokalitu, kterou si zvolíme. Současně cílíme i na skupinu uživatelů, u které je největší pravděpodobnost, že vašeho mazlíčka viděli.</p>
            </div>
          </div>
          <dl class="mt-10 grid grid-cols-1 gap-8 border-t border-stone-900/10 pt-10 sm:grid-cols-3">
            <div v-for="(stat, statIdx) in stats" :key="statIdx">
              <dt class="text-sm font-semibold leading-6 text-stone-600">{{ stat.label }}</dt>
              <dd class="mt-2 text-3xl font-bold leading-10 tracking-tight text-stone-900">{{ stat.value }}</dd>
            </div>
          </dl>
          <div class="mt-10 flex">
            <a href="/o-nas" class="text-base font-semibold leading-7 text-orange-600">Zjistěte více o tom, kdo jsme <span aria-hidden="true">&rarr;</span></a>
          </div>
        </div>
      </div>
    </div>
  </div>


	<div class="bg-white pt-16 sm:pt-24">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-2xl lg:mx-0">
        <h2 class="text-3xl font-bold tracking-tight text-stone-900 sm:text-4xl">Naše strategie při hledání ztracených domácích mazlíčků</h2>
        <p class="mt-6 text-lg leading-8 text-stone-600">Co vše si hlídáme, aby se nám podařilo vašeho ztraceného pejska nebo kočičku v pořádku vrátit domů.</p>
      </div>
      <div class="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
        <dl class="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
          <div v-for="feature in features" :key="feature.name" class="flex flex-col">
            <dt class="text-xl font-semibold leading-7 text-stone-900">
              <div class="mb-8 flex rounded-lg">
                <!-- <component :is="feature.icon" class="h-6 w-6 text-white" aria-hidden="true" /> -->
                <img :src="feature.icon" class="w-full max-w-[300px] aspect-1" alt="" />
              </div>
              {{ feature.name }}
            </dt>
            <dd class="mt-4 flex flex-auto flex-col text-base leading-7 text-stone-600">
              <p class="flex-auto">{{ feature.description }}</p>
            </dd>
          </div>
        </dl>

        <router-link to="/registrovat" class="cursor-pointer block w-fit mt-12 lg:mx-auto text-md font-semibold leading-6 text-white px-8 py-3 bg-gradient-to-r rounded-md from-[#FF9A45] hover:from-[#E68B3E] to-[#FF405B] hover:to-[#E53952] transition-all hover:drop-shadow-sm drop-shadow-md">Registrovat ztrátu</router-link>

      </div>
    </div>
  </div>


	<div class="overflow-hidden bg-white pt-24 sm:pt-32">
    <div class="mx-auto max-w-7xl md:px-6 lg:px-8">
      <div class="grid grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:grid-cols-2 lg:items-start">
        <div class="px-6 md:px-0 lg:pr-4 lg:pt-4">
          <div class="mx-auto max-w-2xl lg:mx-0 lg:max-w-lg">
            <h2 class="text-base font-semibold leading-7 text-orange-600">Proč to děláme takto?</h2>
            <p class="mt-2 text-3xl font-bold tracking-tight text-stone-900 sm:text-4xl">Hlavní výhody při pátrání přes placené reklamy</p>
            <p class="mt-6 text-lg leading-8 text-stone-600">Pátrání tímto způsobem sice není zdarma, ale je dramaticky efektivnější, než jakýkoliv jiný způsob.</p>
            <dl class="mt-10 max-w-xl space-y-8 text-base leading-7 text-stone-600 lg:max-w-none">
              <div v-for="feature in features2" :key="feature.name" class="relative pl-9">
                <dt class="inline font-semibold text-stone-900">
                  <component :is="feature.icon" class="absolute left-1 top-1 h-5 w-5 text-orange-600" aria-hidden="true" />
                  {{ feature.name }}
                </dt>
                {{ ' ' }}
                <dd class="block">{{ feature.description }}</dd>
              </div>
            </dl>
          </div>
        </div>
        <div class="sm:px-6 lg:px-0">

          <div class="relative isolate overflow-hidden bg-stone-50 sm:mx-auto sm:max-w-2xl sm:rounded-3xl lg:mx-0 lg:max-w-none flex items-center justify-center py-8 sm:py-12">

            <img src="../assets/svg/stats.svg" class="h-96 max-w-sm " alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>



	<FaqSection></FaqSection>

	<MainFooter></MainFooter>
</template>


<script setup>

import { MegaphoneIcon, MapPinIcon, CalendarDaysIcon } from '@heroicons/vue/20/solid'
import how1 from '../assets/svg/how1.svg'
import how2 from '../assets/svg/how2.svg'
import how3 from '../assets/svg/how3.svg'
import { useSeoMeta } from '@vueuse/head'

useSeoMeta ({
	title: 'Jak fungují pátrací kampaně? |  Hledáme ztracená domácí zvířata',
	description: 'Pomohli jsme najít stovky ztracených psů a koček po celé ČR. Jaké je naše strategie? Přesnost pátrání 1 km². Oslovíme 16 tisíc lidí. Vše spustíme do 3 hodin.',
	ogDescription: 'Pomohli jsme najít stovky ztracených psů a koček po celé ČR. Jaké je naše strategie? Přesnost pátrání 1 km². Oslovíme 16 tisíc lidí. Vše spustíme do 3 hodin.',
	ogTitle: 'Jak fungují pátrací kampaně? |  Hledáme ztracená domácí zvířata',
	ogImage: 'https://najdimazlicka.cz/og.jpg',
	twitterCard: 'summary_large_image',
});


const stats = [
  { label: 'Přesnost pátrání', value: '1 km²' },
  { label: 'Průměrně oslovených', value: '16 tisíc lidí' },
  { label: 'Průměrně vše spustíme', value: 'do 3 hodin' },
]

const features = [
  {
    name: 'Vytvoříme Facebook a Instagram reklamy',
    description:
      'Manuálně vytvoříme a spustíme reklamy na sociálních sítích. Všechny reklamy jsou dělané na míru podle předem schválených pravidel.',
    icon: how1,
  },
  {
    name: 'Zaměříme všechny lidi v okolí',
    description:
      'Vytipujeme si, kde by se mohlo zvíře pohybovat. V té lokalitě následně spustíme všechny reklamy. Pokud si nejsme jistí, testujeme více lokalit najednou.',
    icon: how2,
  },
  {
    name: 'S každou stopou kampaň vylepšujeme',
    description:
      'Jakmile nám lidé začnou psát komentáře s bližšími informacemi o tom, kde a kdy zvíře viděli, okamžitě upravíme podle nich cílení reklam a vás informujeme.',
    icon: how3,
  },
]

const features2 = [
  {
    name: 'Výrazně efektivnější, než běžné příspěvky.',
    description:
      'S klasickým příspěvkem ve Facebookové skupině oslovíte v průměru jen 6,4% lidí. Oproti SMS hledání oslovujeme 29x více lidí. U hledání prostřednictvím emailů lidem často zpráva spadne do nevyžádané pošty. Pouze s námi jste schopni oslovit tak velké množsví lidí za tak krátkou dobu.',
    icon: MegaphoneIcon,
  },
  {
    name: 'Oslovíme až desítky tisíc lidí v libovolné lokalitě.',
    description: 'Díky našim marketingovým nástrojům se nám daří oslovit kolem 16 tisíc lidí u průměrné kampaně. Rekord máme u jednoho Labradora, kde se oslovilo dokonce více než 110 tisíc lidí! Kromě dosahu, za který platíme, totiž mnoho lidí z dobré vůle sdílí pátrací příspěvky dále mezi své známé.',
    icon: MapPinIcon,
  },
  {
    name: 'Oproti klasickému příspěvku se reklamy zobrazují i několik dní.',
    description: 'Běžný příspěvek na Facebooku nebo Instagramu se lidem zobrazuje pouze asi jen 3 hodiny. S námi je to stadardně 7 dní. Je to způsobené tím, že jako reklamy se naše pátrání zobrazuje vždy prioritně.',
    icon: CalendarDaysIcon,
  },
]
</script>

<script>
import MainHeader from '../components/MainHeader.vue'
import MainFooter from '../components/MainFooter.vue'
import FaqSection from '@/components/FaqSection.vue'
import BreadCrumb from '@/components/BreadCrumb.vue'

export default {
	name: 'HowView',
	components: {
		MainHeader,
		MainFooter,
		FaqSection,
    BreadCrumb,
	}
}
</script>