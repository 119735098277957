<template>
  <div class="bg-stone-50">
    <div class="mx-auto max-w-7xl py-16 sm:px-2 sm:py-24 lg:px-4">
      <div class="mx-auto max-w-2xl px-4 lg:max-w-none">
        <div class="grid grid-cols-1 items-center gap-x-16 gap-y-10 lg:grid-cols-2">
          <div>
            <h2 v-if="cats" class="text-3xl lg:text-4xl lg:text-[35px] font-bold tracking-tight text-gray-900">Pomohli jsme najít už stovky <router-link class="underline" to="/ztracena-zvirata/kocky">ztracených koček a kocourů</router-link></h2>
            <h2 v-else-if="detektiv" class="text-3xl lg:text-4xl lg:text-[35px] font-bold tracking-tight text-gray-900">Co je Najdi Mazlíčka?</h2>
            <h2 v-else class="text-3xl lg:text-4xl lg:text-[35px] font-bold tracking-tight text-gray-900">Pomohli jsme najít už stovky ztracených <router-link class="underline" to="/ztracena-zvirata/psi">psů</router-link> a <router-link class="underline" to="/ztracena-zvirata/kocky">koček</router-link> po celé ČR</h2>
            
            <p class="mt-8 text-gray-500" v-if="cats">
              Jako "páníčci" moc dobře víme, jaká je bezmoc, ztratit svého chlupatého kamaráda. Obvolávání útulků a lepení plakátů je zdlouhavé. Veřejné skupiny na Facebooku jsou plné nerelevatních lidí. A na internetové databáze už nikdo nechodí.
              <br/><br/>
              Rozhodli jsme se tedy vytvořit lepší a opravdu efektivní systém, jak hledat ztracené kočky. Pomocí našeho systému oslovíte až desetitisíce lidí v lokalitě, kde se váš mazlíček ztratil. Moc nás pak hřeje u srdce, když vidíme kolik ztracených kočiček se díky nám podařilo vrátit domů.
            </p>

            <p class="mt-8 text-gray-500" v-else>
              Jako "páníčci" moc dobře víme, jaká je bezmoc, ztratit svého chlupatého kamaráda. Obvolávání útulků a lepení plakátů je zdlouhavé. Veřejné skupiny na Facebooku jsou plné nerelevatních lidí. A na internetové databáze už nikdo nechodí.
              <br/><br/>
              Rozhodli jsme se tedy vytvořit lepší a opravdu efektivní systém, jak hledat ztracená zvířata. Pomocí našeho systému oslovíte až desetitisíce lidí v lokalitě, kde se váš mazlíček ztratil. Moc nás pak hřeje u srdce, když vidíme kolik ztracených zvířat se díky nám podařilo vrátit domů.
            </p>


            

            <dl class="mt-10 space-y-4 text-base leading-7 text-gray-600">
              <div class="flex gap-x-4">
                <dt class="flex-none">
                  <span class="sr-only">Telefon</span>
                  <svg class="h-7 w-6 text-gray-400" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                  </svg>
                </dt>
                <dd><a class="hover:text-gray-900" href="tel:+420 605 176 305">+420 605 176 305</a></dd>
              </div>
              <div class="flex gap-x-4">
                <dt class="flex-none">
                  <span class="sr-only">Email</span>
                  <svg class="h-7 w-6 text-gray-400" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                  </svg>
                </dt>
                <dd><a class="hover:text-gray-900" href="mailto:podpora@najdimazlicka.cz">podpora@najdimazlicka.cz</a></dd>
              </div>

              <div class="w-fit flex -space-x-4">
                <img class="w-10 h-10 border-2 border-white rounded-full" src="../assets/img/albert.jpg" alt="">
                <img class="w-10 h-10 border-2 border-white rounded-full" src="../assets/img/anicka.jpg" alt="">
                <div class="relative">
                  <img class="w-10 h-10 border-2 border-white rounded-full" src="../assets/img/jirka.jpg" alt="">
                  <span class="top-0 left-7 absolute  w-3.5 h-3.5 bg-green-400 border-2 border-white rounded-full"></span>
                </div>
              </div>
            </dl>

          </div>
          <div>
            <div class="aspect-h-2 aspect-w-3 overflow-hidden rounded-lg bg-gray-100">
              <img src="../assets/img/founders.jpg" alt="" class="object-cover object-center" />
            </div>
            <p class="mt-4 text-sm text-stone-500">Zakladatelé NajdiMazlíčka.cz</p>
          </div>

        </div>
        <div class="mt-16 grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-3">
          <div v-for="incentive in incentives" :key="incentive.name" class="sm:flex lg:block">
            <div class="flex align-middle h-16 sm:flex-shrink-0">
              <img class="max-w-[100px] max-h-16" :src="incentive.imageSrc" alt="" />
            </div>
            <div class="mt-4 sm:ml-6 sm:mt-0 lg:ml-0 lg:mt-6">
              <h3 class="text-sm font-medium text-gray-900">{{ incentive.name }}</h3>
              <p class="mt-2 text-sm text-gray-500">{{ incentive.description }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import aboutUsFeature1 from '../assets/svg/aboutUsFeature1.svg'
  import aboutUsFeature2 from '../assets/svg/aboutUsFeature2.svg'
  import aboutUsFeature3 from '../assets/svg/aboutUsFeature3.svg'

const incentives = [
  {
    name: 'Kampaň spustíme do pár hodin',
    imageSrc: aboutUsFeature2,
    description: "Náš tým je k dispozici 7 dní v týdnu. Ihned po vaší registraci začínáme na kampani pracovat.",
  },
  {
    name: '100% Garance vrácení peněz',
    imageSrc: aboutUsFeature1,
    description: "Vrátíme vám všechny peníze, pokud se náhodou nepodaří za stanovený čas oslovit tolik lidí, jak odhadujeme.",
  },
  
  {
    name: 'Celá ČR, 7 dní v týdnu',
    imageSrc: aboutUsFeature3,
    description:
      "Nezáleží jestli jste z Prahy, Brna, z malé vesnice nebo zahraničí. Kampaň nastavíme vždy na míru.",
  },
]
</script>


<script>
export default {
  name: 'AboutUsVue',
  props: {
    cats: Boolean,
    detektiv: Boolean
  }
}
</script>