import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueCookies from 'vue-cookies'
import { createHead } from "@vueuse/head"
import './tailwind.css'
import { createGtm } from '@gtm-support/vue-gtm';


const head = createHead()



createApp(App)
    .use(head)
    .use(store)
    .use(router)
    .use(createGtm({
        id: 'GTM-K63RNDZ',
        vueRouter: router,
    }))
    .use(VueCookies)
    .mount('#app')



