<template>
    <div class="shadow-sm ring-1 ring-gray-900/5 bg-stone-100 md:flex md:items-center md:justify-between p-6 mb-10 rounded-xl">
        <div class="min-w-0 flex-1">
            <h2 class="text-2xl font-bold leading-7 text-stone-900 sm:truncate sm:text-3xl sm:tracking-tight">{{title}}</h2>
        </div>
        <div class="mt-4 hidden md:flex md:ml-4 md:mt-0">
            <BasicButton v-if="button" name="submit" text="Uložit a pokračovat" type="submit"></BasicButton>
            <!-- <button type="submit" name="submit" class="ml-3 inline-flex items-center rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600">Uložit a pokračovat</button> -->
        </div>
    </div>
</template>

<script>
import BasicButton from './BasicButton.vue'
// <!-- <button type="button" class="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-stone-900 shadow-sm ring-1 ring-inset ring-stone-300 hover:bg-stone-50">Edit</button> -->

export default {
    name: 'TitleHeader',
    components: {
    BasicButton,
},
    props: {
        title: {
            type: String,
            default: "Title header"
        },
        button: {
            type: Boolean,
            default: true
        }
    },
}
</script>