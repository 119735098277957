import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import DetektivView from '../views/DetektivView.vue'
import KociciDetektivView from '../views/KociciDetektivView.vue'
import LostView from '../views/LostView.vue'
import LostCatsView from '../views/LostCatsView.vue'
import LostDogsView from '../views/LostDogsView.vue'
import LostBirdsView from '../views/LostBirdsView.vue'
import HowView from '../views/HowView.vue'
import PricingView from '../views/PricingView.vue'
import AboutView from '../views/AboutView.vue'
import PetView from '../views/PetView.vue'
import VopView from '../views/VopView.vue'
// import ReportView from '../views/ReportView.vue'
import RegistrationView from '../views/RegistrationView.vue'
import DescriptionView from '../views/DescriptionView.vue'
import TestemonialsView from '../views/TestemonialsView.vue'
import OrderView from '../views/OrderView.vue'
import PrivacyView from '../views/PrivacyView.vue'
import PageNotFound from '../views/PageNotFound.vue'
import SuccessView from '../views/SuccessView.vue'
import FailureView from '../views/FailureView.vue'
import RetryPaymentView from '../views/RetryPaymentView.vue'
import WhatToDoDog from '../views/WhatToDoDog.vue'
import WhatToDoCat from '../views/WhatToDoCat.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/ztracena-zvirata',
    name: 'lost',
    component: LostView
  },
  {
    path: '/psi-detektiv',
    name: 'detektiv',
    component: DetektivView
  },
  {
    path: '/kocici-detektiv',
    name: 'kociciDetektiv',
    component: KociciDetektivView,
  },
  {
    path: '/co-delat-kdyz-se-ztrati-pes',
    name: 'whatToDoDog',
    component: WhatToDoDog,
  },
  {
    path: '/co-delat-kdyz-se-ztrati-kocka',
    name: 'whatToDoCat',
    component: WhatToDoCat,
  },
  {
    path: '/ztracena-zvirata/kocky',
    name: 'lostCats',
    component: LostCatsView
  },
  {
    path: '/ztracena-zvirata/psi',
    name: 'lostDogs',
    component: LostDogsView
  },
  {
    path: '/ztracena-zvirata/ptaci',
    name: 'lostBirds',
    component: LostBirdsView
  },
  {
    path: '/jak-to-funguje',
    name: 'how',
    component: HowView
  },
  {
    path: '/cenik',
    name: 'pricing',
    component: PricingView
  },

  {
    path: '/pribehy-ostatnich',
    name: 'testemonials',
    component: TestemonialsView
  },
  {
    path: '/o-nas',
    name: 'about',
    component: AboutView
  },
  {
    path: '/registrovat',
    name: 'registration',
    component: RegistrationView
  },
  {
    path: '/popisek',
    name: 'description',
    component: DescriptionView
  },
  {
    path: '/objednavka',
    name: 'order',
    component: OrderView
  },
  {
    path: '/mazlicek/:uid',
    name: 'pet',
    component: PetView,
    props: true
  },
  {
    path: '/:pathMatch(.*)*', 
    component: PageNotFound
  },
  {
    path: '/success',
    component: SuccessView
  },
  {
    path: '/failure',
    component: FailureView
  },
  {
    path: '/retryPayment/:uid',
    component: RetryPaymentView,
    props: true
  },
  {
    path: '/obchodni-podminky',
    component: VopView 
  },
  {
    path: '/podminky-ochrany-osobnich-udaju',
    component: PrivacyView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior() {
      document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  },
  routes
})

export default router
