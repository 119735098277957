<template>
    <input :name='name' :value='text' :type="type" class="cursor-pointer text-md font-semibold leading-6 text-white px-4 py-3 bg-gradient-to-r rounded-md from-[#FF9A45] hover:from-[#E68B3E] to-[#FF405B] hover:to-[#E53952] transition-all hover:drop-shadow-sm drop-shadow-md">
</template>

<script>
export default {
    name: 'BasicButton',
    props: {
        name: String,
        text: {
            type: String,
            default: "Button"
        },
        type: {
            type: String,
            default: "button"
        }
    },
}
</script>