<template>
    <BareboneHeader />
    <div class="bg-stone-50 py-8">

        
        <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">

            <TitleHeader :button="false" title="Vytvoření popisku na sociální sítě" />





            <div class="md:flex justify-between md:space-x-12 lg:space-x-24">
                <form @submit.prevent="formSubmit" class="w-full">
                    <div>
                        <label for="adDescription" class="block text-sm font-medium leading-6 text-gray-900">Napište popisek, který bude pod příspěvkem na sociálních sítích</label>
                        <div class="mt-2">
                            <textarea rows="4" required v-model="adDescription" name="adDescription" id="adDescription" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                        </div>

                        <p class="text-sm text-stone-600 mt-6"><span class="text-black font-bold">Tipy jak správně napsat popisek:</span> Snažte se být co nejvíce struční a konkrétní. Lidé neradi čtou dlouhé příspěvky. Popište místo ztráty, čas, odměnu za nález a unikátní znaky mazlíčka, které nemusí být na fotce viditelné. Hodí se také přiložit vaše telefonní číslo, na které mohou lidé okamžitě reagovat. Pokud si se psaním nevíte rady, nechte to na nás a klikněte na tlačítko "Napište text za nás".</p>
                    </div>
                    <div class="flex flex-row mt-10">
                        
                        
                        
                        <BasicButton text="Pokračovat" type="submit" />


                        <HollowButton @click="addAdDescription" text="Napište text za nás" />
                    </div>
                </form>

                <div class="mt-12 md:mt-0">
                    <h3 class="font-bold text-xl">Ukázka, jak reklama bude vypadat:</h3>

                    <p class="mb-8 mt-4">Každý příspěvek zpracováváme manuálně. Pokud tedy něco graficky nesedí, můžete se spolehnout, že to před spuštěním vždy opravíme.</p>
                    <div class="aspect-[366/729] relative w-full max-w-md md:w-96">
                        <div class="absolute inset-y-[calc(1/729*100%)] left-[calc(7/729*100%)] right-[calc(5/729*100%)] rounded-[calc(58/366*100%)/calc(58/729*100%)] shadow-2xl"></div>
                        <div class="bg-gray-100 absolute left-[calc(23/366*100%)] top-[calc(23/729*100%)] grid h-[calc(686/729*100%)] w-[calc(318/366*100%)] transform grid-cols-1 overflow-hidden pt-[calc(23/318*100%)]">
                            <div class="bg-gray-100 p-4">
                                <div class="bg-white border rounded-sm max-w-md">
                                    <div class="flex items-center px-4 py-3">
                                    <img class="h-8 w-8 rounded-full" src="../assets/img/igProfile.jpeg"/>
                                    <div class="ml-3 ">
                                        <span class="text-sm font-semibold antialiased block leading-tight">Najdi Mazlíčka</span>
                                        <span class="text-gray-600 text-xs block">Místo, kde se mazlíček ztratil</span>
                                    </div>
                                    </div>
                                    <div class="relative w-full aspect-1">

                                        <div class="absolute z-10 bg-white shadow-lg text-2xl font-bold p-5 py-3 rounded-3xl bottom-[20px] whitespace-nowrap text-center left-1/2 -translate-x-1/2">📍 Praha</div>
                                        <img class="absolute top-0 left-0 right-0 bottom-0 w-full" :src="src" />
                                    </div>

                                    <p class="px-4 py-3 text-sm"><b>@najdimazlicka</b> {{adDescription}}</p>

                                    <div class="flex items-center justify-between mx-4 mt-3 mb-2">
                                        <div class="flex gap-5">
                                            <svg fill="#262626" height="24" viewBox="0 0 48 48" width="24"><path d="M34.6 6.1c5.7 0 10.4 5.2 10.4 11.5 0 6.8-5.9 11-11.5 16S25 41.3 24 41.9c-1.1-.7-4.7-4-9.5-8.3-5.7-5-11.5-9.2-11.5-16C3 11.3 7.7 6.1 13.4 6.1c4.2 0 6.5 2 8.1 4.3 1.9 2.6 2.2 3.9 2.5 3.9.3 0 .6-1.3 2.5-3.9 1.6-2.3 3.9-4.3 8.1-4.3m0-3c-4.5 0-7.9 1.8-10.6 5.6-2.7-3.7-6.1-5.5-10.6-5.5C6 3.1 0 9.6 0 17.6c0 7.3 5.4 12 10.6 16.5.6.5 1.3 1.1 1.9 1.7l2.3 2c4.4 3.9 6.6 5.9 7.6 6.5.5.3 1.1.5 1.6.5.6 0 1.1-.2 1.6-.5 1-.6 2.8-2.2 7.8-6.8l2-1.8c.7-.6 1.3-1.2 2-1.7C42.7 29.6 48 25 48 17.6c0-8-6-14.5-13.4-14.5z"></path></svg>
                                            <svg fill="#262626" height="24" viewBox="0 0 48 48" width="24"><path clip-rule="evenodd" d="M47.5 46.1l-2.8-11c1.8-3.3 2.8-7.1 2.8-11.1C47.5 11 37 .5 24 .5S.5 11 .5 24 11 47.5 24 47.5c4 0 7.8-1 11.1-2.8l11 2.8c.8.2 1.6-.6 1.4-1.4zm-3-22.1c0 4-1 7-2.6 10-.2.4-.3.9-.2 1.4l2.1 8.4-8.3-2.1c-.5-.1-1-.1-1.4.2-1.8 1-5.2 2.6-10 2.6-11.4 0-20.6-9.2-20.6-20.5S12.7 3.5 24 3.5 44.5 12.7 44.5 24z" fill-rule="evenodd"></path></svg>
                                            <svg fill="#262626" height="24" viewBox="0 0 48 48" width="24"><path d="M47.8 3.8c-.3-.5-.8-.8-1.3-.8h-45C.9 3.1.3 3.5.1 4S0 5.2.4 5.7l15.9 15.6 5.5 22.6c.1.6.6 1 1.2 1.1h.2c.5 0 1-.3 1.3-.7l23.2-39c.4-.4.4-1 .1-1.5zM5.2 6.1h35.5L18 18.7 5.2 6.1zm18.7 33.6l-4.4-18.4L42.4 8.6 23.9 39.7z"></path></svg>
                                        </div>
                                        <div class="flex">
                                            <svg fill="#262626" height="24" viewBox="0 0 48 48" width="24"><path d="M43.5 48c-.4 0-.8-.2-1.1-.4L24 29 5.6 47.6c-.4.4-1.1.6-1.6.3-.6-.2-1-.8-1-1.4v-45C3 .7 3.7 0 4.5 0h39c.8 0 1.5.7 1.5 1.5v45c0 .6-.4 1.2-.9 1.4-.2.1-.4.1-.6.1zM24 26c.8 0 1.6.3 2.2.9l15.8 16V3H6v39.9l15.8-16c.6-.6 1.4-.9 2.2-.9z"></path></svg>
                                        </div>
                                    </div>
                                    <div class="font-semibold text-sm mx-4 mt-2 mb-4">92,372 likes</div>
                                </div>
                            </div>

                        </div>
                        <img src="../assets/svg/iphone.svg" class="pointer-events-none absolute inset-0 h-full w-full fill-gray-100" alt=""/>

                    </div>
                </div>
            </div>
        </div>
    </div>


</template>


<script setup>

import { useSeoMeta } from '@vueuse/head'

useSeoMeta ({
	title: 'Vytvořte si příspěvek | Najdi Mazlíčka',
    description: 'Ztratil se vám pes nebo kočka? Vytvoříme speciální reklamní kampaň. Do pár hodin oslovíme tisíce lidí v lokalitě kde se váš mazlíček ztratil. 5 let zkušeností.',
    ogDescription: 'Ztratil se vám pes nebo kočka? Vytvoříme speciální reklamní kampaň. Do pár hodin oslovíme tisíce lidí v lokalitě kde se váš mazlíček ztratil. 5 let zkušeností.',
    ogTitle: 'Vytvořte si příspěvek | Najdi Mazlíčka',
    ogImage: 'https://najdimazlicka.cz/og.jpg',
    twitterCard: 'summary_large_image',
    robots: "noindex"
});

</script>

<script>
import BareboneHeader from '../components/BareboneHeader.vue'
import BasicButton from '@/components/BasicButton.vue'
import HollowButton from '../components/HollowButton.vue'
import TitleHeader from '../components/TitleHeader.vue'

const axios = require('axios')

const server_url = process.env.SERVER_URL
const url = `${server_url}/api/pets`
const filter = "?filters[uid][$eq]="
const populateFilter = "&populate=*"

export default {
    name: 'DescriptionView',
    components: {
        BareboneHeader,
        BasicButton,
        HollowButton,
        TitleHeader,
    },
    data() {
        return {
            uid: "",
            adDescription: "",
            src: ""
        }
    },
    methods: {
        async formSubmit() {
            await axios.put(`${url}/${this.petId}`, {
                data: {
                    adDescription: this.adDescription
                }
            })

            this.$router.push('/objednavka')
        },
        addAdDescription() {
            this.adDescription = "! Napište to prosím za nás !"
            this.formSubmit()
        }
    },
    created() {
        this.uid = this.$cookies.get('mazel-pet-info').uid
    },
    async mounted() {
        await axios.get(url + filter + this.uid + populateFilter)
            .then(res => {
                const data = res.data.data[0]
                this.petId = data.id
                this.src = data.attributes.petImg.data.attributes.url
            })

    }
}
</script>

