<template>
	<MainHeader></MainHeader>

	<main>
		<!-- Pricing section -->
		<div class="isolate">
			<div class="flow-root bg-stone-100 py-12 sm:py-24 lg:pb-4">
				<div class="mx-auto max-w-7xl px-6 lg:px-8">
					<div class="relative z-10">
						<h1 class="mx-auto max-w-5xl text-center text-3xl sm:text-5xl font-bold tracking-tight text-stone-900">Vyberte si pátrací balíček, který vám vyhovuje</h1>
						<p class="mx-auto mt-8 max-w-4xl text-center text-lg leading-8 text-stone-900/80">Základní registrace do naší pátrací databáze je a vždy bude zdarma. Hlavní rozdíl mezi pátracími balíčky je rozpočet na reklamu, který platíme Facebooku. S dražším balíčkem tedy můžete očekávat, že se příspěvek o ztrátě vašeho zvířete zobrazí více lidem, vícekrát a rychleji.</p>

						<div class="overflow-hidden max-w-xl mx-auto mt-12 rounded-lg bg-white shadow-lg">
							<div class="px-4 py-5 sm:p-6 flex items-center justify-between">
								<img src="../assets/svg/garance.svg" class="w-3/12" alt="" />
								<div class="w-8/12">
									<h2 class="text-xl font-bold">Garance výkonu</h2>
									<p class="text-stone-600 mt-4">Vrátíme vám všechny peníze, pokud se náhodou nepodaří za stanovený čas oslovit tolik lidí, jako odhadujeme u každého balíčku.</p>
								</div>
							</div>
						</div>


						<p class="mx-auto mt-12 max-w-3xl text-center text-sm text-stone-900/50">Zákazníkům, kteří řeší ztrátu zvířete ve větším městě, vždy doporučujeme alespoň rozšířený balíček.</p>


						<div class="mt-16 flex justify-center">
							<RadioGroup v-model="frequency" class="grid grid-cols-2 gap-x-1 rounded-full bg-stone-900 p-1 text-center text-xs font-semibold leading-5 text-white">
								<RadioGroupLabel class="sr-only">Sociální sítě</RadioGroupLabel>
								<RadioGroupOption as="template" v-for="option in pricing.frequencies" :key="option.value" :value="option" v-slot="{ checked }">
									<div :class="[checked ? (option.label === 'Zobrazení na Facebook' ? 'bg-blue-500' : 'bg-gradient-to-r from-yellow-500 to-pink-500') : '', 'cursor-pointer rounded-full px-2.5 py-1 flex items-center text-center justify-between']">
										<span class="block mx-auto">{{ option.label }}</span>
									</div>
								</RadioGroupOption>
							</RadioGroup>
						</div>
					</div>
					<div class="relative mx-auto mt-10 grid max-w-md grid-cols-1 gap-y-8 lg:mx-0 lg:-mb-14 lg:max-w-none lg:grid-cols-4 ">
						<svg viewBox="0 0 1208 1024" aria-hidden="true" class="absolute hidden xl:block -bottom-48 left-1/2 h-[64rem] -translate-x-1/2 translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] lg:-top-48 lg:bottom-auto lg:translate-y-0">
							<ellipse cx="604" cy="512" fill="url(#d25c25d4-6d43-4bf9-b9ac-1842a30a4867)" rx="604" ry="512" />
							<defs>
								<radialGradient id="d25c25d4-6d43-4bf9-b9ac-1842a30a4867">
									<stop stop-color="#FF405B" />
									<stop offset="1" stop-color="#FF9A45" />
								</radialGradient>
							</defs>
						</svg>
						<div class="hidden lg:absolute lg:inset-x-px lg:top-4 lg:bottom-10 lg:block lg:rounded-t-2xl lg:bg-white/80 lg:ring-1 lg:ring-black/10" aria-hidden="true" />



						<div v-for="tier in pricing.tiers" :key="tier.id" :class="[tier.featured ? 'z-10 bg-white/80 lg:bg-white lg:shadow-xl ring-1 ring-stone-900/10 relative' : 'bg-white/80 ring-1 ring-stone-900/10 lg:bg-transparent lg:pb-10 lg:ring-0', 'relative rounded-2xl']">
							<div class="p-6 lg:pt-10 xl:p-8 xl:pt-12">


								<!-- <span v-if="tier.featured" class="bg-orange-100 text-orange-800 text-sm uppercase font-semibold tracking-wider p-1 px-4 rounded-full block w-fit absolute -top-3">Nejoblíbenější</span> -->

								<h2 :id="tier.id" :class="[tier.featured ? 'text-stone-900' : 'text-stone-900', 'text-lg mb-3 font-semibold leading-6']">{{ tier.name }}</h2>
								<div class="flex flex-col gap-6 sm:flex-row sm:items-end sm:justify-between lg:flex-col lg:items-stretch">
									<div class="mt-2 block">
										<p :class="[tier.featured ? 'text-stone-900' : 'text-stone-900', 'text-4xl font-bold tracking-tight']">{{ tier.price[frequency.value] }}</p>
										<div class="text-sm mt-2 leading-5">
											<p :class="tier.featured ? 'text-stone-500' : 'text-stone-500'">{{ `${tier.price[frequency.value] !== '0 Kč' ? frequency.label : "Databáze ztracených zvířat"}` }}</p>
										</div>
									</div>
									<router-link :to="tier.href" :aria-describedby="tier.id" :class="[tier.featured ? 'focus-visible:outline-orange-600 cursor-pointer text-md font-semibold leading-6 bg-gradient-to-r rounded-md from-[#FF9A45] hover:from-[#E68B3E] to-[#FF405B] hover:to-[#E53952] transition-all hover:drop-shadow-sm drop-shadow-md text-white' : 'focus-visible:outline-orange-600 cursor-pointer text-md font-semibold leading-6 bg-gradient-to-r rounded-md from-[#FF9A45] hover:from-[#E68B3E] to-[#FF405B] hover:to-[#E53952] transition-all hover:drop-shadow-sm drop-shadow-md text-white', 'rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2']">Vybrat si tento balíček</router-link>
								</div>
								<p v-if="tier.featured" class="rounded-full bg-green-600/20 px-3.5 py-1 block text-xs font-semibold leading-6 text-green-600 w-fit mt-4 -mb-4">Nejoblíbenější</p>
								<p v-if="tier.performance" class="rounded-full bg-orange-200/70 px-3.5 py-1 block text-xs font-semibold leading-6 text-orange-500 w-fit mt-4 -mb-4">Největší výkon</p>
								<div class="mt-8 flow-root sm:mt-10">
									<ul role="list" :class="[tier.featured ? 'divide-stone-900/5 border-stone-900/5 text-stone-600' : 'divide-stone-900/5 border-stone-900/5 text-stone-600', '-my-2 divide-y border-t text-sm leading-6 lg:border-t-0']">
										<li v-for="mainFeature in tier.mainFeatures" :key="mainFeature" class="flex gap-x-3 py-2">
											<CheckIcon :class="[tier.featured ? 'text-orange-600' : 'text-orange-600', 'h-6 w-5 flex-none']" aria-hidden="true" />
											{{ mainFeature }}
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>


			
			<div class="relative bg-stone-50">
				<div class="mx-auto max-w-7xl">
					<!-- FAQ section -->
					<FaqSection></FaqSection>
				</div>
			</div>





		</div>

	

	</main>
	




	<MainFooter></MainFooter>
</template>

<script setup>
import { ref } from 'vue'
import { RadioGroup, RadioGroupLabel, RadioGroupOption } from '@headlessui/vue'
import { CheckIcon } from '@heroicons/vue/20/solid'
import { useSeoMeta } from '@vueuse/head'


useSeoMeta ({
	title: 'Ceník služby Najdi Mazlíčka |  Pátrací balíčky, 100% Garance ❤️',
	description: 'Registrace do naší databáze ztracených zvířat je a vždy bude zdarma. Hlavní rozdíl mezi pátracími balíčky je rozpočet na reklamu. Až desítky tisíc oslovených.',
	ogDescription: 'Registrace do naší databáze ztracených zvířat je a vždy bude zdarma. Hlavní rozdíl mezi pátracími balíčky je rozpočet na reklamu. Až desítky tisíc oslovených.',
	ogTitle: 'Ceník služby Najdi Mazlíčka |  Pátrací balíčky, 100% Garance ❤️',
	ogImage: 'https://najdimazlicka.cz/og.jpg',
	twitterCard: 'summary_large_image',
});



// Postaráme se, aby se váš mazlíček v pořádku našel


const pricing = {
  frequencies: [
    { value: 'facebook', label: 'Zobrazení na Facebook' },
    { value: 'instagram', label: 'Zobrazení na Facebook + Instagram' },
  ],
  tiers: [
	{
      name: 'Registrace do databáze',
      id: 'tier-free',
      href: '/registrovat',
      featured: false,
      performance: false,
      description: 'All your essential business finances, taken care of.',
      price: { facebook: '0 Kč', instagram: '0 Kč' },
      mainFeatures: ['Zápis do databáze', 'Fotka, popis, kontakt', 'Možnost sdílet na vlastním profilu'],
    },
    {
      name: 'Základní balíček',
      id: 'tier-basic',
      href: '/registrovat',
      performance: false,
      featured: false,
      description: 'All your essential business finances, taken care of.',
      price: { facebook: '990 Kč', instagram: '1 880 Kč' },
      mainFeatures: [
				"Pátrací kampaň trvá 2 dny",
				"Přes 3 000 zobrazení",
				"Podpora 7 dní v týdnu",
				"Bohatě stačí na vesnice",
				'Zápis do databáze', 'Fotka, popis, kontakt', 'Možnost sdílet na vlastním profilu'
			],
    },
    {
      name: 'Rozšířený balíček',
      id: 'tier-scale',
      href: '/registrovat',
      featured: true,
      performance: false,
      description: 'The best financial services for your thriving business.',
      price: { facebook: '1 990 Kč', instagram: '2 880 Kč' },
      mainFeatures: [
				"Pátrací kampaň trvá 7 dní",
				"Přes 10 000 zobrazení",
				"Prioritní podpora 7 dní v týdnu",
				"Pokryje i většinu velkých měst",
				'Zápis do databáze', 'Fotka, popis, kontakt', 'Možnost sdílet na vlastním profilu'
			],
    },
    {
      name: 'Premium balíček',
      id: 'tier-premium',
      href: '/registrovat',
      featured: false,
      performance: true,
      description: 'Convenient features to take your business to the next level.',
      price: { facebook: '2 990 Kč', instagram: '3 880 Kč' },
      mainFeatures: [
				"Pátrací kampaň trvá 7 dní",
				"Přes 16 000 zobrazení",
				"Prioritní podpora 7 dní v týdnu",
				"Maximální výkon kampaně",
				'Zápis do databáze', 'Fotka, popis, kontakt', 'Možnost sdílet na vlastním profilu'
			],
    },
  ],
  // sections: [
  //   {
  //     name: 'Catered for business',
  //     features: [
  //       { name: 'Tax Savings', tiers: { Free: true, Starter: true, Scale: true, Growth: true } },
  //       { name: 'Easy to use accounting', tiers: { Starter: true, Scale: true, Growth: true } },
  //       { name: 'Multi-accounts', tiers: { Starter: '3 accounts', Scale: 'Unlimited accounts', Growth: '7 accounts' } },
  //       { name: 'Invoicing', tiers: { Starter: '3 invoices', Scale: 'Unlimited invoices', Growth: '10 invoices' } },
  //       { name: 'Exclusive offers', tiers: { Starter: false, Scale: true, Growth: true } },
  //       { name: '6 months free advisor', tiers: { Starter: false, Scale: true, Growth: true } },
  //       { name: 'Mobile and web access', tiers: { Starter: false, Scale: true, Growth: false } },
  //     ],
  //   },
  //   {
  //     name: 'Other perks',
  //     features: [
  //       { name: '24/7 customer support', tiers: { Free: true, Starter: true, Scale: true, Growth: true } },
  //       { name: 'Instant notifications', tiers: { Free: true, Starter: true, Scale: true, Growth: true } },
  //       { name: 'Budgeting tools', tiers: { Free: true, Starter: true, Scale: true, Growth: true } },
  //       { name: 'Digital receipts', tiers: { Free: true, Starter: true, Scale: true, Growth: true } },
  //       { name: 'Pots to separate money', tiers: { Free: true, Starter: false, Scale: true, Growth: true } },
  //       { name: 'Free bank transfers', tiers: { Free: true, Starter: false, Scale: true, Growth: false } },
  //       { name: 'Business debit card', tiers: { Free: true, Starter: false, Scale: true, Growth: false } },
  //     ],
  //   },
  // ],
}

const frequency = ref(pricing.frequencies[0])
</script>


<script>

import MainHeader from '../components/MainHeader.vue'
import MainFooter from '../components/MainFooter.vue'
import FaqSection from '@/components/FaqSection.vue'

export default {
	name: 'PricingView',
	components: {
		MainHeader,
		MainFooter,
		FaqSection,
	}
}
</script>