<template>
    <!-- <div class="flex flex-row justify-center">
			<div class="flex flex-row justify-center container py-32">
				<div class="flex flex-col mr-24">
					<div class="font-black pb-12 text-6xl">
						Find your Lost Pet with Facebook and Instagram Ads
					</div>
					<div class="text-xl pb-12">
						Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptates ducimus non excepturi maxime incidunt obcaecati totam, consequuntur laboriosam distinctio accusantium eaque mollitia ipsum vero alias magnam ad autem iure repellendus.
					</div>
				</div>
				<FormSmall submitRoute="/report"></FormSmall>
			</div>
    </div> -->

		
	<div class="safarifix relative overflow-hidden bg-stone-900">
    <div class="safarifix absolute inset-0 block" aria-hidden="true">
      <img class="safarifix blur-sm block w-auto h-auto opacity-40 min-w-full min-h-full absolute top-0 left-0 object-cover" src="../assets/img/headerBackground.jpg" alt="" />
    </div>
    <div class="relative pb-16 sm:pb-24">
      <main class="mt-16 sm:mt-24">
        <div class="mx-auto max-w-7xl">
          <div class="lg:grid lg:grid-cols-12 lg:gap-8">
            <div class="px-6 sm:text-center md:mx-auto md:max-w-2xl lg:col-span-6 lg:flex lg:items-center lg:text-left">
              <div>
                <div class="block ml-0 mb-6 sm:mx-auto lg:ml-0 sm:mb-8 sm:flex sm:justify-center lg:justify-start">
                  <router-link to="/o-nas" class="block overflow-hidden p-2 w-fit sm:overflow-visible sm:flex items-center rounded-lg sm:rounded-full bg-stone-900/[.60] sm:p-1 sm:pr-2 text-white hover:text-gray-200 sm:text-base lg:text-sm xl:text-base">
                    <span class="block mb-1 sm:mb-0 w-fit rounded-full bg-maz-dark px-3 py-0.5 text-sm font-semibold leading-5 text-white">Fungujeme již 5 let</span>
                    <span class="ml-2 sm:ml-4 text-sm">Přes 8 milionů oslovených lidí</span>
                    <ChevronRightIcon class="inline-block sm:block ml-2 h-5 w-5 text-gray-500" aria-hidden="true" />
                  </router-link>
                </div>
                <h1 class="text-3xl font-bold tracking-tight text-white sm:text-4xl md:text-5xl lg:md:text-6xl leading-tight md:leading-tight">Najdeme vašeho ztraceného domácího mazlíčka 🐶🐱</h1>
                <p class="mt-8 text-base text-gray-300 sm:mt-10 sm:text-xl lg:text-lg xl:text-xl">Ztratil se vám <router-link class="underline" to="/ztracena-zvirata/psi">pes</router-link> nebo <router-link class="underline" to="/ztracena-zvirata/kocky">kočka</router-link>? Vytvoříme speciální pátrací kampaň s fotkami na Facebooku a Instagramu. Do pár hodin tak oslovíme tisíce lidí v lokalitě, kde se váš mazlíček ztratil.</p>
              </div>
            </div>
            <FormSmall submitRoute="/report"></FormSmall>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>




<script setup>
	import { ChevronRightIcon } from '@heroicons/vue/20/solid'
</script>


<script>
import FormSmall from './FormSmall.vue'


export default {
    name: 'MainLanding',
    components: {
        FormSmall,
    }
}
</script>