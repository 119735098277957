<template>
  <MainHeader></MainHeader>

  <BreadCrumb :pages="[
    { name: 'Databáze ztracených zvířat', href: '/ztracena-zvirata', current: false },
    { name: 'Co dělat, když se ztratí pes', href: '/co-delat-kdyz-se-ztrati-pes', current: true },
  ]"/>




  <div class="bg-white px-6 py-16 lg:px-8">
    <div class="mx-auto max-w-3xl text-base leading-7 text-gray-700">
      <p class="text-base font-semibold leading-7 text-orange-600">Několik užitečných tipů při ztrátě psa</p>
      <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Co dělat, když se ztratí pes?</h1>
      <p class="mt-6 text-xl leading-8">Na procházce se svým psím kamarádem se může stát spousta věcí - někdo odpálí ohňostroj, pes se rozběhne za zajícem nebo jste možná jen zapomněli uzavřít vrátka... V každém případě se pes ztratil a bohužel ještě nevrátil. Pravděpodobně teď přemýšlíte co podniknout a jak postupovat? V tomto článku se pokusíme nabídnout pomocné rady, které vám vašeho pejska pomůžou vrátit domů.</p>
      
      
      <h2 class="mt-16 text-2xl font-bold tracking-tight text-gray-900">Vyčkejte na místě ztráty</h2>
      <p class="mt-6">Pokuste se na chvíli nepanikařit a zůstaňte na stejném místě, kde jste svého psa viděli naposledy. Často se stává, že se pes něčeho zalekne, odběhne, potom se vzpamatuje a snaží se vrátit zpět na místo odloučení. Na psa mezitím volejte, a pokud víte, kudy se vydal, snažte se mu jít naproti. Alespoň 2 hodiny vydržte, než se pustíte do pátrání.</p>
  
      <h2 class="mt-16 text-2xl font-bold tracking-tight text-gray-900">Zavolejte na městskou policii, myslivce, veterináře…</h2>
      <p class="mt-6">Tímto určitě nic nezkazíte. Nejprve oznamte ztrátu Městské policii, která vás jistě dál odkáže na ostatní instituce, které by vám mohli pomoci. Veterináři ve vašem okolí mohli mezi tím, co se stresujete, již dávno vašeho pejska evidovat, stačí se jen zeptat. Myslivci zase mají dobrý přehled o všem, co se v lese děje. Navíc můžete být klidnější, že vám psa nezastřelili…</p>

      <h2 class="mt-16 text-2xl font-bold tracking-tight text-gray-900">Obvolejte útulky</h2>
      <p class="mt-6">Bohužel pouze 6% majitelů psů najde svého zatoulaného psa v útulcích. I tak to ale za zkoušku stojí. Vašeho psa mohli odchytit a teď už na vás může někde čekat. Stojí za to oslovit i útulky mimo vaše město, v případě, že by v některém například nebylo volné místo. Tady je seznam na všechny útulky v ČR https://utulek-kralupy.estranky.cz/clanky/utulky-cr.html</p>

      <h2 class="mt-16 text-2xl font-bold tracking-tight text-gray-900">Zkontrolujte online fóra</h2>
      <p class="mt-6">Zkontrolujte různá internetová fóra jako <router-link class="underline" to="/psi-detektiv">Psí detektiv</router-link> a nebo Facebookové stránky spojené s vaším bydlištěm. Může se stát, že někdo vašeho psa spatřil a oznámil to zrovna na těchto sítích. Zanechte na tam na sebe kontakt (nejlépe telefon) a čekejte…</p>

      <div class="bg-orange-50 mt-8 block lg:py-8 rounded-lg">
        <FormSmall></FormSmall>
      </div>

      <h2 class="mt-16 text-2xl font-bold tracking-tight text-gray-900">Pusťte se do sofistikovanějšího pátrání</h2>
      <p class="mt-6 mb-8">Letákami sice možná oslovíte část lidí z vašeho nejbližšího okolí, ale přitom váš pes mohl urazit desítky kilometrů. Automatizované E-maily lidé v dnešní době už téměř nečtou a cílené SMS jsou zbytečně drahé a neefektivní. A pokud zrovna nechcete celý den s ostatními pátrači procházet okolní lesy, tak nejlepší formou aktivního pátrání je rozjetí cílené kampaně na Facebooku a Instagramu. Nejjednodušší způsob, jak něco podobného spustit, je přihlásit se na webu najdimazlicka.cz. Nezapomeňte také nastavit nějakou motivující odměnu pro nálezce.</p>

      <router-link to="/"
        class="font-semibold bg-orange-600 text-white px-6 py-3 rounded-lg inline-block hover:bg-orange-700 transition duration-200"
      >
        Dozvědět se více
      </router-link>
      
      
      
      <h2 class="mt-16 text-2xl font-bold tracking-tight text-gray-900">Mezi další tipy patří</h2>
      
            
      
      <div class="mt-10 max-w-2xl">
        <p class="mt-6 mb-8">Prvním krokem je neztrácet hlavu a nechat za sebou pachovou stopu pro vašeho mazlíčka. Ztráta psa je stresující záležitost, ale je důležité zůstat klidným a podniknout kroky. Je sice možné, že si váš pejsek najde cestu zpět, ale spoléhat se na to není rozumné. Podle statistik se totiž pouze 10 % ztracených psů vrátí domů samy. Je proto vhodné zůstat na místě, kde jste byli naposledy spolu, alespoň tři hodiny a nepropadat panice. Pokud váš pes zahlédne něco lákavého nebo se vydává na průzkum, pravděpodobně se vrátí, a vaše nepřítomnost by ho mohla jen donutit hledat domov na vlastní pěst.</p>
        <p class="mt-6 mb-8">Pokud jste někde, kde nemůžete zůstat, nechte za sebou něco s vaším pachem, jako je například mikina nebo deka. Pokud je váš pes stále v okolí, určitě vás vyčenichá a počká na vás. Pravidelně se tedy vraťte na místo.</p>
        <p class="mt-6 mb-8">Důležité je také okamžitě zapojit co nejvíce lidí do pátrání. Požádejte rodinu a přátele o pomoc, kteří žijí v okolí. Zároveň postupujte podle následujících kroků:</p>
        <ul role="list" class="mt-8 max-w-xl space-y-8 text-gray-600">
          <li class="flex gap-x-3">
            <CheckCircleIcon class="mt-1 h-5 w-5 flex-none text-orange-600" aria-hidden="true" />
            <span>Kontaktujte místní policii a nahlaste ztrátu psa.</span>
          </li>
          <li class="flex gap-x-3">
            <CheckCircleIcon class="mt-1 h-5 w-5 flex-none text-orange-600" aria-hidden="true" />
            <span>Kontaktujte obecní úřad a požádejte o pomoc s odchytáváním psa a zveřejněním informací o ztrátě.</span>
          </li>
          <li class="flex gap-x-3">
            <CheckCircleIcon class="mt-1 h-5 w-5 flex-none text-orange-600" aria-hidden="true" />
            <span>Kontaktujte okolní útulky a záchranné stanice a zjistěte, zda někdo našel vašeho psa.</span>
          </li>
          <li class="flex gap-x-3">
            <CheckCircleIcon class="mt-1 h-5 w-5 flex-none text-orange-600" aria-hidden="true" />
            <span>Kontaktujte nejbližší veterinární ordinace a zaregistrujte ztrátu psa v Národním registru majitelů čipovaných zvířat.</span>
          </li>
          <li class="flex gap-x-3">
            <CheckCircleIcon class="mt-1 h-5 w-5 flex-none text-orange-600" aria-hidden="true" />
            <span>Vylepte letáky s fotografií psa a informacemi o ztrátě v okolí.</span>
          </li>
          <li class="flex gap-x-3">
            <CheckCircleIcon class="mt-1 h-5 w-5 flex-none text-orange-600" aria-hidden="true" />
            <span>Požádejte o pomoc skupiny na sociálních sítích a sdílejte informace o ztrátě psa.</span>
          </li>
        </ul>
        <p class="mt-8">Pokud se váš pes ztratí v lese, opět je důležité zůstat klidným a počkat na místě několik hodin. Použijte píšťalku nebo oblíbenou hračku k přivolání psa. Pokud to nezabere, kontaktujte místní myslivecké sdružení.</p>
        <p class="mt-6">V případě, že váš pes má čip, máte větší šanci ho najít. Nezapomeňte mít čip zaregistrovaný na vhodných serverech a v případě ztráty kontaktujte Národní registr majitelů čipovaných zvířat.</p>
        <p class="mt-6">Aby se ztráta psa předešla, je důležité zajistit, aby váš pes poslouchal vaše povely a uměl se vrátit na vaše volání. Mějte kvalitní pevné vodítko a nepouštějte psa z vodítka v neznámém prostředí. Mohou vám také pomoci obojky s GPS trackery a psí známka My Family s vaším kontaktem.</p>
        <p class="mt-6">Pokud najdete ztraceného psa, neignorujte ho. Počkejte a pokuste se ho odchytit. Pokud má obojek s kontaktem na majitele, kontaktujte majitele nebo zavolejte městskou policii. Pokud majitele nenajdete, kontaktujte útulky a záchranné stanice.</p>
        <p class="mt-6">Je důležité jednat rychle a efektivně, abyste co nejdříve znovu našli svého mazlíčka - psa i <router-link class="underline" to="/kocici-detektiv">kočku</router-link>. Moc vám držíme palce a pokud byste potřebovali pomoci, dejte nám vědět.</p>
      </div>
    </div>
  </div>




  <!-- <RecentlySaved></RecentlySaved> -->
  <MainTestemonial></MainTestemonial>
  <FaqSection></FaqSection>
  <CtaMain></CtaMain>
  <MainFooter></MainFooter>
</template>

<script setup>
  import { useSeoMeta } from '@vueuse/head'
  import { CheckCircleIcon } from '@heroicons/vue/20/solid'

	// import { ChevronRightIcon } from '@heroicons/vue/20/solid'

  useSeoMeta ({
      title: 'Co dělat, pokud se vám ztratil pes? 5 důležitých rad a tipů',
      description: 'Ztratil se vám pes a bohužel ještě nevrátil. Přemýšlíte co podniknout a jak postupovat? Pomocné rady, které vám vašeho pejska pomůžou vrátit domů.',
      ogDescription: 'Ztratil se vám pes a bohužel ještě nevrátil. Přemýšlíte co podniknout a jak postupovat? Pomocné rady, které vám vašeho pejska pomůžou vrátit domů.',
      ogTitle: 'Co dělat, pokud se vám ztratil pes? 5 důležitých rad a tipů',
      ogImage: 'https://najdimazlicka.cz/og.jpg',
      twitterCard: 'summary_large_image',
  });

</script>

<script>

import FormSmall from '../components/FormSmall.vue'
import MainHeader from '../components/MainHeader.vue'
import BreadCrumb from '../components/BreadCrumb.vue'
import MainFooter from '../components/MainFooter.vue'
import CtaMain from '../components/CtaMain.vue'
import MainTestemonial from '../components/MainTestemonial.vue'
// import RecentlySaved from '../components/RecentlySaved.vue'
import FaqSection from '../components/FaqSection.vue'


export default {
  name: 'whatToDoDog',
  components: {
      MainHeader,
      FormSmall,
      MainFooter,
      CtaMain,
      FaqSection,
  }
}
</script>

