<template>
  <div class="safarifix bg-stone-900 py-24 relative overflow-hidden">
    <!-- bg-[#231b0b] -->
    <div class="mx-auto max-w-7xl px-6 lg:px-8 relative z-10">
      <div class="mx-auto max-w-4xl lg:text-center">
        <h2 class="text-base font-semibold leading-7 text-orange-400">Jak to funguje</h2>
        <p class="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">Jak probíhají pátrací kampaně?</p>
        <p v-if="cats" class="mt-6 text-lg leading-8 text-stone-300">Představte si to úplně stejně jako reklamu, kterou běžně vídáte na vašich sociálních sítích. Pouze s fotkou a popisem vaší kočky. Zobrazíme ji všem lidem v lokalitě ztráty. Pokud někdo vaší kočku uvidí, ihned se nám ozve s informacemi.</p>
        <p v-else class="mt-6 text-lg leading-8 text-stone-300">Představte si to úplně stejně jako reklamu, kterou běžně vídáte na vašich sociálních sítích. Pouze s fotkou a popisem vašeho ztraceného mazlíčka. Zobrazíme ji všem lidem v lokalitě ztráty. Pokud někdo vašeho mazlíčka uvidí, ihned se nám ozve s informacemi.</p>
      </div>
      <div class="mx-auto mt-16 max-w-2xl sm:mt-20 lg:max-w-none">
        <dl class="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-4">
          <div v-for="feature in features" :key="feature.name" class="flex flex-col bg-white rounded-lg p-4">
            <div class="h-24 mb-4 flex items-center">
              <img :src="feature.icon" class=" max-w-[160px] max-h-24 " alt="" />
            </div>

            <dt class="flex items-center gap-x-3 text-xl font-semibold leading-7 text-stone-900">
              {{ feature.name }}
            </dt>
            <dd class="mt-4 flex flex-auto flex-col text-base leading-7 text-stone-600">
              <p class="flex-auto">{{ feature.description }}</p>
            </dd>
          </div>
        </dl>
      </div>

      <router-link to="/registrovat" class="rounded-md mx-auto block w-fit bg-white px-4 py-3 mt-20 font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white">Oznámit ztrátu zvířete</router-link>

    </div>

    <div class="absolute inset-0 block z-0 safarifix" aria-hidden="true">
      <img class="safarifix blur-sm block w-auto h-auto opacity-30 min-w-full min-h-full absolute top-0 left-0 object-cover" src="https://images.unsplash.com/photo-1623387641168-d9803ddd3f35?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80" alt="" />
    </div>
  </div>
</template>

<script setup>
// import { ArrowPathIcon, CloudArrowUpIcon, LockClosedIcon } from '@heroicons/vue/20/solid'
import hiw1 from '../assets/svg/hiw1.svg'
import hiw2 from '../assets/svg/hiw2.svg'
import hiw3 from '../assets/svg/hiw3.svg'
import hiw4 from '../assets/svg/hiw4.svg'




const features = [
  {
    name: '1. Registrace',
    description:
      'Objednávka zabere jen pár minut. Náš tým vám ihned potom naplánuje celou kampaň na míru.',
    icon: hiw1,
  },
  {
    name: '2. Spuštění kampaně',
    description:
      'Během pár hodin začneme o ztrátě informovat až desítky tisíc lidí v místě, kde se zvíře pohybuje.',
    icon: hiw2,
  },
  {
    name: '3. Získávání stop',
    description:
      'Neustále přizpůsobujeme kampaň dle informací od lidí, kteří zvíře viděli. Lehce ho tak vystopujeme.',
    icon: hiw3,
  },
  {
    name: '4. Nález mazlíčka',
    description:
      'Průměrně se nám daří 74% domácích mazlíčků v pořádku vrátit domů. Pomůžeme i vám?',
    icon: hiw4,
  },
]
</script>

<script>
export default {
  name: 'HowItWorks',
  props: {
    cats: Boolean,
  },
}
</script>