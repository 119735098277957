import { createStore } from 'vuex'
import { toRaw } from 'vue'

export default createStore({
    state: {
        form: {},
        uid: "",
        adDescription: "",
    },
    mutations: {
        updateForm(state, payloadR) {

            const payload = toRaw(payloadR)


            for(const key in payload) {
                state.form[key] = payload[key]
            }
        },
        updateUid(state, payload) {
            state.uid = payload
        },
        updateAdDescription(state, payload) {
            state.adDescription = payload
        }
    },
    actions: {},
    getters: {}
})