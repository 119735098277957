
<template>
  <MainHeader></MainHeader>

  <BreadCrumb hide :pages="[
  { name: 'Psí detektiv', href: '/psi-detektiv', current: true },
]"/>
  
  <div class="safarifix relative overflow-hidden bg-yellow-500">
    <div class="safarifix absolute inset-0 block" aria-hidden="true">
      <img class="safarifix blur-sm block w-auto h-auto opacity-10 min-w-full min-h-full absolute top-0 left-0 object-cover saturate-200" src="../assets/img/psi-detektiv.jpg" alt="" />
    </div>
    <div class="relative pb-16 sm:pb-24">
      <main class="mt-16 sm:mt-24">
        <div class="mx-auto max-w-7xl">
          <div class="lg:grid lg:grid-cols-12 lg:gap-8">
            <div class="px-6 sm:text-center md:mx-auto md:max-w-2xl lg:col-span-6 lg:flex lg:items-center lg:text-left">
              <div>
                <!-- <span class="bg-white bg-opacity-50 mb-6 block w-fit text-sm px-4 py-2 rounded">Porovnání portálů pátrání ztracených zvířat</span> -->

                <h1 class="text-3xl font-bold tracking-tight text-white sm:text-4xl md:text-5xl lg:md:text-7xl leading-tight md:leading-tight"><span class="text-stone-800">Psí detektiv 🕵🏻‍♂️</span> vs. Najdi Mazlíčka</h1>
                <p class="mt-8 text-base text-white sm:mt-10 sm:text-xl lg:text-lg xl:text-xl  ">Ztratil se vám <router-link class="underline" to="/ztracena-zvirata/psi">pes</router-link> nebo <router-link class="underline" to="/ztracena-zvirata/kocky">kočka</router-link> a snažíte je najít? Oslovíme tisíce lidí v okolí ztráty a pomůžeme vám je v pořádku vrátit domů.<br/><br/>Zaregistrujte ztrátu zdarma. Nebo porovnejte, která platforma je pro vás ta pravá.</p>

                <router-link to="/" class="mt-8 inline-block bg-white px-6 py-3 rounded-lg font-semibold text-stone-900 hover:bg-orange-100 transition duration-200">Začít hledat ztracené zvíře</router-link>
              </div>
            </div>
            <div class="lg:col-span-6">
              <FormSmall submitRoute="/report"></FormSmall>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>




  <div class="lg:flex align-top mx-auto max-w-7xl sm:px-6 lg:px-8 py-24 sm:py-32">
    <div class="bg-white px-6 lg:px-8">
      <div class="mx-auto max-w-4xl text-left">
        <p class="text-base font-semibold leading-7 text-orange-600">Celonárodní pátrací systém po ztracených zvířatech</p>
        <h2 class="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-4xl">Co to je Psí detektiv?</h2>
        <p class="mt-6 text-lg leading-8 text-gray-600">Psí detektiv je spolehlivé řešení pro hledání ztracených psů a koček. Stránka nabízí zdarma zápis do jejich databáze, leták s užitečnými radami a informační SMS s fotkou, která osloví pár stovek lidí v okolí. Zasílají okamžitě i e-mailová upozornění na všechny důležité organizace, veterináře či policii. Navíc disponují i službou detektiva přímo na místě a využívají pátrací dron s termokamerou pro ještě efektivnější pátrání.</p>
      </div>
    </div>

    <div class="bg-white px-6 lg:px-8 mt-16 lg:mt-0">
      <div class="mx-auto max-w-4xl text-left">
        <p class="text-base font-semibold leading-7 text-orange-600">Databáze a pátrací kampaně s fotkami na Facebooku a Instagramu</p>
        <h2 class="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-4xl">Co to je Najdi Mazlíčka?</h2>
        <p class="mt-6 text-lg leading-8 text-gray-600">Najdi Mazlíčka je osvědčený systém pro hledání ztracených psů a koček na základě speciálních kampaní na sociálních sítích. Nabízíme také databázi ztracených zvířat do které se můžete zdarma zaregistrovat. Naše kampaně jsou vždy tvořené profesionálem a na míru. Oslovíme běžně několik tísíc lidí v okolí ztráty a naše úspěšnost je 74%.</p>
      </div>
    </div>
  </div>

  <HowItWorks></HowItWorks>

  <AboutUsSection></AboutUsSection>

  <RecentlySaved></RecentlySaved>
  <MainStats></MainStats>

  <MainTestemonial></MainTestemonial>

  <FaqSection></FaqSection>
  

  <CtaMain></CtaMain>



  <MainFooter></MainFooter>
</template>

<script setup>
  import { useSeoMeta } from '@vueuse/head'
	// import { ChevronRightIcon } from '@heroicons/vue/20/solid'

  useSeoMeta ({
      title: 'Psí detektiv: Jak hledat ztracená zvířata v ČR (2024)',
      description: 'Psí detektiv vs. Najdi Mazlíčka: Ztratil se vám pes nebo kočka a snažíte je najít? Zaregistrujte mazlíčka zdarma. Porovnejte, která platforma je pro vás ta pravá.',
      ogDescription: 'Psí detektiv vs. Najdi Mazlíčka: Ztratil se vám pes nebo kočka a snažíte je najít? Zaregistrujte mazlíčka zdarma. Porovnejte, která platforma je pro vás ta pravá.',
      ogTitle: 'Psí detektiv 🕵🏻‍♂️ Možnosti hledání ztracených zvířat v ČR (2024)',
      ogImage: 'https://najdimazlicka.cz/og.jpg',
      twitterCard: 'summary_large_image',
  });

</script>

<script>

import FormSmall from '../components/FormSmall.vue'
import MainHeader from '../components/MainHeader.vue'
// import MainLanding from '../components/MainLanding.vue'
import MainFooter from '../components/MainFooter.vue'
import CtaMain from '../components/CtaMain.vue'
import AboutUsSection from '../components/AboutUsSection.vue'
import MainTestemonial from '../components/MainTestemonial.vue'
import MainStats from '../components/MainStats.vue'
import HowItWorks from '../components/HowItWorks.vue'
import RecentlySaved from '../components/RecentlySaved.vue'
import FaqSection from '../components/FaqSection.vue'


export default {
  name: 'DetektivView',
  components: {
      MainHeader,
      FormSmall,
      // MainLanding,
      MainFooter,
      CtaMain,
      AboutUsSection,
      MainTestemonial,
      MainStats,
      HowItWorks,
      RecentlySaved,
      FaqSection,
  }
}
</script>


<style>
</style>