<template>
    <MainHeader />
    <div class="flex flex-row justify-center mt-10">
        <div class="max-w-2xl flex flex-col items-center text-center px-3">
            <div class="justify-center w-24">
                <img src="../assets/svg/cross.svg">
            </div>
            <div class="font-bold text-3xl mt-10">Platba selhala</div>
            <p class="mt-5 text-stone-600">Nepodařilo se nám zpracovat vaší platbu. Pokud chcete platit přes účet, ozvěte se nám prosím na +420 605 176 305. Celou platbu můžete zkusit ještě znovu.</p>
            <div class="mt-8">
                <BasicButton text="Zkusit znovu" @click="retryCheckout" />
            </div>
        </div>
    </div>
    <teleport to="body">
        <PopupWindow v-if="error">
            <div class="block">
                <div class="mb-6 font-semibold">Omlouváme se, ale došlo k chybě. Zkuste to prosím znovu nebo nás kontaktujte na +420 605 176 305.</div>
                <div class="flex">
                    <PetButton class="mr-3" text="Zavřít" @click="error = false" />
                    <router-link to="/">
                        <PetButton text="Vrátit se domů" />
                    </router-link>
                </div>
            </div>
        </PopupWindow>
    </teleport>
</template>

<script setup>
import { useSeoMeta } from '@vueuse/head'

useSeoMeta ({
	title: 'Platba selhala | Najdi Mazlíčka',
    description: 'Ztratil se vám pes nebo kočka? Vytvoříme speciální reklamní kampaň. Do pár hodin oslovíme tisíce lidí v lokalitě kde se váš mazlíček ztratil. 5 let zkušeností.',
    ogDescription: 'Ztratil se vám pes nebo kočka? Vytvoříme speciální reklamní kampaň. Do pár hodin oslovíme tisíce lidí v lokalitě kde se váš mazlíček ztratil. 5 let zkušeností.',
    ogTitle: 'Platba selhala | Najdi Mazlíčka',
    ogImage: 'https://najdimazlicka.cz/og.jpg',
    twitterCard: 'summary_large_image',
    robots: "noindex"
});

</script>

<script>
import BasicButton from '../components/BasicButton.vue'
import MainHeader from '../components/MainHeader.vue'
import PopupWindow from '../components/PopupWindow.vue'

import axios from 'axios'

const url = process.env.SERVER_URL

export default {
    name: "FailureView", 
    components: {
        BasicButton,
        MainHeader,
        PopupWindow
    },
    data() {
        return {
            error: false
        }
    },
    methods: {
        async retryCheckout() {
            const order = this.$cookies.get('mazel-order-info')

            //get the checkout link
            const retryUrl = await axios.post(`${url}/api/payment`, order)
                .then(res => {
                    return res.data.data
                }).catch(err => {
                    this.error = true
                    console.log(err)
                })

            window.location.href = retryUrl
        },
        async sendEmail() {
            const uid = this.$cookies.get('mazel-pet-info').uid 
            const userEmail = this.$cookies.get('mazel-pet-info').userEmail
            const address = `${process.env.FRONTEND_URL}/retryPayment/${uid}`

            const payload = {
                address,
                userEmail
            }

            await axios.post(`${url}/api/email-fail`, payload)
                .catch(err => {
                    alert('failed sending email')
                    console.log(err)
                })
        }
    },
    mounted() {
        this.sendEmail()
    }
}
</script>


