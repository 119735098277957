<template>
  <MainHeader></MainHeader>

  <BreadCrumb :pages="[
    { name: 'Databáze ztracených zvířat', href: '/ztracena-zvirata', current: false },
    { name: 'Co dělat, když se ztratí kočka', href: '/co-delat-kdyz-se-ztrati-kocka', current: true },
  ]"/>




  <div class="bg-white px-6 py-16 lg:px-8">
    <div class="mx-auto max-w-3xl text-base leading-7 text-gray-700">
      <p class="text-base font-semibold leading-7 text-orange-600">Několik užitečných tipů při ztrátě kočky</p>
      <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Co dělat, když se ztratí kočka?</h1>
      <p class="mt-6 text-xl leading-8">Zatoulala se vám někde kočka (nebo kocour) a už nepřišla domů? Nebo jste jen třeba nedovřeli vchodové dveře a kočičku doma už nenašli… Neztrácejte hlavu. V tomto článku se vám pokusíme pomoci.</p>
      
      
      <h2 class="mt-16 text-2xl font-bold tracking-tight text-gray-900">Vyčkejte chvíli</h2>
      <p class="mt-6">U zaběhnuté kočky nikdy nevíte. Než jí vyrazit okamžitě hledat, vyplatí se nepanikařit, před dveře připravit misku s jejím oblíbeným jídlem a vyčkat. Toto ve většině případech vašeho mazlíčka naláká rychle zpět. Samozřejmě intervaly, ve kterých se kočka většinou vrací, má každá nastavená jinak. Věřte své vlatní intuici. Pokud je čekání podezřele dlouhé, začněte teprve s hledáním.</p>
  
      <h2 class="mt-16 text-2xl font-bold tracking-tight text-gray-900">Podívejte se po domě</h2>
      <p class="mt-6">Stává se velmi často, že se kočka ztratí ve vlastním prostředí. Můžete jí například zavřít do garáže, do sklepa, může se někam propadnout, někde uvíznout, dostat se na střechu, do různých trubek, podvozků aut a tak dále. Pečlivě tedy prohledejte všechny schovky, kde by se mohla skrývat. Poslouchejte také zvuky, kdyby jste jí náhodou zaslechli.</p>

      <h2 class="mt-16 text-2xl font-bold tracking-tight text-gray-900">Zavolejte sousedům</h2>
      <p class="mt-6">Často se stává, že se kočka zatoulá a někdo z okolí jí nabídne jídlo, nocleh a je možné, že se tam na nějakou dobu zabydlí. Stojí za to se tedy poptat vašich sousedů, zda-li vašeho ztraceného mazlíčka někdo neviděl. Zkusit můžete také řadu kočičích útulků, veterinářů a podobně.</p>


      <h2 class="mt-16 text-2xl font-bold tracking-tight text-gray-900">Zkontrolujte online fóra</h2>
      <p class="mt-6">Zkontrolujte různá internetová fóra jako <router-link class="underline" to="/psi-detektiv">Psí detektiv</router-link> a nebo Facebookové stránky spojené s vaším bydlištěm. Může se stát, že někdo vašeho psa spatřil a oznámil to zrovna na těchto sítích. Zanechte na tam na sebe kontakt (nejlépe telefon) a čekejte…</p>


      <div class="bg-orange-50 mt-8 block lg:py-8 rounded-lg">
        <FormSmall
          submitRoute="/report"
          customHeading="Registrujte kočku ZDARMA"
          customSubheading="Zadejte informace o své ztracené kočce a začněte s pátráním. Hotovo máte za 60 vteřin."
          customNameHeading="Jméno kočky"
        ></FormSmall>
      </div>

      <h2 class="mt-16 text-2xl font-bold tracking-tight text-gray-900">Pusťte se do sofistikovanějšího pátrání</h2>
      <p class="mt-6 mb-8">Letákami sice možná oslovíte část lidí z Vašeho nejbližšího okolí, ale přitom vaše kočka mohla urazit desítky kilometrů. Automatizované E-maily lidé v dnešní době už téměř nečtou a cílené SMS jsou zbytečně drahé a neefektivní. A pokud zrovna nechcete celý den s ostatními pátrači procházet okolní lesy, tak nejlepší formou aktivního pátrání je rozjetí cílené kampaně na Facebooku a Instagramu. Nejjednodušší způsob, jak něco podobného spustit, je přihlásit se na webu najdimazlicka.cz. Nezapomeňte také nastavit nějakou motivující odměnu pro nálezce.</p>

      <router-link to="/"
        class="font-semibold bg-orange-600 text-white px-6 py-3 rounded-lg inline-block hover:bg-orange-700 transition duration-200"
      >
        Dozvědět se více
      </router-link>
      
      
      
      <h2 class="mt-16 text-2xl font-bold tracking-tight text-gray-900">Mezi další tipy patří</h2>
      
            
      
      <div class="mt-10 max-w-2xl">
        <p class="mt-6 mb-8">Samotná ztráta kočky může být pro majitele velmi stresující a emocionálně náročná zkušenost. Pocit ztráty milovaného mazlíčka může být podobný jako ztráta člena rodiny. Je důležité být připraven a vědět, co dělat, když se kočka ztratí, abyste maximalizovali šance na nalezení a opětovné spojení se svým domácím miláčkem. Zde je několik užitečných rad:</p>

        <p class="mt-8"><span class="font-bold text-stone-900">Okamžitá akce:</span> Jakmile si všimnete, že vaše kočka chybí, jednejte okamžitě. Čím dříve začnete s hledáním, tím větší je pravděpodobnost úspěchu.</p>

        <p class="mt-8"><span class="font-bold text-stone-900">Prohledání okolí:</span> Začněte prohledávat okolí vašeho domu nebo bytu. Kočky se často schovávají v blízkosti, takže je možné, že se skrývá v zahradě, garáži nebo pod domem.</p>

        <p class="mt-8"><span class="font-bold text-stone-900">Rozvěšení plakátů:</span> Vytvořte a rozvěste plakáty s fotografií vaší ztracené kočky v okolí vašeho domu a ve vaší čtvrti. Zahrňte informace o kontaktu a popište vaši kočku - barva srsti, velikost, zvláštní znaky apod.</p>

        <p class="mt-8"><span class="font-bold text-stone-900">Kontaktujte místní útulky pro zvířata:</span> Oznámit ztrátu kočky místním útulkům pro zvířata je důležitý krok. Někdy se ztracená zvířata dostanou do útulků nebo jsou k nim přinesena lidmi, kteří je našli.</p>

        <p class="mt-8"><span class="font-bold text-stone-900">Sociální média a online komunity:</span> Sdílejte informace o ztrátě kočky na sociálních sítích a v online komunitách zaměřených na mazlíčky ve vaší lokalitě. Mnoho lidí má tendenci sdílet informace o ztracených zvířatech a pomáhat s jejich nalezením.</p>

        <p class="mt-8"><span class="font-bold text-stone-900">Nepřetržité hledání:</span> Pokud kočka není nalezena během prvního dne, nevzdávejte to. Pokračujte v pravidelném hledání a aktualizujte informace na plakátech a sociálních sítích.</p>

        <p class="mt-8"><span class="font-bold text-stone-900">Oslovte sousedy:</span> Požádejte své sousedy, aby si dali pozor na vaši kočku. Možná ji někdo ve vaší blízkosti viděl nebo ji chytí a zavře v garáži nebo sklepě. Ideálně prostřednictvím <router-link class="underline" to="/kocici-detektiv">kočičího detektiva</router-link>.</p>

        <p class="mt-8"><span class="font-bold text-stone-900">Zkontrolujte místa, kam se vaše kočka obvykle schovává:</span> Pokud má vaše kočka oblíbená místa nebo skryté úkryty, zkuste je prohledat.</p>

        <p class="mt-8"><span class="font-bold text-stone-900">Kontaktujte veterináře:</span> Informujte místní veterinární kliniky o ztrátě vaší kočky. Někdy jsou zvířata přinesena do veterinárních zařízení.</p>

        <p class="mt-8">Ztráta kočky může být velmi stresující situace, ale pomocí těchto rad a aktivit máte šanci maximalizovat možnost jejího nalezení a opětovného spojení. Buďte trpěliví a neztrácejte naději, vaše kočka může být nalezena i po několika dnech.</p>

      </div>
    </div>
  </div>




  <!-- <RecentlySaved></RecentlySaved> -->
  <MainTestemonial cats></MainTestemonial>
  <FaqSection></FaqSection>
  <CtaMain cats></CtaMain>
  <MainFooter></MainFooter>
</template>

<script setup>
  import { useSeoMeta } from '@vueuse/head'
  // import { CheckCircleIcon } from '@heroicons/vue/20/solid'

	// import { ChevronRightIcon } from '@heroicons/vue/20/solid'

  useSeoMeta ({
      title: 'Co dělat, pokud se vám ztratila kočka? 5 důležitých rad a tipů',
      description: 'Ztratila se vám kočka nebo kocour a bohužel ještě nevrátili? Přemýšlíte co podniknout a jak postupovat? Pomocné rady, které vám je pomůžou vrátit domů.',
      ogDescription: 'Ztratila se vám kočka nebo kocour a bohužel ještě nevrátili? Přemýšlíte co podniknout a jak postupovat? Pomocné rady, které vám je pomůžou vrátit domů.',
      ogTitle: 'Co dělat, pokud se vám ztratila kočka? 5 důležitých rad a tipů',
      ogImage: 'https://najdimazlicka.cz/og.jpg',
      twitterCard: 'summary_large_image',
  });

</script>

<script>

import FormSmall from '../components/FormSmall.vue'
import MainHeader from '../components/MainHeader.vue'
import BreadCrumb from '../components/BreadCrumb.vue'
import MainFooter from '../components/MainFooter.vue'
import CtaMain from '../components/CtaMain.vue'
import MainTestemonial from '../components/MainTestemonial.vue'
// import RecentlySaved from '../components/RecentlySaved.vue'
import FaqSection from '../components/FaqSection.vue'


export default {
  name: 'whatToDoDog',
  components: {
      MainHeader,
      FormSmall,
      MainFooter,
      CtaMain,
      FaqSection,
  }
}
</script>

