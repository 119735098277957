<template>
    <BareboneHeader />

    <div class="bg-stone-50 pt-8 pb-16">
        <FormBigNew title="Registrace" @form-submit="formSubmit" :popup="popup" />
        
        <teleport to="body">
            <div v-if="popup" class="w-100 h-100 bg-black/50 fixed inset-0 flex justify-center">
                <div class="max-h-screen overflow-y-scroll">
                    <div class="flex flex-col bg-white text-center transform rounded-lg shadow-xl transition-all my-8 w-full max-w-xl p-6 sm:p-10">
                        <p class="font-bold text-xl">Právě jste přidali vašeho mazlíčka do naší databáze ztracených zvířat ✅</p>
                        <p class="font-medium text mt-6">Dobrá práce! Toto je důležitý první krok při cestě k nalezení vašeho chlupatého kamaráda ❤️</p>
                        
                        <input type="text" class="block text-center mt-7 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6" :value="'https://najdimazlicka.cz/mazlicek/'+ uid" readonly>
                        <PetButton text="Kopírovat" class="w-fit mx-auto mt-3" @click="copyText"/> 

                        <div class="mt-3 text-sm text-gray-500">Zkopírujte si odkaz na váš inzerát a sdílejte ho na sociálních sítích.</div>

                        <div class="relative my-8">
                            <div class="absolute inset-0 flex items-center" aria-hidden="true">
                            <div class="w-full border-t border-gray-300" />
                            </div>
                            <div class="relative flex justify-center">
                            <span class="bg-white px-2 text-sm text-gray-500">Další krok dole</span>
                            </div>
                        </div>

                        <p class="font-bold text-xl">Oslovte až desítky tisíc lidí s naší placenou kampaní na sociálních sítích 🔎 </p>


                        

                        <p class="text mt-6">Vytvoříme pro vás profesionální "reklamní" kampaň s fotkou vašeho mazlíčka. Ta se potom bude zobrazovat přesně v místě ztráty všem lidem, kteří používají Facebook nebo Instagram.</p>

                        <BasicButton text="Chci oslovit až 20x více lidí" class="mt-8" @click="nextStep"/> 


                        <div class="flex items-center space-x-3 max-w-xs mx-auto mt-4">
							<img class="block w-[calc((100%-0.75rem)/2-8px)]" src="../assets/svg/googleMaps.svg" alt="" />
							<img class="block w-[calc((100%-0.75rem)/2+8px)]" src="../assets/svg/seznamMaps.svg" alt="" />
						</div>

                        <a :href="'/mazlicek/' + uid" class="hover:underline mt-5 text-sm text-gray-500">Stačí mi pouze inzerát na stránkách</a>
                    </div>
                </div>
            </div>
        </teleport>


        <teleport v-if="isLoading" to="body">
            <div role="status" class="w-full h-screen bg-black/20 backdrop-blur-sm fixed top-0 left-0">
                <svg aria-hidden="true" class="inline w-24 h-24 mr-2 absolute top-[calc(50%-48px)] left-[calc(50%-48px)] text-white animate-spin fill-orange-400" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                </svg>
                <span class="sr-only">Načítání...</span>
			</div>
        </teleport>

        <teleport to="body">
            <PopupWindow v-if="error">
                <div class="block">
                    <div class="mb-6 font-semibold">Omlouváme se, ale došlo k chybě. Zkuste to prosím znovu nebo nás kontaktujte na +420 605 176 305.</div>
                    <div class="flex">
                        <PetButton class="mr-3" text="Zavřít" @click="error = false" />
                        <router-link to="/">
                            <PetButton text="Vrátit se domů" />
                        </router-link>
                    </div>
                </div>
            </PopupWindow>
        </teleport>
    </div>
</template>

<script setup>
import { useSeoMeta } from '@vueuse/head'


useSeoMeta ({
	title: 'Registrace do databáze ztracených psů a koček ZDARMA',
	description: 'Zadejte informace o svém domácím mazlíčkovi a začněte. Hotovo máte za 60 vteřin. Do pár hodin oslovíme tisíce lidí v lokalitě kde se váš mazlíček ztratil.',
	ogDescription: 'Zadejte informace o svém domácím mazlíčkovi a začněte. Hotovo máte za 60 vteřin. Do pár hodin oslovíme tisíce lidí v lokalitě kde se váš mazlíček ztratil.',
	ogTitle: 'Registrace do databáze ztracených psů a koček ZDARMA',
	ogImage: 'https://najdimazlicka.cz/og.jpg',
	twitterCard: 'summary_large_image',
});

</script>



<script>
import BareboneHeader from "../components/BareboneHeader.vue";
// import FormBig from "../components/FormBig.vue"
import BasicButton from '../components/BasicButton.vue'
import PetButton from '../components/PetButton.vue'
import PopupWindow from '../components/PopupWindow.vue'
import FormBigNew from "../components/FormBigNew.vue";



// import aws from 'aws-sdk'
const axios = require('axios')
const { nanoid } = require('nanoid');
import { toRaw } from '@vue/reactivity'

const url = process.env.SERVER_URL

export default {
name: 'RegistrationView',
    components: {
        BareboneHeader,
        // FormBig,
        BasicButton,
        PetButton,
        PopupWindow,
        FormBigNew
    },
    data() {
        return {
            popup: false,
            error: false,
            uid: '',
            isLoading: false,
        }
    },
    methods: {
        async formSubmit(form, imageFile) {


            this.isLoading = true




            //generate uid
            const uid = nanoid(6)

            form.uid = uid
            form.paid = false
            form.status = "lost"
            this.uid = uid

            //get form data
            form = toRaw(form)

            const imageData = new FormData()
            imageData.append('files', imageFile)
            
            const imgId = await axios.post(`${url}/api/upload`, imageData)
                .then(res => {
                    return res.data[0].id
                }).catch(err => {
                    this.error = true
                    console.log(err)
                })

            form.petImg = imgId

            const payload = {
                data: form
            }

            //post data to the database
            await axios.post(`${url}/api/pets`, payload)
                .then(() => {
                    this.popup = true
                    this.isLoading = false
                })
                .catch(err => {
                    this.error = true
                    console.error(err)
                })


            const formSimplified = form
            formSimplified.description = ''

            this.$cookies.set('mazel-pet-info', formSimplified)

            this.sendEmail(formSimplified)
        },
        async nextStep() {
            this.popup = false
            this.$router.push('/popisek');
        },
        copyText() {
            navigator.clipboard.writeText("https://najdimazlicka.cz/mazlicek/"+ this.uid)
        },
        async sendEmail(payload) {
            await axios.post(`${url}/api/email`, payload)
                .catch(err => {
                    this.error = true
                    console.log(err)
                })

            this.isLoading = false
        }
    }
}
</script>