<template>
  <form @submit.prevent="formSubmit" class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">   
		<TitleHeader :title="title" />

		<div class="mx-auto max-w-7xl space-y-10 divide-y divide-gray-900/10">
			<div class="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
				<div class="px-2 sm:px-0">
					<h2 class="text-xl mb-3 font-semibold leading-7 text-gray-900">Informace o vašem mazlíčkovi</h2>
					<p class="mt-1 text-sm leading-6 text-gray-600">Tyto informace budeme veřejně zobrazovat v naší databázi</p>

					<div class="mt-8 bg-white border rounded-xl p-4 max-w-sm">
						<div class="flex flex-col mb-5">
							<!-- <p class="bg-blue-100 text-blue-800 text-sm font-semibold inline-flex items-center p-1.5 rounded">8.7</p> -->
							<div class="flex items-center mb-2">
								<p class="font-medium text-gray-900">Jsme ověřená společnost</p>
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#84cc16" class="ml-2 w-6 h-6">
									<path fill-rule="evenodd" d="M8.603 3.799A4.49 4.49 0 0112 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 013.498 1.307 4.491 4.491 0 011.307 3.497A4.49 4.49 0 0121.75 12a4.49 4.49 0 01-1.549 3.397 4.491 4.491 0 01-1.307 3.497 4.491 4.491 0 01-3.497 1.307A4.49 4.49 0 0112 21.75a4.49 4.49 0 01-3.397-1.549 4.49 4.49 0 01-3.498-1.306 4.491 4.491 0 01-1.307-3.498A4.49 4.49 0 012.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 011.307-3.497 4.49 4.49 0 013.497-1.307zm7.007 6.387a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clip-rule="evenodd" />
								</svg>
							</div>

							<p class="text-sm font-medium text-gray-500">500+ spokojených zákazníků</p>
						</div>

						<div class="flex items-center space-x-3">
							<img class="block w-[calc((100%-0.75rem)/2-8px)]" src="../assets/svg/googleMaps.svg" alt="" />
							<img class="block w-[calc((100%-0.75rem)/2+8px)]" src="../assets/svg/seznamMaps.svg" alt="" />
						</div>
					</div>
				</div>

				<div class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
					<div class="px-4 py-6 sm:p-8">
						<div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
							
							<div class="sm:col-span-3">
								<label for="petName" class="block text-sm font-medium leading-6 text-gray-900">Jméno mazlíčka</label>
								<div class="mt-2">
									<input v-model="form.petName" type="text" name="petName" id="petName" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6" />
								</div>
								<div v-if="v$.form.petName.$errors.length != 0" class="text-red-600 text-sm mt-3">Vyplňte prosím pole</div>
							</div>

							<div class="sm:col-span-3">
								<label for="petTime" class="block text-sm font-medium leading-6 text-gray-900">Kdy se ztratil?</label>
								<div class="mt-2">
                  <input v-model="form.petTime" type="text" name="petTime" id="petTime" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6" />
								</div>
								<div v-if="v$.form.petTime.$errors.length != 0" class="text-red-600 text-sm mt-3">Vyplňte prosím pole</div>
							</div>

							<div class="sm:col-span-3">
								<label for="petPlace" class="block text-sm font-medium leading-6 text-gray-900">Místo ztráty (bude se veřejně zobrazovat)</label>
								<div class="mt-2">
                  <input v-model="form.petPlace" type="text" name="petPlace" id="petPlace" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6" />
								</div>
								<div v-if="v$.form.petPlace.$errors.length != 0" class="text-red-600 text-sm mt-3">Vyplňte prosím pole</div>
							</div>


							<div class="sm:col-span-3">
								<label for="petRegion" class="block text-sm font-medium leading-6 text-gray-900">Kraj, kde se ztratil</label>
								<div class="mt-2">
									<select v-model="form.petRegion" id="petRegion" name="petRegion" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:max-w-xs sm:text-sm sm:leading-6">
										<option value="">Vybrat</option>
										<option value="Hlavní město Praha">Hlavní město Praha</option>
										<option value="Středočeský kraj">Středočeský kraj</option>
										<option value="Jihočeský kraj">Jihočeský kraj</option>
										<option value="Plzeňský kraj">Plzeňský kraj</option>
										<option value="Karlovarský kraj">Karlovarský kraj</option>
										<option value="Ústecký kraj">Ústecký kraj</option>
										<option value="Liberecký kraj">Liberecký kraj</option>
										<option value="Královéhradecký kraj">Královéhradecký kraj</option>
										<option value="Pardubický kraj">Pardubický kraj</option>
										<option value="Kraj Vysočina">Kraj Vysočina</option>
										<option value="Jihomoravský kraj">Jihomoravský kraj</option>
										<option value="Olomoucký kraj">Olomoucký kraj</option>
										<option value="Moravskoslezský kraj">Moravskoslezský kraj</option>
										<option value="Zlínský kraj">Zlínský kraj</option>
									</select>
								</div>
								<div v-if="v$.form.petRegion.$errors.length != 0" class="text-red-600 text-sm mt-3">Vyplňte prosím pole</div>
							</div>

							<div class="col-span-full">
								<label for="petImg" class="block text-sm font-medium leading-6 text-gray-900">Fotografie mazlíčka</label>
								<div v-if="!imgCropped" @click="cropImage" class="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
									<div class="text-center">
										<PhotoIcon class="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
										<div class="mt-4 flex text-sm leading-6 text-gray-600">
											<label for="petImg" class="relative cursor-pointer rounded-md bg-white font-semibold text-orange-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-orange-600 focus-within:ring-offset-2 hover:text-orange-500">
												<span>Přidejte vaší fotku</span>


												<!-- <input id="petImg" name="petImg" type="file" class="sr-only" /> -->
											</label>
										</div>
										<p class="text-xs leading-5 text-gray-600">PNG, JPG do to 10MB</p>
									</div>
								</div>

								<div v-if="imgCropped" class="mt-2 flex flex-col justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
									<p class="mb-6 text-sm font-semibold">Vybraná fotografie</p>
									<img class="rounded-lg w-64" :src="src" />

									<button type="button" @click="()=> {
										src=''
										blob={}
										cropper=false
										imgCropped=false
									}" class="rounded-md mt-6 bg-red-50 px-3 py-2 text-sm font-semibold text-red-600 hover:bg-red-100">Odebrat fotografii</button>
								</div>

								<div v-if="v$.src.$errors.length != 0" class="text-red-600 text-sm mt-3">Vyplňte prosím pole</div>

							</div>

							


							<div class="sm:col-span-3">
								<div class="flex justify-between">
									<label for="petReward" class="block text-sm font-medium leading-6 text-gray-900">Odměna pro nálezce</label>
									<span class="text-sm leading-6 text-gray-500" id="email-optional">Nepovinné</span>
								</div>
								<div class="mt-2">
									<input v-model="form.petReward" type="text" name="petReward" id="petReward" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6" />
								</div>
							</div>

							<div class="sm:col-span-3">
								<div class="flex justify-between">
									<label for="petSex" class="block text-sm font-medium leading-6 text-gray-900">Pohlaví</label>
									<span class="text-sm leading-6 text-gray-500" id="email-optional">Nepovinné</span>
								</div>
								<div class="mt-2">
									<select v-model="form.petSex" id="petSex" name="petSex" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:max-w-xs sm:text-sm sm:leading-6">
										<option value="">Vybrat</option>
										<option value="Samec">Samec</option>
										<option value="Samice">Samice</option>
									</select>
								</div>
							</div>

							<div class="sm:col-span-3">
								<div class="flex justify-between">
									<label for="petType" class="block text-sm font-medium leading-6 text-gray-900">Typ mazlíčka</label>
									<span class="text-sm leading-6 text-gray-500" id="email-optional">Nepovinné</span>
								</div>
								<div class="mt-2">
									<select v-model="form.petType" id="petType" name="petType" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:max-w-xs sm:text-sm sm:leading-6">
										<option value="">Vybrat</option>
										<option value="Pes">Pes</option>
										<option value="Kočka">Kočka</option>
										<option value="Pták">Pták</option>
										<option value="Ostatní">Ostatní</option>
									</select>
								</div>
							</div>

							<div class="sm:col-span-3">
								<div class="flex justify-between">
									<label for="petAge" class="block text-sm font-medium leading-6 text-gray-900">Věk</label>
									<span class="text-sm leading-6 text-gray-500" id="email-optional">Nepovinné</span>
								</div>
								<div class="mt-2">
									<input v-model="form.petAge" type="text" name="petAge" id="petAge" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6" />
								</div>
							</div>

							<div class="sm:col-span-3">
								<div class="flex justify-between">
									<label for="petColor" class="block text-sm font-medium leading-6 text-gray-900">Barva</label>
									<span class="text-sm leading-6 text-gray-500" id="email-optional">Nepovinné</span>
								</div>
								<div class="mt-2">
										<input type="text" v-model="form.petColor" name="petColor" id="petColor" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6" />
								</div>
							</div>

							<div class="sm:col-span-3">
								<div class="flex justify-between">
									<label for="petSize" class="block text-sm font-medium leading-6 text-gray-900">Velikost</label>
									<span class="text-sm leading-6 text-gray-500" id="email-optional">Nepovinné</span>
								</div>
								<div class="mt-2">
									<select id="petSize" name="petSize" v-model="form.petSize" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:max-w-xs sm:text-sm sm:leading-6">
										<option value="">Vybrat</option>
										<option value="Malá do 9kg">Malá do 9kg</option>
										<option value="Střední 10-18kg">Střední 10-18kg</option>
										<option value="Velká nad 18kg">Velká nad 18kg</option>
									</select>
								</div>
							</div>
							
							<div class="sm:col-span-3">
								<div class="flex justify-between">
									<label for="petBreed" class="block text-sm font-medium leading-6 text-gray-900">Plemeno</label>
									<span class="text-sm leading-6 text-gray-500" id="email-optional">Nepovinné</span>
								</div>
								<div class="mt-2">
										<input type="text" name="petBreed" v-model="form.petBreed" id="petBreed" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6" />
								</div>
							</div>
							
						

							<div class="col-span-full">
								<div class="flex justify-between">
									<label for="petDescription" class="block text-sm font-medium leading-6 text-gray-900">Okolnosti ztráty</label>
									<span class="text-sm leading-6 text-gray-500" id="email-optional">Nepovinné</span>
								</div>
								<div class="mt-2">
									<textarea id="petDescription" v-model="form.petDescription" name="petDescription" rows="3" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6" />
								</div>
								<p class="mt-3 text-sm leading-6 text-gray-600">Popište v krátkosti jakékoliv detaily toho, jak došlo ke ztrátě</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3">
				<div class="px-4 sm:px-0">
					<h2 class="text-xl mb-3 font-semibold leading-7 text-gray-900">Osobní informace</h2>
					<p class="mt-1 text-sm leading-6 text-gray-600">Potřebujeme je znát, abychom s vámi mohli komunikovat</p>
				</div>
				<div class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
					<div class="px-4 py-6 sm:p-8">
						<div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

							<div class="sm:col-span-3">
								<label for="userName" class="block text-sm font-medium leading-6 text-gray-900">Vaše celé jméno</label>
								<div class="mt-2">
									<input type="text" v-model="form.userName" name="userName" id="userName" autocomplete="given-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6" />
								</div>

								<div v-if="v$.form.userName.$errors.length != 0" class="text-red-600 text-sm mt-3">Vyplňte prosím pole</div>

							</div>

							<div class="sm:col-span-3">
								<label for="userEmail" class="block text-sm font-medium leading-6 text-gray-900">Emailová adresa</label>
								<div class="mt-2">
									<input id="userEmail" v-model="form.userEmail" name="userEmail" type="userEmail" autocomplete="email" class="px-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6" />
								</div>

								<div v-if="v$.form.userEmail.$errors.length != 0" class="text-red-600 text-sm mt-3">Vyplňte prosím pole</div>

							</div>

							<div class="sm:col-span-3">
								<label for="userPhone" class="block text-sm font-medium leading-6 text-gray-900">Telefonní číslo (bude zobrazeno veřejně)</label>
								<div class="mt-2">
									<input type="text" v-model="form.userPhone" name="userPhone" id="userPhone" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6" />
								</div>

								<div v-if="v$.form.userPhone.$errors.length != 0" class="text-red-600 text-sm mt-3">Vyplňte prosím pole</div>

							</div>

							<div class="sm:col-span-3">
								<div class="flex justify-between">
									<label for="userSource" class="block text-sm font-medium leading-6 text-gray-900">Kde jste našli naší stránku?</label>
									<span class="text-sm leading-6 text-gray-500" id="email-optional">Nepovinné</span>
								</div>
								<div class="mt-2">
									<input type="text" name="userSource" v-model="form.userSource" id="userSource" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6" />
								</div>

							</div>

							<div class="sm:col-span-3">
								<label for="password" class="block text-sm font-medium leading-6 text-gray-900">Vytvořte si heslo</label>
                <div class="mt-2">
                  <input type="password" name="password" v-model="form.password" id="password" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6" />
                </div>

								<div v-if="v$.form.password.$errors.length != 0" class="text-red-600 text-sm mt-3">Vyplňte prosím pole</div>

							</div>

							<div class="sm:col-span-3">
								<label for="userStreet" class="block text-sm font-medium leading-6 text-gray-900">Ulice a číslo popisné</label>
                <div class="mt-2">
                  <input type="text" name="userStreet" v-model="form.userStreet" id="userStreet" autocomplete="street-address" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6" />
                </div>

								<div v-if="v$.form.userStreet.$errors.length != 0" class="text-red-600 text-sm mt-3">Vyplňte prosím pole</div>

							</div>

							<div class="sm:col-span-3 sm:col-start-1">
								<label for="userCity" class="block text-sm font-medium leading-6 text-gray-900">Město</label>
								<div class="mt-2">
									<input type="text" name="userCity" v-model="form.userCity" id="userCity" autocomplete="address-level2" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6" />
								</div>

								<div v-if="v$.form.userCity.$errors.length != 0" class="text-red-600 text-sm mt-3">Vyplňte prosím pole</div>

							</div>

							<div class="sm:col-span-3">
								<label for="userPostalCode" class="block text-sm font-medium leading-6 text-gray-900">PSČ</label>
								<div class="mt-2">
									<input type="text" name="userPostalCode" v-model="form.userPostalCode" id="userPostalCode" autocomplete="postal-code" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6" />
								</div>

								<div v-if="v$.form.userPostalCode.$errors.length != 0" class="text-red-600 text-sm mt-3">Vyplňte prosím pole</div>

							</div>
						</div>
					</div>
				</div>
			</div>

			<BasicButton class="mt-7 block w-48 ml-auto" name="submit" text="Začít pátrat" type="submit" />

			<p class="text-sm pt-8 ">Pokračováním souhlasíte s našimi <a href="/podminky-ochrany-osobnich-udaju" target="_blank" class="font-medium text-stone-900 underline">podmínkami ochrany osobních údajů</a>. </p>

		</div>


	</form>
	<teleport to="body">
		<TransitionRoot as="template" :show="cropper">
			<Dialog as="div" class="relative z-10" @close="cropper = false">
				<div class="fixed inset-0 bg-stone-900/40 transition ease-in-out duration-500 sm:duration-700" />

				<div class="fixed inset-0 overflow-hidden">
					<div class="absolute inset-0 overflow-hidden">
						<div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
							<TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
								<DialogPanel class="pointer-events-auto w-screen max-w-2xl">
									<div class="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
										<div class="px-4 sm:px-6">
											<div class="flex items-start justify-between">
												<DialogTitle class="text-base font-semibold leading-6 text-gray-900">Vybrat fotografii</DialogTitle>
												<div class="ml-3 flex h-7 items-center">
													<button type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2" @click="cropper = false">
														<span class="sr-only">Close panel</span>
														<XMarkIcon class="h-6 w-6" aria-hidden="true" />
													</button>
												</div>
											</div>
										</div>
										<div class="relative mt-6 flex-1 px-4 sm:px-6">
											<!-- Your content -->
											<PetCropper @image-cropped="getCroppedImg" :imageSrc="src"/>

										</div>
									</div>
								</DialogPanel>
							</TransitionChild>
						</div>
					</div>
				</div>
			</Dialog>
		</TransitionRoot>

	</teleport>
</template>


<script>
import { PhotoIcon } from '@heroicons/vue/24/solid'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XMarkIcon } from '@heroicons/vue/24/outline'
import BasicButton from './BasicButton.vue'
import useVuelidate from '@vuelidate/core'
import { email, required } from '@vuelidate/validators'
import TitleHeader from './TitleHeader.vue'
import PetCropper from './PetCropper.vue'


export default {
    name: "FormBigNew",
    setup() {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            form: {},
            src: "",
            blob: {},
            cropper: false,
            imgCropped: false
        }
    },
    validations() {
        return {
            form: {
              petName: { required },
              petTime: { required },
              petPlace: { required },
              petRegion: { required },
              userEmail: { required, email },
              password: { required },
              userName: { required },
              userPhone: { required },
              userCity: { required },
              userPostalCode: { required },
              userStreet: { required },
            },
            src: { required },
            blob: { required },
            cropper: {},
            imgCropped: {}
        }
    },
    props: {
      title: String,
      submitRoute: String,
    },
    methods: {
        formSubmit() {

          this.v$.$validate()

          if(!this.v$.$invalid) {
            this.$emit('formSubmit', this.form, this.blob)
          }
        },
        getForm() {
            return this.$store.state.form
        },
        cropImage() {
            this.cropper = true
        },
        async getCroppedImg(img) {
            this.cropper = false
            this.imgCropped = true

            this.src = img

            const imgBlob = await fetch(img).then(res => {return res.blob()})
            this.blob = imgBlob
        }
    },
    components: { 
        BasicButton,
        Dialog,
        DialogPanel,
        DialogTitle,
        TransitionChild,
        TransitionRoot,
        PhotoIcon,
        XMarkIcon,
        TitleHeader,
        PetCropper
    },
    created() {
        this.form = this.getForm()
    },
}
</script>