<template>
    <MainHeader />
    <div class="flex flex-row justify-center mt-8">
        <div class="w-[1290px] flex flex-col items-center text-center">
            <div class="font-bold text-4xl mt-10">Vyčkejte prosím...</div>
        </div>
    </div>
    <teleport to="body">
        <PopupWindow v-if="error">
            <div class="block">
                <div class="mb-6 font-semibold">Omlouváme se, ale došlo k chybě. Zkuste to prosím znovu nebo nás kontaktujte na +420 605 176 305.</div>
                <div class="flex">
                    <PetButton class="mr-3" text="Zavřít" @click="error = false" />
                    <router-link to="/">
                        <PetButton text="Vrátit se domů" />
                    </router-link>
                </div>
            </div>
        </PopupWindow>
    </teleport>
</template>

<script setup>
import { useSeoMeta } from '@vueuse/head'


useSeoMeta ({
	title: 'Vyčkejte prosím...',
	description: 'Vyčkejte prosím...',
	ogDescription: 'Vyčkejte prosím...',
	ogTitle: 'Vyčkejte prosím...',
	ogImage: 'https://najdimazlicka.cz/og.jpg',
	twitterCard: 'summary_large_image',
    robots: "noindex"
});

</script>


<script>
//import BasicButton from '../components/BasicButton.vue'
import MainHeader from '../components/MainHeader.vue'
import PopupWindow from '../components/PopupWindow.vue'
import PetButton from '../components/PetButton.vue'

import axios from 'axios'

const serverAddr = 'http://127.0.0.1:1337'
const url = `${serverAddr}/api/pets`
const filter = "?filters[uid][$eq]="

export default {
    name: "FailureView", 
    components: {
        MainHeader,
        PopupWindow,
        PetButton
    },
    data() {
        return {
            error: false
        }
    },
    props: {
        uid: String
    },
    async mounted() {
        // get pet id
        await this.getPet()
            .then(() => {
                // check if the pet has been paid for already
                if(this.pet.paid) {
                    alert('your pet has already been paid for')
                    this.$router.push(`/mazlicek/${this.uid}`)
                } else {
                    // store pet info into cookies
                    const form = this.pet
                    form.description = ''
                    this.$cookies.set('mazel-pet-info', {
                        form
                    })
                    this.$router.push('/objednavka')
                }
            })
    },
    methods: {
        async getPet() {
            await axios.get(url + filter + this.uid)
                .then(res => {
                    if (res.data.data.length == 0) {
                        this.petExists = false
                    }
                    const rawPet = res.data.data[0].attributes
                    this.pet = rawPet
                    this.petId = res.data.data[0].id
                })
                .catch(err => {
                    this.error = true
                    console.log(err)
                })
        },
    }
}
</script>