<template>
    <MainHeader />
    <main class="relative isolate min-h-[calc(100vh-200px)]">
        <img src="https://images.unsplash.com/photo-1453227588063-bb302b62f50b" alt="" class="absolute inset-0 -z-10 h-full w-full object-cover object-top brightness-50" />
        <div class="mx-auto max-w-7xl px-6 py-32 text-center sm:py-40 lg:px-8">
        <p class="text-base font-semibold leading-8 text-white">404</p>
        <h1 class="mt-4 text-3xl font-bold tracking-tight text-white sm:text-5xl">Omlouváme se, ale tato stránka již neexistuje</h1>
        <p class="mt-4 text-base text-white/70 sm:mt-6">Stránku, kterou hledáte, jsme pravděpodobně smazali, přejmenovali, nebo momentálně není k dispozici.</p>
        <div class="mt-10 flex justify-center">
            <a href="/" class="text-sm font-semibold leading-7 text-white"><span aria-hidden="true">&larr;</span> Zpět domů</a>
        </div>
        </div>
    </main>

    <MainFooter />
</template>

<script setup>

import { useSeoMeta } from '@vueuse/head'


useSeoMeta ({
	title: 'Stránka nenalezena | Najdi Mazlíčka',
	description: 'Stránku, kterou hledáte, jsme pravděpodobně smazali, přejmenovali, nebo momentálně není k dispozici.',
	ogDescription: 'Stránku, kterou hledáte, jsme pravděpodobně smazali, přejmenovali, nebo momentálně není k dispozici.',
	ogTitle: 'Stránka nenalezena | Najdi Mazlíčka',
	ogImage: 'https://najdimazlicka.cz/og.jpg',
	twitterCard: 'summary_large_image',
    robots: "noindex"
});


</script>

<script>
    import MainHeader from '../components/MainHeader.vue'
    import MainFooter from '../components/MainFooter.vue'

    export default {
        name: 'PageNotFound',
        components: {
            MainHeader,
            MainFooter
        }
    }
</script>
