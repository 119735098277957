<template>
  <div class="bg-white py-24 sm:py-32">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <dl class="grid grid-cols-1 gap-x-8 gap-y-16 text-center lg:grid-cols-3">
        <div v-for="stat in stats" :key="stat.id" class="mx-auto flex max-w-xs flex-col gap-y-4">
          <dt class="text-base leading-7 text-gray-600">{{ stat.name }}</dt>
          <dd class="text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl">{{ stat.value }}</dd>
        </div>
      </dl>
    </div>
  </div>
</template>



<script>
export default {
  name: 'MainStats',
  props: {
    cats: Boolean
  },
  data() {
    return {
      stats: [
        { id: 1, name: 'Průměrně o ztrátě informujeme cca', value: '16 000 lidí' },
        { id: 2, name: 'Místo ztráty zaměříme s přesností na', value: '1 kilometr' },
        { id: 3, name: 'a díky tomu se také zachrání', value: this.cats ? '74% koček' : '74% zvířat'},
      ]
    }
  }
}
</script>