<template>
  <div class="bg-stone-100">
    <div class="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
      <div class="md:flex md:items-center md:justify-between">


        <h2 v-if="cats === true" class="text-2xl font-bold tracking-tight text-stone-900">Poslední nalezené kočky ❤️</h2>
        <h2 v-else class="text-2xl font-bold tracking-tight text-stone-900">Poslední nalezení mazlíčci ❤️</h2>



        <router-link v-if="cats === true" to="/ztracena-zvirata/kocky" class="hidden text-sm font-medium text-orange-600 hover:text-orange-500 md:block">
          Zobrazit všechny hledané kočky
          <span aria-hidden="true"> &rarr;</span>
        </router-link>


        <router-link v-else to="/ztracena-zvirata" class="hidden text-sm font-medium text-orange-600 hover:text-orange-500 md:block">
          Zobrazit všechny hledané
          <span aria-hidden="true"> &rarr;</span>
        </router-link>
      </div>


        <div v-if="cats === true" class="mt-10 grid grid-cols-2 xs:max-w-none xs:grid-cols-2 gap-x-4 gap-y-10 sm:gap-x-6 lg:grid-cols-4 lg:gap-y-0 lg:gap-x-8">
          <div v-for="pet in catList" :key="pet.id">
            <PetBubble :pet="pet"></PetBubble>
          </div>
        </div>

        <div v-else class="mt-10 grid grid-cols-2 xs:max-w-none xs:grid-cols-2 gap-x-4 gap-y-10 sm:gap-x-6 lg:grid-cols-4 lg:gap-y-0 lg:gap-x-8">
          <div v-for="pet in pets" :key="pet.id">
            <PetBubble :pet="pet"></PetBubble>
          </div>
        </div>
        

        

      <div class="mt-8 text-sm md:hidden">
        <router-link to="/ztracena-zvirata" class="font-medium text-orange-600 hover:text-orange-500" v-if="cats">
          Zobrazit všechny hledané kočky
          <span aria-hidden="true"> &rarr;</span>
        </router-link>

        <router-link to="/ztracena-zvirata" class="font-medium text-orange-600 hover:text-orange-500" v-else>
          Zobrazit všechny hledané
          <span aria-hidden="true"> &rarr;</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script setup>

import sushiPic from '../assets/img/customerSushi.jpeg';
import neliPic from '../assets/img/customerNeli.jpeg';
import malicekPic from '../assets/img/customerMalicek.jpeg';
import bertPic from '../assets/img/customerBert.jpeg';
import ezraImg from '../assets/img/ezraImg.png';
import tonicekImg from '../assets/img/tonicekImg.png';



const pets = [
  {
    petAge: "4",
    petColor: "Tmavá",
    petImg: {data: {attributes: {url: neliPic}}},
    petName: "Neli",
    petPlace: "Humpolec",
    petRegion: "Kraj Vysočina",
    petSex: "Samice",
    petSize: "Malá do 9kg",
    status: "found",
  },
  {
    petAge: "2",
    petBreed: "Kočka domácí",
    petColor: "Šedá",
    petImg: {data: {attributes: {url: sushiPic}}},
    petName: "Sushi",
    petPlace: "Praha",
    petRegion: "Hlavní město Praha",
    petSex: "Samice",
    status: "found",
  },
  {
    petAge: "5",
    petBreed: "Border kolie",
    petColor: "Černobílá",
    petImg: {data: {attributes: {url: bertPic}}},
    petName: "Bert",
    petPlace: "Janov, Bruntál",
    petRegion: "Moravskoslezský kraj",
    petSex: "Samec",
    status: "found",
  },
  {
    petAge: "2",
    petBreed: "Kočka",
    petColor: "Bílá a šedá",
    petImg: {data: {attributes: {url: malicekPic}}},
    petName: "Malíček",
    petPlace: "Zlín",
    petRegion: "Zlínský kraj",
    petSex: "Samec",
    status: "found",
  },
]

  const catList = [

  

  {
    petAge: "9 měsíců",
    petBreed: "Kočka evropská",
    petColor: "Světle šedá",
    petImg: {data: {attributes: {url: tonicekImg}}},
    petName: "Toníček",
    petPlace: "Stropešín 42",
    petRegion: "Kraj Vysočina",
    petSex: "Samec",
    status: "found",
  },

  {
    petAge: "7 měsíců",
    petColor: "Světle šedá",
    petImg: {data: {attributes: {url: ezraImg}}},
    petName: "Ezra",
    petPlace: "Vědomice",
    petRegion: "Ústecký kraj",
    petSex: "Samice",
    status: "found",
  },

  {
    petAge: "2",
    petBreed: "Kočka domácí",
    petColor: "Šedá",
    petImg: {data: {attributes: {url: sushiPic}}},
    petName: "Sushi",
    petPlace: "Praha",
    petRegion: "Hlavní město Praha",
    petSex: "Samice",
    status: "found",
  },

  {
    petAge: "2",
    petBreed: "Kočka",
    petColor: "Bílá a šedá",
    petImg: {data: {attributes: {url: malicekPic}}},
    petName: "Malíček",
    petPlace: "Zlín",
    petRegion: "Zlínský kraj",
    petSex: "Samec",
    status: "found",
  },



  // More products...
]
</script>

<script>
import PetBubble from './PetBubble.vue';


export default {
  name: 'RecentlySaved',
  components: {
    PetBubble
  },
  props: {
    cats: Boolean
  }
}

</script>