<template>
    <MainHeader></MainHeader>

    <BreadCrumb :pages="[
		{ name: 'Ztracená zvířata', href: '/ztracena-zvirata', current: true },
	]"/>




    

    
    <div class="relative isolate overflow-hidden px-6 py-12 sm:py-24 lg:px-8 bg-stone-900">
        <img src="https://images.unsplash.com/photo-1581888227599-779811939961?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2148&q=80" alt="" class="absolute opacity-40 inset-0 -z-10 h-full w-full object-cover blur-sm" />
        <div class="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl" aria-hidden="true">
            <div class="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#FF405B] to-[#FF9A45] opacity-20" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)" />
        </div>
        <div class="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu" aria-hidden="true">
            <div class="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#FF405B] to-[#FF9A45] opacity-20" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)" />
        </div>

        <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div class="mx-auto max-w-4xl lg:mx-0">
                <h2 class="text-3xl font-bold tracking-tight text-white sm:text-6xl">Databáze ztracených zvířat</h2>
                <p class="mt-6 sm:text-lg sm:leading-8 text-gray-300">Podívejte se do naší rozsáhlé databáze ztracených zvířat v České republice. Pokud řešíte svého ztraceného <a class="underline white text-white font-medium" href="/ztracena-zvirata/psi">psa</a>, <a class="underline white text-white font-medium" href="/ztracena-zvirata/kocky">kočku</a>, <a class="underline white text-white font-medium" href="/ztracena-zvirata/ptaci">ptáka</a> nebo jiného domácího mazlíčka, zaregistrujte je ZDARMA do naší evidence.</p>
                <a href="/registrovat" class="rounded-md block w-fit bg-white mt-8 px-4 py-3 font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white">Registovat ztrátu ZDARMA</a>
            </div>
        </div>
    </div>

    <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div v-if="typeof pets !== 'undefined'">
            <div class="mx-auto w-fit my-16" v-if="pets.length === 0" >
                <div role="status">
                    <svg aria-hidden="true" class="inline w-24 h-24 mr-2 text-gray-200 animate-spin fill-yellow-400" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                    </svg>
                    <span class="sr-only">Načítání...</span>
                </div>
            </div>
        </div>

        <div v-if="typeof pets !== 'undefined'" class="grid mt-16 xs:grid-cols-2 gap-x-4 gap-y-8 md:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
            <div v-for="pet in pets" :key="pet.uid" class="" @click="sendToPet(pet.attributes.uid)">
                <PetBubble :pet="pet.attributes" />
            </div>

        </div>

        <div v-if="typeof pets !== 'undefined'">
            <PetPaginator :page="page" :pageCount="pageCount" @paginate="getPets" />
        </div>
    </div>
    


    <CtaMain class="mt-16"></CtaMain>

    <MainFooter></MainFooter>
</template>


<script setup>
import { useSeoMeta } from '@vueuse/head'


useSeoMeta ({
	title: 'Ztracená zvířata - Databáze psů a koček | Registrujte ZDARMA',
	description: 'Zadejte informace o svém domácím mazlíčkovi a začněte s pátráním. Hotovo máte za 60 vteřin. Pomohli jsme najít stovky ztracených psů a koček po celé ČR.',
	ogDescription: 'Zadejte informace o svém domácím mazlíčkovi a začněte s pátráním. Hotovo máte za 60 vteřin. Pomohli jsme najít stovky ztracených psů a koček po celé ČR.',
	ogTitle: 'Ztracená zvířata - Databáze psů a koček | Registrujte ZDARMA',
	ogImage: 'https://najdimazlicka.cz/og.jpg',
	twitterCard: 'summary_large_image',
});

</script>


<script>
import axios from 'axios'
import MainHeader from '../components/MainHeader.vue'
import PetPaginator from '../components/PetPaginator.vue'
import PetBubble from '@/components/PetBubble.vue'
import MainFooter from '@/components/MainFooter.vue'
import CtaMain from '@/components/CtaMain.vue'
import BreadCrumb from '@/components/BreadCrumb.vue'

//import { toRaw } from '@vue/reactivity'

const server_url = process.env.SERVER_URL
const url = `${server_url}/api`
const pageParams = "?pagination[pageSize]=32&pagination[page]="
const populateFilter = "&populate=*&sort=id:desc"

export default {
    name: 'LostView',
    components: {
        MainHeader,
        PetPaginator,
        PetBubble,
        CtaMain,
        MainFooter,
        BreadCrumb
    },
    data() {
        return {
            pets: [],
            page: 1,
            pageCount: 0
        }
    },
    computed: {
        console: () => console,
        window: () => window,
    },
    methods: {
        async getPets(pageNum) {
            this.pets = []

            const res = await axios.get(url + '/pets' + pageParams + pageNum + populateFilter)
            const data = res.data.data
            const pageCount = res.data.meta.pagination.pageCount

            for(const i in data) {
                if(data[i].attributes.petImg.data === null) {
                    data[i].attributes.petImg.data = {}
                    data[i].attributes.petImg.data.attributes = {}
                    data[i].attributes.petImg.data.attributes.url = ""
                }
                this.pets.push(data[i])
            }

            this.page = pageNum
            this.pageCount = parseInt(pageCount)

            return data
        },
        sendToPet(uid) {
            this.$router.push(`/mazlicek/${uid}`)
        },
    },
    async created() {
        await this.getPets(this.page)
    },
}
</script>
