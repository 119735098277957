<template>
	<MainHeader></MainHeader>


	<div class="bg-stone-100 pb-24">
		<main class="isolate">
			<!-- Hero section -->
			<div class="relative isolate -z-10">
				<svg class="absolute inset-x-0 top-0 -z-10 h-[64rem] w-full stroke-stone-200 [mask-image:radial-gradient(32rem_32rem_at_center,white,transparent)]" aria-hidden="true">
					<defs>
						<pattern id="1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84" width="200" height="200" x="50%" y="-1" patternUnits="userSpaceOnUse">
							<path d="M.5 200V.5H200" fill="none" />
						</pattern>
					</defs>
					<svg x="50%" y="-1" class="overflow-visible fill-stone-50">
						<path d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z" stroke-width="0" />
					</svg>
					<rect width="100%" height="100%" stroke-width="0" fill="url(#1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84)" />
				</svg>
				<div class="absolute left-1/2 right-0 top-0 -z-10 -ml-24 transform-gpu overflow-hidden blur-3xl lg:ml-24 xl:ml-48" aria-hidden="true">
					<div class="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#FF9A45] to-[#FF405B] opacity-30" style="clip-path: polygon(63.1% 29.5%, 100% 17.1%, 76.6% 3%, 48.4% 0%, 44.6% 4.7%, 54.5% 25.3%, 59.8% 49%, 55.2% 57.8%, 44.4% 57.2%, 27.8% 47.9%, 35.1% 81.5%, 0% 97.7%, 39.2% 100%, 35.2% 81.4%, 97.2% 52.8%, 63.1% 29.5%)" />
				</div>

				<div class="overflow-hidden">
					<div class="mx-auto max-w-7xl px-6 pb-32 pt-12 sm:pt-20 lg:px-8 lg:pt-32">
						<div class="mx-auto max-w-2xl gap-x-14 lg:mx-0 lg:flex lg:max-w-none lg:items-center">
							<div class="w-full max-w-xl lg:shrink-0 xl:max-w-2xl">
								<h1 class="text-3xl font-bold tracking-tight text-stone-900 md:text-4xl lg:text-6xl">Pomáháme lidem hledat jejich ztracené chlupaté kamarády.</h1>
								<p class="relative mt-6 text-lg leading-8 text-stone-600 sm:max-w-md lg:max-w-none">Jako "páníčci" moc dobře víme, jaká je to bezmoc, ztratit svého domácího mazlíčka. Jsme tu od toho, abychom vám byli oporou a udělali maximum pro to, abychom ho v pořádku vrátili domů. Vše nakonec dobře dopadne.</p>
							</div>
							<div class="mt-14 flex justify-end gap-8 sm:-mt-44 sm:justify-start sm:pl-20 lg:mt-0 lg:pl-0">
								<div class="ml-auto w-44 flex-none space-y-8 pt-32 sm:ml-0 sm:pt-80 lg:order-last lg:pt-36 xl:order-none xl:pt-80">
									<div class="relative">
										<img src="https://images.unsplash.com/photo-1581888227599-779811939961?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1074&q=80" alt="" class="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg" />
										<div class="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-stone-900/10" />
									</div>
								</div>
								<div class="mr-auto w-44 flex-none space-y-8 sm:mr-0 sm:pt-52 lg:pt-36">
									<div class="relative">
										<img src="https://images.unsplash.com/photo-1554456854-55a089fd4cb2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80" alt="" class="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg" />
										<div class="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-stone-900/10" />
									</div>
									<div class="relative">
										<img src="https://images.unsplash.com/photo-1557842756-4fcb52d31bb6?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1074&q=80" alt="" class="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg" />
										<div class="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-stone-900/10" />
									</div>
								</div>
								<div class="w-44 flex-none space-y-8 pt-32 sm:pt-0">
									<div class="relative">
										<img src="https://images.unsplash.com/photo-1547955922-85912e223015?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=687&q=80" alt="" class="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg" />
										<div class="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-stone-900/10" />
									</div>
									<div class="relative">
										<img src="https://images.unsplash.com/photo-1585399058947-f68f9db58e5f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80" alt="" class="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg" />
										<div class="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-stone-900/10" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- Content section -->
			<div class="mx-auto -mt-12 max-w-7xl px-6 sm:mt-0 lg:px-8 xl:-mt-8">
				<div class="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
					<h2 class="text-3xl font-bold tracking-tight text-stone-900 sm:text-4xl">Náš příběh</h2>
					<div class="mt-6 flex flex-col gap-x-8 gap-y-20 lg:flex-row">
						<div class="lg:w-full lg:max-w-2xl lg:flex-auto">
							<p class="text-xl leading-8 text-stone-600">Jako Češi jsme národ pejskařů a kočičkářů a celkem tu tak s námi žije přes 3,5 milionu domácích mazlíčků. Z vlastní zkušenosti víme, jak mohou být některá zvířátka neposlušná a mohou se lehce ztratit.</p>
							<div class="mt-10 max-w-xl text-base leading-7 text-stone-700">
								<p>Začali jsme jako běžná marketingová agentura, která pomáhala firmám s prodejem jejich produktů na sociálních sítích. Našeho zakladatele Jirku vždy fascinovalo, jak je možné oslovit takové množství lidí najednou a jednoduše. Téměř každý z nás denně používá Facebook nebo Instagram.</p>
								<p class="mt-10">Po zkušenostech se ztrátou jeho mazlíčka si spojil obě věci dohromady a začal vymýšlet nový způsob hledání zvířat. Zrodil se tak projekt Najdi Mazlíčka a v roce 2019 jsme začali s prvním pátráním. Od té doby jsme projekt spustili i v Německu, Rakousku a Švýcarsku. Naším cílem je pomoci každému ztracenému zvířátku na planetě, aby se v pořádku vrátilo domů.</p>
							</div>
						</div>
						<div class="lg:flex lg:flex-auto lg:justify-center">
							<dl class="w-64 space-y-8 xl:w-80">
								<div v-for="stat in stats" :key="stat.label" class="flex flex-col-reverse gap-y-4">
									<dt class="text-base leading-7 text-stone-600">{{ stat.label }}</dt>
									<dd class="text-4xl lg:text-5xl font-semibold tracking-tight text-stone-900">{{ stat.value }}</dd>
								</div>
							</dl>
						</div>
					</div>
				</div>
			</div>


			

			<div className="mx-auto max-w-7xl px-6 lg:px-8 mt-32 sm:mt-40">
				<h2 className="text-center text-lg font-semibold leading-8 text-gray-900">
					Podporují nás:
				</h2>
				<div className="mx-auto mt-10 max-w-lg items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
				<!-- <div className="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5"> -->
					<a href="https://www.gentledogs.cz" target="_blank" rel="nofollow">
						<img
							className="col-span-2 max-h-20 w-full object-contain lg:col-span-1"
							src="https://cdn.myshoptet.com/usr/www.gentledogs.cz/user/logos/gd-novelogo-pruhledne-1.png"
							alt="www.GentleDogs.cz"
							width={200}
						/>
					</a>

					<a href="https://www.mujpes.cz" target="_blank" rel="nofollow" class="group">
						<img
							className="col-span-2 max-h-12 w-full object-contain lg:col-span-1 mt-5"
							src="https://cdn.myshoptet.com/usr/www.mujpes.cz/user/logos/logo_orange_30kb.png"
							alt="Example Logo"
							width={200}
						/>
						<p className="text-sm text-gray-500 text-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 mt-5">Na poli krmiv a chovatelských potřeb působíme již více jak 20 let. Jsme malou, odborně vedenou českou firmou. Jsme pevně přesvědčeni, že ten, kdo něco nabízí a prodává, by měl být také odborníkem, který nabízený sortiment zná a dokáže poskytnout kvalifikované rady a pomoc. Provozujeme poradnu z oblasti výživy psů a veterinární poradnu, pořádáme odborné přednášky na témata z výživy psů. Kromě služeb e-shopu nabízíme i služby klasické kamenné prodejny, kde se s námi můžete osobně setkat. V místě našeho působení, tedy v Brně, nabízíme rozvoz krmiv až k vám domů.</p>
					</a>
					<!-- <img
						className="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1"
						src="https://tailwindui.com/img/logos/158x48/savvycal-logo-gray-900.svg"
						alt="SavvyCal"
						width={158}
						height={48}
					/> -->
					<!-- <img
						className="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1"
						src="https://tailwindui.com/img/logos/158x48/statamic-logo-gray-900.svg"
						alt="Statamic"
						width={158}
						height={48}
					/> -->
				</div>
			</div>

			<!-- Image section -->
			<div class="mt-32 sm:mt-40 xl:mx-auto xl:max-w-7xl xl:px-8">
				<img src="https://images.unsplash.com/photo-1543269865-cbf427effbad?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80" alt="" class="aspect-[5/2] w-full object-cover xl:rounded-3xl" />
			</div>



			<!-- Team section -->
			<!-- <div class="mx-auto mt-32 max-w-7xl px-6 sm:mt-48 lg:px-8">
				<div class="mx-auto max-w-2xl lg:mx-0">
					<h2 class="text-3xl font-bold tracking-tight text-stone-900 sm:text-4xl">Our team</h2>
					<p class="mt-6 text-lg leading-8 text-stone-600">Sit facilis neque ab nulla vel. Cum eos in laudantium. Temporibus eos totam in dolorum. Nemo vel facere repellendus ut eos dolores similique.</p>
				</div>
				<ul role="list" class="mx-auto mt-20 grid max-w-2xl grid-cols-2 gap-x-8 gap-y-16 text-center sm:grid-cols-3 md:grid-cols-4 lg:mx-0 lg:max-w-none lg:grid-cols-5 xl:grid-cols-6">
					<li v-for="person in team" :key="person.name">
						<img class="mx-auto h-24 w-24 rounded-full" :src="person.imageUrl" alt="" />
						<h3 class="mt-6 text-base font-semibold leading-7 tracking-tight text-stone-900">{{ person.name }}</h3>
						<p class="text-sm leading-6 text-stone-600">{{ person.role }}</p>
					</li>
				</ul>
			</div> -->

		<div class="mx-auto max-w-7xl px-6 lg:px-8 mt-32">
			<div class="mx-auto max-w-2xl space-y-16 divide-y divide-gray-100 lg:mx-0 lg:max-w-none">
				<div class="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-3">
					<div>
						<h2 class="text-3xl font-bold tracking-tight text-gray-900">Spojte se s námi</h2>
						<p class="mt-4 leading-7 text-gray-600">Potřebujete poradit nebo vymyslet spolupráci? Ozvěte se nám!</p>
					</div>
					<div class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:col-span-2 lg:gap-8">
						<div class="rounded-2xl bg-white border p-10">
							<h3 class="text-base font-semibold leading-7 text-gray-900">Ztracení domácí mazlíčci</h3>
							<dl class="mt-3 space-y-1 text-sm leading-6 text-gray-600">
								<div>
									<dt class="sr-only">Email</dt>
									<dd><a class="font-semibold text-orange-600" href="mailto:podpora@najdimazlicka.cz">podpora@najdimazlicka.cz</a></dd>
								</div>
								<div class="mt-1">
									<dt class="sr-only">Telefonní číslo</dt>
									<dd>+420 605 176 305</dd>
								</div>
							</dl>
						</div>
						<div class="rounded-2xl bg-white border p-10">
							<h3 class="text-base font-semibold leading-7 text-gray-900">PR a spolupráce</h3>
							<dl class="mt-3 space-y-1 text-sm leading-6 text-gray-600">
								<div>
									<dt class="sr-only">Email</dt>
									<dd><a class="font-semibold text-orange-600" href="mailto:jiri@palmmedia.cz">jiri@palmmedia.cz</a></dd>
								</div>
								<div class="mt-1">
									<dt class="sr-only">Telefonní číslo</dt>
									<dd>+420 605 176 305</dd>
								</div>
							</dl>
						</div>
					</div>
				</div>
			</div>
		</div>


	

			

			<!-- Blog section -->
			<!-- <div class="mx-auto mt-32 max-w-7xl px-6 sm:mt-40 lg:px-8">
				<div class="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
					<h2 class="text-3xl font-bold tracking-tight text-stone-900 sm:text-4xl">From the blog</h2>
					<p class="mt-2 text-lg leading-8 text-stone-600">Vel dolorem qui facilis soluta sint aspernatur totam cumque.</p>
				</div>
				<div class="mx-auto mt-16 grid max-w-2xl auto-rows-fr grid-cols-1 gap-8 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
					<article v-for="post in blogPosts" :key="post.id" class="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl bg-stone-900 px-8 pb-8 pt-80 sm:pt-48 lg:pt-80">
						<img :src="post.imageUrl" alt="" class="absolute inset-0 -z-10 h-full w-full object-cover" />
						<div class="absolute inset-0 -z-10 bg-gradient-to-t from-stone-900 via-stone-900/40" />
						<div class="absolute inset-0 -z-10 rounded-2xl ring-1 ring-inset ring-stone-900/10" />

						<div class="flex flex-wrap items-center gap-y-1 overflow-hidden text-sm leading-6 text-stone-300">
							<time :datetime="post.datetime" class="mr-8">{{ post.date }}</time>
							<div class="-ml-4 flex items-center gap-x-4">
								<svg viewBox="0 0 2 2" class="-ml-0.5 h-0.5 w-0.5 flex-none fill-white/50">
									<circle cx="1" cy="1" r="1" />
								</svg>
								<div class="flex gap-x-2.5">
									<img :src="post.author.imageUrl" alt="" class="h-6 w-6 flex-none rounded-full bg-white/10" />
									{{ post.author.name }}
								</div>
							</div>
						</div>
						<h3 class="mt-3 text-lg font-semibold leading-6 text-white">
							<a :href="post.href">
								<span class="absolute inset-0" />
								{{ post.title }}
							</a>
						</h3>
					</article>
				</div>
			</div> -->

			<CtaMain class="bg-transparent mt-32"></CtaMain>

		</main>
	</div>



	<MainFooter></MainFooter>
</template>

<script setup>

import { useSeoMeta } from '@vueuse/head'

useSeoMeta ({
	title: 'Kdo jsme? | Najdi Mazlíčka - Hledáme ztracené domácí mazlíčky',
	description: 'Pomohli jsme najít už stovky ztracených psů a koček po celé ČR. Fungujeme již 5 let a oslovili jsme přes 8 milionů lidí. Moc rádi pomůžeme i vám.',
	ogDescription: 'Pomohli jsme najít už stovky ztracených psů a koček po celé ČR. Fungujeme již 5 let a oslovili jsme přes 8 milionů lidí. Moc rádi pomůžeme i vám.',
	ogTitle: 'Kdo jsme?',
	ogImage: 'https://najdimazlicka.cz/og.jpg',
	twitterCard: 'summary_large_image',
});


const stats = [
  { label: 'Fungování našeho projektu', value: '5 let' },
  { label: 'Oslovených lidí našimi kampaněmi', value: '8+ milionů' },
  { label: 'Jsme vám k dispozici', value: '7 dní v týdnu' },
]
// const values = [
//   {
//     name: 'Be world-class',
//     description:
//       'Aut illo quae. Ut et harum ea animi natus. Culpa maiores et sed sint et magnam exercitationem quia. Ullam voluptas nihil vitae dicta molestiae et. Aliquid velit porro vero.',
//   },
//   {
//     name: 'Share everything you know',
//     description:
//       'Mollitia delectus a omnis. Quae velit aliquid. Qui nulla maxime adipisci illo id molestiae. Cumque cum ut minus rerum architecto magnam consequatur. Quia quaerat minima.',
//   },
//   {
//     name: 'Always learning',
//     description:
//       'Aut repellendus et officiis dolor possimus. Deserunt velit quasi sunt fuga error labore quia ipsum. Commodi autem voluptatem nam. Quos voluptatem totam.',
//   },
//   {
//     name: 'Be supportive',
//     description:
//       'Magnam provident veritatis odit. Vitae eligendi repellat non. Eum fugit impedit veritatis ducimus. Non qui aspernatur laudantium modi. Praesentium rerum error deserunt harum.',
//   },
//   {
//     name: 'Take responsibility',
//     description:
//       'Sit minus expedita quam in ullam molestiae dignissimos in harum. Tenetur dolorem iure. Non nesciunt dolorem veniam necessitatibus laboriosam voluptas perspiciatis error.',
//   },
//   {
//     name: 'Enjoy downtime',
//     description:
//       'Ipsa in earum deserunt aut. Quos minus aut animi et soluta. Ipsum dicta ut quia eius. Possimus reprehenderit iste aspernatur ut est velit consequatur distinctio.',
//   },
// ]
// const team = [
//   {
//     name: 'Michael Foster',
//     role: 'Co-Founder / CTO',
//     imageUrl:
//       'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
//   },
//   // More people...
// ]

</script>

<script>
import MainHeader from '../components/MainHeader.vue'
import MainFooter from '../components/MainFooter.vue'
import CtaMain from '../components/CtaMain.vue'

export default {
    name: 'AboutView',
    components: {
        MainHeader,
        MainFooter,
				CtaMain,
    }
}
</script>