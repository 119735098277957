
<template>
  <MainHeader></MainHeader>

  <BreadCrumb hide :pages="[
  { name: 'Kočičí detektiv', href: '/kocici-detektiv', current: true },
]"/>

  <div class="safarifix relative overflow-hidden bg-stone-900">
    <div class="safarifix absolute inset-0 block" aria-hidden="true">
      <img class="safarifix blur-sm  block w-auto h-auto opacity-40 min-w-full min-h-full absolute top-0 left-0 object-cover" src="../assets/img/kocici-detektiv.jpg" alt="" />
    </div>
    <div class="relative pb-16 sm:pb-24">
      <main class="mt-16 sm:mt-24">
        <div class="mx-auto max-w-7xl">
          <div class="lg:grid lg:grid-cols-12 lg:gap-8">
            <div class="px-6 sm:text-center md:mx-auto md:max-w-2xl lg:col-span-6 lg:flex lg:items-center lg:text-left">
              <div>
                <div class="block ml-0 mb-6 sm:mx-auto lg:ml-0 sm:mb-8 sm:flex sm:justify-center lg:justify-start">
                  <router-link to="/o-nas" class="block overflow-hidden p-2 w-fit sm:overflow-visible sm:flex items-center rounded-lg sm:rounded-full bg-stone-900/[.60] sm:p-1 sm:pr-2 text-white hover:text-gray-200 sm:text-base lg:text-sm xl:text-base">
                    <span class="block mb-1 sm:mb-0 w-fit rounded-full bg-maz-dark px-3 py-0.5 text-sm font-semibold leading-5 text-white">Fungujeme již 5 let</span>
                    <span class="ml-2 sm:ml-4 text-sm">Přes 8 milionů oslovených lidí</span>
                    <ChevronRightIcon class="inline-block sm:block ml-2 h-5 w-5 text-gray-500" aria-hidden="true" />
                  </router-link>
                </div>
                <h1 class="text-3xl font-bold tracking-tight text-white sm:text-4xl md:text-5xl lg:md:text-6xl leading-tight md:leading-tight">Kočičí detektiv: Najdeme vaší ztracenou kočku 🐈</h1>
                <span class="text-white mt-3 block">(pokud je potřeba, tak i <router-link class="underline" to="/">pejska</router-link>)</span>
                <p class="mt-8 text-base text-gray-300 sm:mt-10 sm:text-xl lg:text-lg xl:text-xl">Ztratila se vám kočka nebo kocourek? Vytvoříme speciální pátrací kampaň s fotkami na Facebooku a Instagramu. Do pár hodin tak oslovíme tisíce lidí v lokalitě, kde se vaše <router-link class="underline" to="/ztracena-zvirata/kocky">kočka ztratila</router-link>.</p>
              </div>
            </div>
            <FormSmall
              submitRoute="/report"
              customHeading="Registrujte kočku ZDARMA"
              customSubheading="Zadejte informace o své ztracené kočičce a začněte s pátráním. Hotovo máte za 60 vteřin."
              customNameHeading="Jméno kočky"
            ></FormSmall>
          </div>
        </div>
      </main>
    </div>
  </div>


  <RecentlySaved cats></RecentlySaved>

  <!-- upravit na kočky -->


  <AboutUsSection cats></AboutUsSection>
  <HowItWorks cats></HowItWorks>
  <MainStats cats></MainStats>
  <MainTestemonial cats></MainTestemonial>
  <FaqSection></FaqSection>
  <CtaMain cats></CtaMain>
  



  <MainFooter></MainFooter>
</template>

<script setup>
  import { useSeoMeta } from '@vueuse/head'
	// import { ChevronRightIcon } from '@heroicons/vue/20/solid'

  useSeoMeta ({
      title: 'Kočičí detektiv: Hledáme ztracené kočky | Registrace ZDARMA',
      description: 'Jsme kočičí detektivové! Ztratila se vám kočka nebo kocourek? Do pár hodin oslovíme tisíce lidí v lokalitě kde se váš mazlíček ztratil. Registrujte se ZDARMA.',
      ogDescription: 'Jsme kočičí detektivové! Ztratila se vám kočka nebo kocourek? Do pár hodin oslovíme tisíce lidí v lokalitě kde se váš mazlíček ztratil. Registrujte se ZDARMA.',
      ogTitle: 'Kočičí detektiv: Hledáme ztracené kočky | Registrace ZDARMA',
      ogImage: 'https://najdimazlicka.cz/og.jpg',
      twitterCard: 'summary_large_image',
  });

</script>

<script>

import FormSmall from '../components/FormSmall.vue'
import MainHeader from '../components/MainHeader.vue'
import BreadCrumb from '../components/BreadCrumb.vue'
import MainFooter from '../components/MainFooter.vue'
import CtaMain from '../components/CtaMain.vue'
import AboutUsSection from '../components/AboutUsSection.vue'
import MainTestemonial from '../components/MainTestemonial.vue'
import MainStats from '../components/MainStats.vue'
import HowItWorks from '../components/HowItWorks.vue'
import RecentlySaved from '../components/RecentlySaved.vue'
import FaqSection from '../components/FaqSection.vue'


export default {
  name: 'kociciDetektiv',
  components: {
      MainHeader,
      FormSmall,
      // MainLanding,
      MainFooter,
      CtaMain,
      AboutUsSection,
      MainTestemonial,
      MainStats,
      HowItWorks,
      RecentlySaved,
      FaqSection,
  }
}
</script>


<style>
</style>