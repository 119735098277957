<template>
    <button :name='name' class="ml-3 cursor-pointer inline-flex items-center rounded-md bg-white px-3 py-2 text-md font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 w-fit">{{ text }}</button>
</template>

<script>
export default {
    name: 'HollowButton',
    props: {
        name: String,
        text: {
            type: String,
            default: "Button"
        },
        type: {
            type: String,
            default: "button"
        }
    },
}
</script>