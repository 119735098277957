<template>
  <MainHeader></MainHeader>


  <div class="relative isolate bg-stone-100 py-12 sm:py-24 lg:pb-32">
    <div class="absolute inset-x-0 top-1/2 -z-10 -translate-y-1/2 transform-gpu overflow-hidden opacity-30 blur-3xl" aria-hidden="true">
      <div class="ml-[max(50%,38rem)] aspect-[1313/771] w-[82.0625rem] bg-gradient-to-tr from-[#FF9A45] to-[#FF405B]" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)" />
    </div>
    <div class="absolute inset-x-0 top-0 -z-10 flex transform-gpu overflow-hidden pt-32 opacity-25 blur-3xl sm:pt-40 xl:justify-end" aria-hidden="true">
      <div class="ml-[-22rem] aspect-[1313/771] w-[82.0625rem] flex-none origin-top-right rotate-[30deg] bg-gradient-to-tr from-[#FF9A45] to-[#FF405B] xl:ml-0 xl:mr-[calc(50%-12rem)]" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)" />
    </div>
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-4xl text-center">
        <h2 class="text-lg font-semibold leading-8 tracking-tight text-orange-500">Recenze</h2>
        <p class="mt-2 text-2xl font-bold tracking-tight text-stone-900 sm:text-4xl lg:text-5xl">Pomohli jsme stovkám lidem najít jejich ztracené domácí mazlíčky</p>
      </div>
      <div class="mx-auto mt-16 grid max-w-2xl grid-cols-1 grid-rows-1 gap-8 text-sm leading-6 text-gray-900 sm:mt-20 sm:grid-cols-2 xl:mx-0 xl:max-w-none xl:grid-flow-col xl:grid-cols-4">
        <figure class="col-span-2 hidden sm:block sm:rounded-2xl sm:bg-white sm:shadow-lg sm:ring-1 sm:ring-gray-900/5 xl:col-start-2 xl:row-end-1">
          <blockquote class="p-10 text-lg font-semibold leading-8 tracking-tight text-gray-900">
            <p>{{ `“${featuredTestimonial.body}”` }}</p>
          </blockquote>
          <figcaption class="flex items-center gap-x-4 border-t border-stone-900/10 px-6 py-4">
            <img class="h-10 w-10 flex-none rounded-full bg-stone-50" :src="featuredTestimonial.author.imageUrl" alt="" />
            <div class="flex-auto">
              <div class="font-semibold">{{ featuredTestimonial.author.name }}</div>
              <div class="text-stone-600">{{ `${featuredTestimonial.author.handle}` }}</div>
            </div>
            <div class="text-2xl">
              ⭐️⭐️⭐️⭐️⭐️
            </div>
          </figcaption>
        </figure>
        <div v-for="(columnGroup, columnGroupIdx) in testimonials" :key="columnGroupIdx" class="space-y-8 xl:contents xl:space-y-0">
          <div v-for="(column, columnIdx) in columnGroup" :key="columnIdx" :class="[(columnGroupIdx === 0 && columnIdx === 0) || (columnGroupIdx === testimonials.length - 1 && columnIdx === columnGroup.length - 1) ? 'xl:row-span-2' : 'xl:row-start-1', 'space-y-8']">
            <figure v-for="testimonial in column" :key="testimonial.author.handle" class="rounded-2xl bg-white p-6 shadow-lg ring-1 ring-gray-900/5">
              <blockquote class="text-stone-900">
                <p>{{ `“${testimonial.body}”` }}</p>
              </blockquote>
              <figcaption class="mt-6 flex items-center gap-x-4">
                <img class="h-10 w-10 rounded-full bg-stone-50" :src="testimonial.author.imageUrl" alt="" />
                <div>
                  <div class="font-semibold">{{ testimonial.author.name }}</div>
                  <div class="text-stone-600">{{ `${testimonial.author.handle}` }}</div>
                </div>
              </figcaption>
            </figure>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="relative isolate overflow-hidden bg-stone-900 py-12 sm:py-32">
    <img src="https://images.unsplash.com/photo-1604848698030-c434ba08ece1?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1074&q=80" alt="" class="absolute inset-0 -z-10 h-full w-full object-cover opacity-20 blur-sm" />
    <div class="relative mx-auto max-w-7xl px-6 lg:px-8">
      <div class="absolute -bottom-8 -left-96 -z-10 transform-gpu blur-3xl sm:-bottom-64 sm:-left-40 lg:-bottom-32 lg:left-8 xl:-left-10" aria-hidden="true">
        <div class="aspect-[1266/975] w-[79.125rem] bg-gradient-to-tr from-[#FF405B] to-[#FF9A45] opacity-20" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)" />
      </div>
      <div class="mx-auto max-w-2xl lg:mx-0 lg:max-w-full">
        <h2 class="text-base font-semibold leading-8 text-orange-400">Služba, které můžete věřit</h2>
        <p class="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">Největší radost nám dělá každé úspěšné shledání ❤️</p>
        <p class="mt-6 text-lg leading-8 text-stone-300">Za 5 let našeho fungování jsme se naučili hodně o tom, jak pomoci lidem najít jejich ztraceného kamaráda. Nejste v tom sami.</p>
      </div>
      <dl class="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-10 text-white sm:mt-20 sm:grid-cols-2 sm:gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-4">
        <div v-for="stat in stats" :key="stat.id" class="flex flex-col gap-y-3 border-l border-white/10 pl-6">
          <dt class="text-sm leading-6">{{ stat.name }}</dt>
          <dd class="order-first text-3xl font-semibold tracking-tight">{{ stat.value }}</dd>
        </div>
      </dl>
    </div>
  </div>

  <RecentlySaved></RecentlySaved>

  <AboutUsSection></AboutUsSection>

  <CtaMain class="sm:mt-24"></CtaMain>

  <FaqSection></FaqSection>
  
  <MainFooter></MainFooter>
</template>


<script setup>

import { useSeoMeta } from '@vueuse/head'
import customerViteslav from '../assets/img/customerViteslav.png'
import customerZoe from '../assets/img/customerZoe.jpg'
import customer1 from '../assets/img/customer1.png'
import customer2 from '../assets/img/customer2.png'
import customerArny from '../assets/img/customerArny.jpeg'
import customer3 from '../assets/img/customer3.png'
import customerBarnie from '../assets/img/customerBarnie.jpeg'
import customerSushi from '../assets/img/customerSushi2.jpeg'
import customerMalicek2 from '../assets/img/customerMalicek2.jpeg'


useSeoMeta ({
	title: 'Recenze: Služba na hledání ztracených zvířat | Najdi Mazlíčka',
	description: 'Pomohli jsme stovkám lidem najít jejich ztracené domácí mazlíčky po celé ČR. Máme už 5 let zkušeností s pátracími kampaněmi pro ztracené psi a kočky.',
	ogDescription: 'Pomohli jsme stovkám lidem najít jejich ztracené domácí mazlíčky po celé ČR. Máme už 5 let zkušeností s pátracími kampaněmi pro ztracené psi a kočky.',
	ogTitle: 'Recenze: Služba na hledání ztracených zvířat | Najdi Mazlíčka',
	ogImage: 'https://najdimazlicka.cz/og.jpg',
	twitterCard: 'summary_large_image',
});

const featuredTestimonial = {
  body: 'Nedávno se nám zaběhli dva pejsci, Toby a Alex. Nyní jsme již zase všichni šťastně pohromadě. Děkuji Vám všem kdo jste se na pátrání prostřednictvím služby „Najdi mazlíčka“ podíleli a poskytovali nám podporu. Je velice důležité, že taková služba jako je najdimazlicka.cz existuje a hlavně, že funguje. Komunita lidí, úřadů, spolků a všech ochotných pomoci, kteří byli ve velmi krátkém časovém úseku po ztrátě osloveni, by byla nad síly jednotlivce. A právě rychlost a kvalita rozšíření informace na správné lidi a místa je klíčem k úspěšnému shledání se ztraceným mazlíčkem.',
  author: {
    name: 'Vítězslav H.',
    handle: 'Šťasný páníček',
    imageUrl: customerViteslav,
  },
}

const testimonials = [
  [
    [
      {
        body: 'O naší ztrátě vědělo opravdu široké okolí cizích lidí, se kterými jsme náhodně mluvili při pátrání.',
        author: {
          name: 'Zuzana W.',
          handle: 'Šťastná panička',
          imageUrl: customer1,
        },
      },
      {
        body: 'Dostali jsme tip od jedné slečny, která chodí krmit potulné kočky, že si ji všimla a nepatří mezi ostatní co tam potkává. Moc bych Vám chtěla poděkovat za pomoc, bez Vaší pomoci bychom ještě hledali, nebo vůbec nenašli. Děkuji.',
        author: {
          name: 'Lenka Z.',
          handle: 'Šťastná panička',
          imageUrl: customer2,
        },
      },
      {
        body: 'Barnie jednoho večera utekl ze zahrady u rodiného domu. Celé 2 dny ho jeho majitelé hledali. Za 8 hodin od spuštění naší kampaně se podařilo oslovit přes 12 tisíc lidí včetně jeho zachránce, který ho ještě ten den předal majitelům.',
        author: {
          name: 'Barnie',
          handle: 'Příběh ztráty',
          imageUrl: customerBarnie,
        },
      }
      // More testimonials...
    ],
    [
      {
        body: 'Malíček je kocourek, který nikdy nebyl venku. Jednoho dne však utekl a nikdo nevěděl, jak ho hledat. Za první den kampaně jsme oslovili 12 tisíc lidí z okolí, díky čemuž se nám kocourka podařilo vrátit v pořádku domů.',
        author: {
          name: 'Malíček',
          handle: 'Příběh ztráty',
          imageUrl: customerMalicek2,
        },
      },
      // More testimonials...
    ],
  ],
  [
    [
      {
        body: 'Sushi se ztratila z panelového domu ve velmi zalidněné části Prahy. Během 2 dní jsme oslovili přes 9 tisíc lidí z okolí. Jakmile se o ztrátě dozvěděl i človek, který se této kočičky ujal, sám kontaktoval majitelku a vrátil Sushi domů.',
        author: {
          name: 'Sushi',
          handle: 'Příběh ztráty',
          imageUrl: customerSushi,
        },
      },
      // More testimonials...
    ],
    [
      {
        body: 'Zoe se na procházce ztratila z dohledu a byla zaběhnutá 9 dní. Díky Vaší službě o ní vědělo široké okolí a každý den nám volali lidé z celého okresu, že zřejmě viděli našeho psa. Po spoustě planých výjezdů se nakonec ozval pán se správným tipem a Zoe jsme našli v poli jen pár km od domova.',
        author: {
          name: 'Vojtěch H.',
          handle: 'Šťastný páníček',
          imageUrl: customerZoe,
        },
      },
      {
        body: 'Člověk si vůbec neuvědomuje hodnotu takové služby do té doby, než ji opravdu potřebuje...',
        author: {
          name: 'Dara N.',
          handle: 'Šťastná panička',
          imageUrl: customer3,
        },
      },
      {
        body: 'Arny se ztratil při výkonu strážní služby v Pardubicích. Během pár dní se nám podařilo oslovit přes 37 tisíc lídí z Pardubic a okolí. Díky tomu se ho také podařilo najít.',
        author: {
          name: 'Arny, policení pes',
          handle: 'Příběh ztráty',
          imageUrl: customerArny,
        },
      },
      // More testimonials...
    ],
  ],
  
]

const stats = [
  { id: 1, name: 'Fungování NajdiMazlíčka.cz', value: '5 let' },
  { id: 2, name: 'Průměrně o ztrátě informujeme', value: '16 000 lidí' },
  { id: 3, name: 'Jsme tu pro vás', value: '7 dní v týdnu' },
  { id: 4, name: 'A díky tomu průměrně zachráníme', value: '74% zvířat' },
]
</script>


<script>

import MainHeader from '../components/MainHeader.vue'
import MainFooter from '../components/MainFooter.vue'
import RecentlySaved from '@/components/RecentlySaved.vue';
import AboutUsSection from '@/components/AboutUsSection.vue';
import CtaMain from '@/components/CtaMain.vue';
import FaqSection from '@/components/FaqSection.vue';

export default {
  name: 'TestemonialsView',
  components: {
    MainHeader,
    MainFooter,
    RecentlySaved,
    AboutUsSection,
    CtaMain,
    FaqSection,
  },
}
</script>