
<template>
  <MainHeader></MainHeader>

  <div class="bg-white px-6 py-16 lg:px-8">
    <div class="mx-auto max-w-5xl text-base leading-7 text-stone-700">
      <p class="text-base font-semibold leading-7 text-orange-600">Právní dokument</p>
      <h1 class="mt-2 text-3xl font-bold tracking-tight text-stone-900 sm:text-4xl">Podmínky ochrany osobních údajů</h1>
      <div class="mt-10 max-w-5xl">


        <h2 class="text-2xl font-bold tracking-tight text-gray-900 mb-6 mt-8">I. Základní ustanovení</h2>
        <ol>
          <li><span>Správcem osobních údajů podle čl. 4 bod 7 nařízení Evropského parlamentu a Rady (EU) 2016/679 o ochraně fyzických osob v souvislosti se zpracováním osobních údajů a o volném pohybu těchto údajů (dále jen: „<b>GDPR</b>”) je Jiří Štěpánek IČ: 07539002, se sídlem: Na Kopci 164, 251 62 Svojetice (dále jen: „<b>správce</b>“).</span></li>
          <li><span>Kontaktní údaje správce jsou</span></li>
        </ol>
        <p class="mt-6"><span>adresa: Na Kopci 164, 251 62 Svojetice</span></p>
        <p><span>email: jiri@palmmedia.cz</span></p>
        <p><span>telefon: 605176305</span></p>
        <ol class="mt-6" start="3">
          <li class="mt-6"><span>Osobními údaji se rozumí veškeré informace o identifikované nebo identifikovatelné fyzické osobě; identifikovatelnou fyzickou osobou je fyzická osoba, kterou lze přímo či nepřímo identifikovat, zejména odkazem na určitý identifikátor, například jméno, identifikační číslo, lokační údaje, síťový identifikátor nebo na jeden či více zvláštních prvků fyzické, fyziologické, genetické, psychické, ekonomické, kulturní nebo společenské identity této fyzické osoby.</span></li>
          <li class="mt-6"><span>Správce nejmenoval pověřence pro ochranu osobních údajů.</span></li>
        </ol>
        <h2 class="text-2xl font-bold tracking-tight text-gray-900 mb-6 mt-8">II. Zdroje a kategorie zpracovávaných osobních údajů</h2>
        <ol>
          <li class="mt-6"><span>Správce zpracovává osobní údaje, které jste mu poskytl/a nebo osobní údaje, které správce získal na základě plnění Vaší objednávky.</span></li>
          <li class="mt-6"><span>Správce zpracovává Vaše identifikační a kontaktní údaje a údaje nezbytné pro plnění smlouvy.<span class="Apple-converted-space">&nbsp;</span></span></li>
        </ol>
        <h2 class="text-2xl font-bold tracking-tight text-gray-900 mb-6 mt-8">III. Zákonný důvod a účel zpracování osobních údajů</h2>
        <ol class="mt-6">
          <li><span>Zákonným důvodem zpracování osobních údajů je<span class="Apple-converted-space">&nbsp;</span></span></li>
        </ol>
        <ul>
          <li class="mt-6"><span></span><span>plnění smlouvy mezi Vámi a&nbsp;správcem podle čl. 6 odst. 1 písm. b) GDPR,</span></li>
          <li class="mt-6"><span></span><span>oprávněný zájem správce na poskytování přímého marketingu (zejména pro zasílání obchodních sdělení a newsletterů) podle čl. 6 odst. 1 písm. f) GDPR,</span></li>
        </ul>
        <ol start="2">
          <li class="mt-6"><span>Účelem zpracování osobních údajů je</span></li>
        </ol>
        <ul>
          <li class="mt-6"><span></span><span>vyřízení Vaší objednávky a výkon práv a povinností vyplývajících ze smluvního vztahu mezi Vámi a správcem; při objednávce jsou vyžadovány osobní údaje, které jsou nutné pro úspěšné vyřízení objednávky (jméno a adresa, kontakt), poskytnutí osobních údajů je nutným požadavkem pro uzavření a plnění smlouvy, bez poskytnutí osobních údajů není možné smlouvu uzavřít či jí ze strany správce plnit,</span></li>
          <li class="mt-6"><span></span><span>zasílání obchodních sdělení a činění dalších marketingových aktivit.<span class="Apple-converted-space">&nbsp;</span></span></li>
        </ul>
        <ol start="3">
          <li class="mt-6"><span>Ze strany správce nedochází k&nbsp;automatickému individuálnímu rozhodování ve&nbsp;smyslu čl. 22 GDPR.</span></li>
        </ol>
        <h2 class="text-2xl font-bold tracking-tight text-gray-900 mb-6 mt-8">IV. Doba uchovávání údajů</h2>
        <ol>
          <li class="mt-6"><span>Správce uchovává osobní údaje<span class="Apple-converted-space">&nbsp;</span></span></li>
        </ol>
        <ul>
          <li class="mt-6"><span></span><span>po dobu nezbytnou k výkonu práv a povinností vyplývajících ze smluvního vztahu mezi Vámi a správcem a uplatňování nároků z těchto smluvních vztahů (po dobu 15 let od ukončení smluvního vztahu).<span class="Apple-converted-space">&nbsp;</span></span></li>
        </ul>
        <ol start="2">
          <li class="mt-6"><span>Po uplynutí doby uchovávání osobních údajů správce osobní údaje vymaže.<span class="Apple-converted-space">&nbsp;</span></span></li>
        </ol>
        <h2 class="text-2xl font-bold tracking-tight text-gray-900 mb-6 mt-8">V. Příjemci osobních údajů (subdodavatelé správce)</h2>
        <ol>
          <li class="mt-6"><span>Příjemci osobních údajů jsou osoby<span class="Apple-converted-space">&nbsp;</span></span></li>
        </ol>
        <ul>
          <li class="mt-6"><span></span><span>podílející se na dodání zboží / služeb / realizaci plateb na základě smlouvy,<span class="Apple-converted-space">&nbsp;</span></span></li>
          <li class="mt-6"><span></span><span>zajišťující služby provozování e-shopu a další služby v&nbsp;souvislosti s&nbsp;provozováním e-shopu,</span></li>
          <li class="mt-6"><span></span><span>zajišťující marketingové služby.</span></li>
        </ul>
        <ol start="2">
          <li class="mt-6"><span>Správce má v úmyslu předat osobní údaje do třetí země (do země mimo EU) nebo mezinárodní organizaci. Příjemci osobních údajů ve třetích zemích jsou poskytovatelé mailingových služeb a cloudových služeb.<span class="Apple-converted-space">&nbsp;</span></span></li>
        </ol>
        <h2 class="text-2xl font-bold tracking-tight text-gray-900 mb-6 mt-8">VI. Vaše práva</h2>
        <ol>
          <li class="mt-6"><span>Za podmínek stanovených v&nbsp;GDPR máte<span class="Apple-converted-space">&nbsp;</span></span></li>
        </ol>
        <ul>
          <li class="mt-6"><span></span><span>právo na přístup ke svým osobním údajům dle čl. 15 GDPR,<span class="Apple-converted-space">&nbsp;</span></span></li>
          <li class="mt-6"><span></span><span>právo opravu osobních údajů dle čl. 16 GDPR, popřípadě omezení zpracování dle čl. 18 GDPR.<span class="Apple-converted-space">&nbsp;</span></span></li>
          <li class="mt-6"><span></span><span>právo na výmaz osobních údajů dle čl. 17 GDPR.<span class="Apple-converted-space">&nbsp;</span></span></li>
          <li class="mt-6"><span></span><span>právo vznést námitku proti zpracování dle čl. 21 GDPR a<span class="Apple-converted-space">&nbsp;</span></span></li>
          <li class="mt-6"><span></span><span>právo na přenositelnost údajů dle čl. 20 GDPR.<span class="Apple-converted-space">&nbsp;</span></span></li>
        </ul>
        <ol start="2">
          <li class="mt-6"><span>Dále máte právo podat stížnost u Úřadu pro ochranu osobních údajů v případě, že se domníváte, že bylo porušeno Vaší právo na ochranu osobních údajů.</span></li>
        </ol>
        <h2 class="text-2xl font-bold tracking-tight text-gray-900 mb-6 mt-8">VII. Podmínky zabezpečení osobních údajů</h2>
        <ol>
          <li class="mt-6"><span>Správce prohlašuje, že přijal veškerá vhodná technická a organizační opatření k&nbsp;zabezpečení osobních údajů.</span></li>
          <li class="mt-6"><span>Správce přijal technická opatření k&nbsp;zabezpečení datových úložišť a úložišť osobních údajů v&nbsp;listinné podobě, zejména kontaktní údaje a fotky.</span></li>
          <li class="mt-6"><span>Správce prohlašuje, že k&nbsp;osobním údajům mají přístup pouze jím pověřené osoby.</span></li>
        </ol>
        <h2 class="text-2xl font-bold tracking-tight text-gray-900 mb-6 mt-8">VIII. Závěrečná ustanovení</h2>
        <ol>
          <li class="mt-6"><span>Odesláním objednávky z internetového objednávkového formuláře potvrzujete, že jste seznámen/a s podmínkami ochrany osobních údajů a že je v celém rozsahu přijímáte.</span></li>
          <li class="mt-6"><span>S&nbsp;těmito podmínkami souhlasíte zaškrtnutím souhlasu prostřednictvím internetového formuláře. Zaškrtnutím souhlasu potvrzujete, že jste seznámen/a s podmínkami ochrany osobních údajů a že je v celém rozsahu přijímáte.</span></li>
          <li class="mt-6"><span>Správce je oprávněn tyto podmínky změnit. Novou verzi podmínek ochrany osobních údajů zveřejní na svých internetových stránkách a zároveň Vám zašle novou verzi těchto podmínek Vaši e-mailovou adresu, kterou jste správci poskytl/a.</span></li>
        </ol>
        <p class="mt-6"><span>Tyto podmínky nabývají účinnosti dnem 11.9.2019.</span></p>
      </div>
    </div>
  </div>

  <MainFooter></MainFooter>
</template>


<script setup>
import { useSeoMeta } from '@vueuse/head'


useSeoMeta ({
	title: 'Podmínky ochrany osobních údajů | Najdi Mazlíčka',
	description: 'Správce zpracovává osobní údaje, které jste mu poskytl/a nebo osobní údaje, které správce získal na základě plnění Vaší objednávky.',
	ogDescription: 'Správce zpracovává osobní údaje, které jste mu poskytl/a nebo osobní údaje, které správce získal na základě plnění Vaší objednávky.',
	ogTitle: 'Podmínky ochrany osobních údajů | Najdi Mazlíčka',
	ogImage: 'https://najdimazlicka.cz/og.jpg',
	twitterCard: 'summary_large_image',
  robots: "noindex"
});

</script>

<script>
import MainHeader from '../components/MainHeader.vue'
import MainFooter from '../components/MainFooter.vue'

export default {
  name: "PrivacyView", 
  components: {
    MainHeader,
    MainFooter
  }
}
</script>

