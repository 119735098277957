
<template>
    <MainHeader></MainHeader>
    <MainLanding></MainLanding>
    <RecentlySaved></RecentlySaved>
    <AboutUsSection></AboutUsSection>
    <HowItWorks></HowItWorks>
    <MainStats></MainStats>
    <MainTestemonial></MainTestemonial>
    <FaqSection></FaqSection>
    <CtaMain></CtaMain>
    <MainFooter></MainFooter>
    <Footer></Footer>
</template>

<script setup>
    import { useSeoMeta } from '@vueuse/head'

    useSeoMeta ({
        title: 'Hledáme ztracené domácí mazlíčky | Registrace ZDARMA 🐶🐱',
        description: 'Ztratil se vám pes nebo kočka? Vytvoříme speciální reklamní kampaň. Do pár hodin oslovíme tisíce lidí v lokalitě kde se váš mazlíček ztratil. 5 let zkušeností.',
        ogDescription: 'Ztratil se vám pes nebo kočka? Vytvoříme speciální reklamní kampaň. Do pár hodin oslovíme tisíce lidí v lokalitě kde se váš mazlíček ztratil. 5 let zkušeností.',
        ogTitle: 'Hledáme ztracené domácí mazlíčky | Registrace ZDARMA 🐶🐱',
        ogImage: 'https://najdimazlicka.cz/og.jpg',
        twitterCard: 'summary_large_image',
    });

</script>

<script>

import MainHeader from '../components/MainHeader.vue'
import MainLanding from '../components/MainLanding.vue'
import MainFooter from '../components/MainFooter.vue'
import CtaMain from '../components/CtaMain.vue'
import AboutUsSection from '../components/AboutUsSection.vue'
import MainTestemonial from '../components/MainTestemonial.vue'
import MainStats from '../components/MainStats.vue'
import HowItWorks from '../components/HowItWorks.vue'
import RecentlySaved from '../components/RecentlySaved.vue'
import FaqSection from '../components/FaqSection.vue'


export default {
    name: 'HomeView',
    components: {
        MainHeader,
        MainLanding,
        MainFooter,
        CtaMain,
        AboutUsSection,
        MainTestemonial,
        MainStats,
        HowItWorks,
        RecentlySaved,
        FaqSection,
    }
}
</script>


<style>
</style>