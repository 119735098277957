<template>
    <BareboneHeader />

    <div class="bg-stone-50 pt-8">        
        <!-- <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <TitleHeader :button="false" title="Platba" />
        </div> -->




        


        <div class="mt-6 sm:mt-16">
            <div class="mx-auto max-w-7xl px-6 lg:px-8">

                <div class="relative z-10">
                    <div class="mx-auto max-w-7xl text-center">
                        <h2 class="text-base font-semibold leading-7 text-orange-600">Poslední krok: Vyberte si pátrací balíček</h2>
                        <p class="mt-2 text-3xl font-bold tracking-tight text-stone-900 sm:text-5xl">Postaráme se, aby se váš mazlíček v pořádku našel</p>
                    </div>
                    <p class="mx-auto mt-6 max-w-7xl text-center text-lg leading-8 text-stone-600">Hlavní rozdíl mezi pátracími balíčky je rozpočet na reklamu, který platíme Facebooku. S dražším balíčkem tedy můžete očekávat, že se příspěvek o ztrátě vašeho zvířete zobrazí více lidem, vícekrát a rychleji.</p>
                    <a @click="$router.go(-1)" class="flex text-sm mt-4 items-center w-fit mx-auto opacity-50 cursor-pointer ">
                        <ChevronLeftIcon class="ml-2 h-5 w-5 text-black" aria-hidden="true" />
                        Zpět k upravě textu
                    </a>


                    <div class="max-w-md text-center mx-auto mt-12 bg-white border rounded-xl p-4">
						<div class="flex flex-col sm:flex-row sm:items-center mb-5 w-fit mx-auto">
							<!-- <p class="bg-blue-100 text-blue-800 text-sm font-semibold inline-flex items-center p-1.5 rounded">8.7</p> -->
							<div class="flex items-center mb-2 sm:mb-0">
								<p class="font-medium text-gray-900">Ověřená společnost</p>
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#84cc16" class="ml-2 w-6 h-6">
									<path fill-rule="evenodd" d="M8.603 3.799A4.49 4.49 0 0112 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 013.498 1.307 4.491 4.491 0 011.307 3.497A4.49 4.49 0 0121.75 12a4.49 4.49 0 01-1.549 3.397 4.491 4.491 0 01-1.307 3.497 4.491 4.491 0 01-3.497 1.307A4.49 4.49 0 0112 21.75a4.49 4.49 0 01-3.397-1.549 4.49 4.49 0 01-3.498-1.306 4.491 4.491 0 01-1.307-3.498A4.49 4.49 0 012.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 011.307-3.497 4.49 4.49 0 013.497-1.307zm7.007 6.387a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clip-rule="evenodd" />
								</svg>
							</div>

							<span class="hidden sm:block w-1 h-1 mx-2 bg-gray-900 rounded-full"></span>
							<p class="text-sm font-medium text-gray-500">500+ spokojených zákazníků</p>
						</div>

						<div class="flex items-center space-x-3">
							<img class="block w-[calc((100%-0.75rem)/2-8px)]" src="../assets/svg/googleMaps.svg" alt="" />
							<img class="block w-[calc((100%-0.75rem)/2+8px)]" src="../assets/svg/seznamMaps.svg" alt="" />
						</div>
					</div>


                    <div class="mx-auto max-w-7xl px-4 py-8 sm:px-6 lg:px-8">
                        <div class="mx-auto max-w-xl lg:max-w-none">
                            <div class="mx-auto mt-12 grid max-w-sm grid-cols-1 gap-x-8 gap-y-10 sm:max-w-none lg:grid-cols-3">
                                <div v-for="incentive in incentives" :key="incentive.name" class="text-center sm:flex sm:text-left lg:block lg:text-center">
                                    <div class="sm:flex-shrink-0">
                                        <div class="flow-root">
                                        <img class="mx-auto h-16 w-16" :src="incentive.imageSrc" alt="" />
                                        </div>
                                    </div>
                                    <div class="mt-3 sm:ml-6 sm:mt-0 lg:ml-0 lg:mt-6">
                                        <h3 class="text-sm font-medium text-stone-900">{{ incentive.name }}</h3>
                                        <p class="mt-2 text-sm text-stone-600">{{ incentive.description }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                </div>



            


                <div class="isolate relative mx-auto mt-16 grid max-w-md grid-cols-1 gap-y-8 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                    <svg viewBox="0 0 1208 1024" aria-hidden="true" class="hidden xl:block -z-10 absolute isolate -bottom-48 left-1/2 h-[64rem] -translate-x-1/2 translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] lg:-top-48 lg:bottom-auto lg:translate-y-0 opacity-70">
                        <ellipse cx="604" cy="512" fill="url(#d25c25d4-6d43-4bf9-b9ac-1842a30a4867)" rx="604" ry="512" />
                        <defs>
                            <radialGradient id="d25c25d4-6d43-4bf9-b9ac-1842a30a4867">
                                <stop stop-color="#FF405B" />
                                <stop offset="1" stop-color="#FF9A45" />
                            </radialGradient>
                        </defs>
                    </svg>


                    <div v-for="(tier, tierIdx) in tiers" :key="tier.id" :class="[tier.mostPopular ? 'lg:z-10 lg:rounded-b-none' : 'lg:mt-8', tierIdx === 0 ? 'lg:rounded-r-none' : '', tierIdx === tiers.length - 1 ? 'lg:rounded-l-none' : '', 'flex flex-col justify-between rounded-3xl bg-white p-8 ring-1 ring-gray-200 xl:p-10 lg:rounded-b-none']">
                        <div>
                            <div class="sm:flex items-left justify-between gap-x-4">
                                <h3 :id="tier.id" :class="[tier.mostPopular ? 'text-gray-6900' : 'text-gray-900', 'text-3xl font-semibold leading-8']">{{ tier.name }}</h3>
                                <p v-if="tier.mostPopular" class="mt-4 sm:mt-0 w-fit rounded-full bg-green-600/10 px-3.5 py-1 block text-xs font-semibold leading-6 text-green-600">Nejoblíbenější</p>
                                <p v-if="tier.performance" class="mt-4 sm:mt-0 w-fit rounded-full bg-blue-600/10 px-3.5 py-1 block text-xs font-semibold leading-6 text-blue-600">Největší výkon</p>
                            </div>
                            <p class="mt-4 text-sm leading-6 text-gray-600">{{ tier.description }}</p>
                            <p class="mt-6 flex items-baseline gap-x-1">
                                <span class="text-lg line-through mr-2 font-normal tracking-tight text-gray-600" v-if="tier.beforePrice">{{ tier.beforePrice }}</span>
                                <span class="text-2xl font-bold tracking-tight text-gray-900">{{ tier.priceMonthly }}</span>
                                <span class="text-sm font-semibold leading-6 text-gray-600"></span>
                            </p>
                            <ul role="list" class="mt-8 space-y-3 text-sm leading-6 text-gray-600">
                            <li v-for="feature in tier.features" :key="feature" class="flex gap-x-3">
                                <CheckIcon class="h-6 w-5 flex-none text-orange-600" aria-hidden="true" />
                                {{ feature }}
                            </li>
                            </ul>
                        </div>
                        <button @click="packageType = tier.number" :aria-describedby="tier.id" :class="[packageType === tier.number ? 'bg-orange-600 text-white shadow-sm hover:bg-orange-500' : 'text-orange-600 ring-1 ring-inset ring-orange-200 hover:ring-orange-300', 'mt-8 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600']">{{packageType === tier.number ? "Vybráno" : "Vybrat"}}</Button>
                    </div>
                </div>
            </div>
        </div>





        <div class="mt-12 lg:mt-0 bg-orange-600/10 py-12 z-30 relative">
            <div class="mx-auto max-w-5xl px-6 lg:px-8">
                <div class="mx-auto bg-white max-w-2xl rounded-3xl ring-1 ring-gray-200 lg:mx-0 lg:flex lg:max-w-none lg:items-middle ">
                    <div class="p-8 sm:p-10 lg:flex-auto lg:w-96">
                        
                        <h3 class="text-2xl font-bold tracking-tight text-gray-900 sm:flex"><img src="../assets/img/ig.png" class="mb-2 sm:mb-0 mr-4 w-8"> Instagram reklamy</h3>
                        <p class="mt-6 text-base leading-7 text-gray-600">Oslovte i mladší cílovou skupinu na populární sociální síti Instagram. Lokální reklamy v místě ztráty po celou dobu trvání kampaně.</p>
                        <div class="mt-10 flex items-center gap-x-4">
                            <h4 class="flex-none text-sm font-semibold leading-6 text-orange-600">Co to obsahuje?</h4>
                            <div class="h-px flex-auto bg-gray-100" />
                        </div>
                        <ul role="list" class="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6">
                            <li v-for="feature in includedFeatures" :key="feature" class="flex gap-x-3">
                            <CheckIcon class="h-6 w-5 flex-none text-orange-600" aria-hidden="true" />
                            {{ feature }}
                            </li>
                        </ul>
                    </div>
                    <div class="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
                        <div class="rounded-2xl py-10 h-full text-center bg-stone-50 ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
                            <div class="mx-auto max-w-xs px-8">
                                <p class="text-base font-semibold text-gray-600">Příplatek k jakémukoliv balíčku</p>
                                <p class="mt-6 flex items-baseline justify-center gap-x-2">
                                    <span class="text-4xl font-bold tracking-tight text-gray-900">+ 890</span>
                                    <span class="text-xl font-semibold leading-6 tracking-wide text-gray-600">Kč</span>
                                </p>
                                <button @click="addon = !addon" :class="[addon ? 'bg-orange-600 text-white shadow-sm hover:bg-orange-500 bg-gradient-to-r from-yellow-500 to-pink-500 hover:from-yellow-600 hover:to-pink-600' : 'text-orange-600 ring-1 ring-inset ring-orange-200 hover:ring-orange-300', 'mt-8 w-full block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600']">{{ addon ? "Vybráno" : "Vybrat"}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> 





















    <section aria-labelledby="summary-heading" class="mt-16 mx-auto block max-w-3xl px-6 lg:px-8">
        <h2 id="summary-heading" class="text-2xl text-center mb-12 font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">Shrnutí objednávky</h2>

        <div class="rounded-lg bg-gray-50 px-4 py-6 sm:p-6 lg:p-8 w-full">
            <div class="flow-root">
                <dl class="-my-4 divide-y divide-gray-200 text-sm">
                    <div class="flex items-center justify-between py-4">
                        <dt class="text-gray-600">Pátrácí kampaně na sociálních sítích</dt>
                        <dd class="font-medium text-gray-900">{{ packagePriceAmount > 0 ? packagePriceAmount + " Kč" : "Nevybráno" }}</dd>
                    </div>
                    <div class="flex items-center justify-between py-4">
                        <dt class="text-gray-600">Příplatek za Instagram</dt>
                        <dd class="font-medium text-gray-900">{{ addon ? "890 Kč" : "Nevybráno" }}</dd>
                    </div>
                    <div class="flex items-center justify-between py-4">
                        <dt class="text-base font-medium text-gray-900">Celkem</dt>
                        <dd class="text-base font-medium text-gray-900">{{ total }} Kč</dd>
                    </div>
                </dl>
            </div>
        </div>
        <div class="mt-10">
            <button type="submit" v-if="total > 890" @click="startCheckout" class="w-full order border-transparent bg-indigo-600 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50 cursor-pointer text-md leading-6 bg-gradient-to-r rounded-md from-[#FF9A45] hover:from-[#E68B3E] to-[#FF405B] hover:to-[#E53952] transition-all hover:drop-shadow-sm drop-shadow-md">Zaplatit</button>
            <button type="submit" v-if="total <= 890" class="w-full rounded-md border border-transparent bg-stone-600 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-stone-700 focus:outline-none focus:ring-2 focus:ring-stone-500 focus:ring-offset-2 focus:ring-offset-gray-50">Vyberte některý z balíčků</button>
        </div>

        <div class="mt-6 text-center text-sm text-gray-500">
            <p class="mb-6 flex justify-center text-sm font-medium text-gray-500">
                <LockClosedIcon class="mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                Bezpečná platba přes platební bránu Stripe
            </p>
            <p class="mb-4">
                Chcete raději platit přes účet/fakturu? Ozvěte se nám na +420 605 176 305 a vše pro vás zařídíme.
            </p>
            <p>
                Platbou souhlasíte s našimi <a href="/obchodni-podminky" target="_blank" class="font-medium text-stone-900 underline">obchodními podmínkami</a>.
            </p>
        </div>
    </section>

    <div class="bg-stone-100 py-16 mt-16 px-6 lg:px-8">
        <div class="mx-auto ms:mt-16 grid max-w-2xl grid-cols-1 grid-rows-1 gap-8 text-sm leading-6 text-gray-900 sm:my-0 sm:grid-cols-2 xl:mx-auto xl:max-w-7xl xl:grid-flow-col xl:grid-cols-4">
            <figure class="col-span-2 hidden sm:block sm:rounded-2xl sm:bg-white sm:shadow-lg sm:ring-1 sm:ring-gray-900/5 xl:col-start-2 xl:row-end-1">
            <blockquote class="p-10 text-lg font-semibold leading-8 tracking-tight text-gray-900">
                <p>{{ `“${featuredTestimonial.body}”` }}</p>
            </blockquote>
            <figcaption class="flex items-center gap-x-4 border-t border-stone-900/10 px-6 py-4">
                <img class="h-10 w-10 flex-none rounded-full bg-stone-50" :src="featuredTestimonial.author.imageUrl" alt="" />
                <div class="flex-auto">
                <div class="font-semibold">{{ featuredTestimonial.author.name }}</div>
                <div class="text-stone-600">{{ `${featuredTestimonial.author.handle}` }}</div>
                </div>
                <div class="text-2xl">
                ⭐️⭐️⭐️⭐️⭐️
                </div>
            </figcaption>
            </figure>
            <div v-for="(columnGroup, columnGroupIdx) in testimonials" :key="columnGroupIdx" class="space-y-8 xl:contents xl:space-y-0">
            <div v-for="(column, columnIdx) in columnGroup" :key="columnIdx" :class="[(columnGroupIdx === 0 && columnIdx === 0) || (columnGroupIdx === testimonials.length - 1 && columnIdx === columnGroup.length - 1) ? 'xl:row-span-2' : 'xl:row-start-1', 'space-y-8']">
                <figure v-for="testimonial in column" :key="testimonial.author.handle" class="rounded-2xl bg-white p-6 shadow-lg ring-1 ring-gray-900/5">
                <blockquote class="text-stone-900">
                    <p>{{ `“${testimonial.body}”` }}</p>
                </blockquote>
                <figcaption class="mt-6 flex items-center gap-x-4">
                    <img class="h-10 w-10 rounded-full bg-stone-50" :src="testimonial.author.imageUrl" alt="" />
                    <div>
                    <div class="font-semibold">{{ testimonial.author.name }}</div>
                    <div class="text-stone-600">{{ `${testimonial.author.handle}` }}</div>
                    </div>
                </figcaption>
                </figure>
            </div>
            </div>
        </div>
    </div>

    <FaqSection />





    <teleport to="body">
        <PopupWindow v-if="error">
            <div class="block">
                <div class="mb-6 font-semibold">Omlouváme se, ale došlo k chybě. Zkuste to prosím znovu nebo nás kontaktujte na +420 605 176 305.</div>
                <div class="flex">
                        <PetButton class="mr-3" text="Zavřít" @click="error = false" />
                        <router-link to="/">
                            <PetButton text="Vrátit se domů" />
                        </router-link>
                </div>
            </div>
        </PopupWindow>
    </teleport>
</template>


<script setup>
import { CheckIcon } from '@heroicons/vue/20/solid'
import aboutUsFeature1 from '../assets/svg/aboutUsFeature1.svg'
import aboutUsFeature2 from '../assets/svg/aboutUsFeature2.svg'
import aboutUsFeature3 from '../assets/svg/aboutUsFeature3.svg'
import { ChevronLeftIcon } from '@heroicons/vue/20/solid'
import { LockClosedIcon } from '@heroicons/vue/20/solid'
import { useSeoMeta } from '@vueuse/head'
import customerViteslav from '../assets/img/customerViteslav.png'
import customerZoe from '../assets/img/customerZoe.jpg'
import customer1 from '../assets/img/customer1.png'
import customer2 from '../assets/img/customer2.png'
import customerArny from '../assets/img/customerArny.jpeg'
import customer3 from '../assets/img/customer3.png'
import customerBarnie from '../assets/img/customerBarnie.jpeg'
import customerSushi from '../assets/img/customerSushi2.jpeg'
import customerMalicek2 from '../assets/img/customerMalicek2.jpeg'

useSeoMeta ({
	title: 'Objednávka pátrací kampaně | Najdi Mazlíčka',
	description: 'Postaráme se, aby se váš mazlíček v pořádku našel',
	ogDescription: 'Postaráme se, aby se váš mazlíček v pořádku našel',
	ogTitle: 'Objednávka pátrací kampaně | Najdi Mazlíčka',
	ogImage: 'https://najdimazlicka.cz/og.jpg',
	twitterCard: 'summary_large_image',
    robots: "noindex"
});

const featuredTestimonial = {
  body: 'Nedávno se nám zaběhli dva pejsci, Toby a Alex. Nyní jsme již zase všichni šťastně pohromadě. Děkuji Vám všem kdo jste se na pátrání prostřednictvím služby „Najdi mazlíčka“ podíleli a poskytovali nám podporu. Je velice důležité, že taková služba jako je najdimazlicka.cz existuje a hlavně, že funguje. Komunita lidí, úřadů, spolků a všech ochotných pomoci, kteří byli ve velmi krátkém časovém úseku po ztrátě osloveni, by byla nad síly jednotlivce. A právě rychlost a kvalita rozšíření informace na správné lidi a místa je klíčem k úspěšnému shledání se ztraceným mazlíčkem.',
  author: {
    name: 'Vítězslav H.',
    handle: 'Šťasný páníček',
    imageUrl: customerViteslav,
  },
}

const testimonials = [
  [
    [
      {
        body: 'O naší ztrátě vědělo opravdu široké okolí cizích lidí, se kterými jsme náhodně mluvili při pátrání.',
        author: {
          name: 'Zuzana W.',
          handle: 'Šťastná panička',
          imageUrl: customer1,
        },
      },
      {
        body: 'Dostali jsme tip od jedné slečny, která chodí krmit potulné kočky, že si ji všimla a nepatří mezi ostatní co tam potkává. Moc bych Vám chtěla poděkovat za pomoc, bez Vaší pomoci bychom ještě hledali, nebo vůbec nenašli. Děkuji.',
        author: {
          name: 'Lenka Z.',
          handle: 'Šťastná panička',
          imageUrl: customer2,
        },
      },
      {
        body: 'Barnie jednoho večera utekl ze zahrady u rodiného domu. Celé 2 dny ho jeho majitelé hledali. Za 8 hodin od spuštění naší kampaně se podařilo oslovit přes 12 tisíc lidí včetně jeho zachránce, který ho ještě ten den předal majitelům.',
        author: {
          name: 'Barnie',
          handle: 'Příběh ztráty',
          imageUrl: customerBarnie,
        },
      }
      // More testimonials...
    ],
    [
      {
        body: 'Malíček je kocourek, který nikdy nebyl venku. Jednoho dne však utekl a nikdo nevěděl, jak ho hledat. Za první den kampaně jsme oslovili 12 tisíc lidí z okolí, díky čemuž se nám kocourka podařilo vrátit v pořádku domů.',
        author: {
          name: 'Malíček',
          handle: 'Příběh ztráty',
          imageUrl: customerMalicek2,
        },
      },
      // More testimonials...
    ],
  ],
  [
    [
      {
        body: 'Sushi se ztratila z panelového domu ve velmi zalidněné části Prahy. Během 2 dní jsme oslovili přes 9 tisíc lidí z okolí. Jakmile se o ztrátě dozvěděl i človek, který se této kočičky ujal, sám kontaktoval majitelku a vrátil Sushi domů.',
        author: {
          name: 'Sushi',
          handle: 'Příběh ztráty',
          imageUrl: customerSushi,
        },
      },
      // More testimonials...
    ],
    [
      {
        body: 'Zoe se na procházce ztratila z dohledu a byla zaběhnutá 9 dní. Díky Vaší službě o ní vědělo široké okolí a každý den nám volali lidé z celého okresu, že zřejmě viděli našeho psa. Po spoustě planých výjezdů se nakonec ozval pán se správným tipem a Zoe jsme našli v poli jen pár km od domova.',
        author: {
          name: 'Vojtěch H.',
          handle: 'Šťastný páníček',
          imageUrl: customerZoe,
        },
      },
      {
        body: 'Člověk si vůbec neuvědomuje hodnotu takové služby do té doby, než ji opravdu potřebuje...',
        author: {
          name: 'Dara N.',
          handle: 'Šťastná panička',
          imageUrl: customer3,
        },
      },
      {
        body: 'Arny se ztratil při výkonu strážní služby v Pardubicích. Během pár dní se nám podařilo oslovit přes 37 tisíc lídí z Pardubic a okolí. Díky tomu se ho také podařilo najít.',
        author: {
          name: 'Arny, policení pes',
          handle: 'Příběh ztráty',
          imageUrl: customerArny,
        },
      },
      // More testimonials...
    ],
  ],
  
]



const includedFeatures = [
  'Instagram reklamy',
  'Příspěvek, stories',
  'Pravidelná kontrola zpráv',
  'Profesionální kampaň',
]

const tiers = [
  {
    name: 'Základní',
    id: 'tier-basic',
    priceMonthly: '990 Kč',
    description: 'Bohatě stačí na málo zalidněné oblasti',
    features: ["Pátrací kampaň bude trvat 2 dny", "4 000+ zobrazení v okolí ztráty (8km)"],
    performance: false,
    mostPopular: false,
    number: 1,
  },
  {
    name: 'Rozšířený',
    id: 'tier-advanced',
    priceMonthly: '1 990 Kč',
    beforePrice: "2 390 Kč",
    description: 'Pokryje i většinu velkých měst',
    features: [
        "Pátrací kampaň bude trvat 7 dní",
        "10 000+ zobrazení v okolí ztráty (8km)",
        "Prioritní podpora každý den v týdnu",
    ],
    mostPopular: true,
    performance: false,
    number: 2,
  },
  {
    name: 'Premium',
    id: 'tier-premium',
    priceMonthly: '2 990 Kč',
    beforePrice: "3 490 Kč",
    description: 'Maximální výkon pátrací kampaně',
    features: [
        "Pátrací kampaň bude trvat 7 dní",
        "16 000+ zobrazení v okolí ztráty (8km)",
        "Prioritní podpora každý den v týdnu",
    ],
    performance: true,
    mostPopular: false,
    number: 3,
  },
]



const incentives = [
{
    name: 'Kampaň spustíme do pár hodin',
    imageSrc: aboutUsFeature2,
    description: "Náš tým je k dispozici 7 dní v týdnu. Ihned po vaší registraci začínáme na kampani pracovat.",
  },
  {
    name: '100% Garance vrácení peněz',
    imageSrc: aboutUsFeature1,
    description: "Vrátíme vám všechny peníze, pokud se náhodou nepodaří za stanovený čas oslovit tolik lidí, jak odhadujeme.",
  },
  
  {
    name: 'Celá ČR, 7 dní v týdnu',
    imageSrc: aboutUsFeature3,
    description:
      "Nezáleží jestli jste z Prahy, Brna, z malé vesnice nebo zahraničí. Kampaň nastavíme vždy na míru.",
  },
]
</script>

<script>
// import BasicButton from '@/components/BasicButton.vue'
import BareboneHeader from '@/components/BareboneHeader.vue'
// import TitleHeader from '@/components/TitleHeader.vue'
import PopupWindow from '@/components/PopupWindow.vue'
import FaqSection from '@/components/FaqSection.vue'

//eslint-disable-next-line
import axios from 'axios'

const url = process.env.SERVER_URL

export default {
    name: 'OrderView',
    components: {
        // BasicButton,
        BareboneHeader,
        // TitleHeader,
        PopupWindow,
        FaqSection
    },
    data() {
        return {
            packageType: 0,
            packagePriceAmount: 0,
            total: 0,
            addon: false,
            error: false
        }
    },
    watch: {
        packageType() {
            this.computeTotal()
        },
        addon() {
            this.computeTotal()
        }
    },
    methods: {
        async startCheckout() {

            const order = {
                package: this.packageType,
                addon: this.addon
            }

            this.$cookies.set('mazel-order-info', order)

            //get the checkout link
            const checkoutUrl = await axios.post(`${url}/api/payment`, order)
                .then(res => {
                    return res.data.data
                }).catch(err => {
                    this.error = true
                    console.log(err)
                })

            window.location.href = checkoutUrl
        },
        computeTotal() {
            let packagePrice = 0
            let addonPrice = 0

            switch(this.packageType) {
                case 1:
                    packagePrice = 990
                    break
                case 2:
                    packagePrice = 1990
                    break
                case 3:
                    packagePrice = 2990
                    break
            }



            if(this.addon) {
                addonPrice = 890
            }

            this.packagePriceAmount = packagePrice
            this.total = packagePrice + addonPrice
        }
    },
}
</script>